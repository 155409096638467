export default function Stars({ num, starImg = "../assets/img/star.png" }) {
  return (
    <>
      {Array(num)
        .fill(1)
        .map((_, idx) => {
          return <img key={`star-${idx}`} src={starImg} alt="" />;
        })}
    </>
  );
}
