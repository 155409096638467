const RightMenu = () => {
  return (
    <div className="rightbar">
      <ul>
        <li>
          <a
            href="https://twitter.com/theNowlAge"
            target="_blank"
            rel="noreferrer"
          >
            <img src={"/assets/img/sns01.svg"} alt="" />
          </a>
        </li>
        <li>
          <a href="#" target="_blank">
            <img src={"/assets/img/sns02.svg"} alt="" />
          </a>
        </li>
        <li>
          <a href="#" target="_blank">
            <img src={"/assets/img/sns03.svg"} alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://www.reddit.com/r/NowlAgeGame/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={"/assets/img/sns04.svg"} alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://medium.com/@nowlage"
            target="_blank"
            rel="noreferrer"
          >
            <img src={"/assets/img/sns05.svg"} alt="" />
          </a>
        </li>
        <li>
          <a href="https://t.me/NowlAgeGroup" target="_blank" rel="noreferrer">
            <img src={"/assets/img/sns06.svg"} alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://t.me/NowlAgeAnnouncement"
            target="_blank"
            rel="noreferrer"
          >
            <img src={"/assets/img/sns06.svg"} alt="" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default RightMenu;
