import { Link, useLocation } from "react-router-dom";

import routes from "../routes";

const Navigation = ({ linkActive }) => {
  // const [linkAllRoom, setLinkAllRoom] = useState();
  const location = useLocation();
  let pathName = location.pathname;
  let hashName = location.hash.replace("#/", "/");

  pathName = pathName || hashName;

  if (pathName === "/") pathName = "/shop";

  // const active = (value) => {
  //   setLinkAllRoom(value);
  // };

  return (
    <div className="top-navigation">
      <div className="container">
        <Link
          to={routes.PVP}
          className={`top-navigation__link all-room-link ${
            linkActive === "allRoom" ? "active" : ""
          }`}
        >
          All Rooms
        </Link>
        <Link
          to={routes.MY_ROOMS}
          className={`top-navigation__link my-room-link ${
            linkActive === "myRoom" ? "active" : ""
          }`}
        >
          My Rooms
        </Link>
        {/*<Link*/}
        {/*  to="/rank"*/}
        {/*  className={`top-navigation__link rank-link ${*/}
        {/*    linkActive === "rank" ? "active" : ""*/}
        {/*  }`}*/}
        {/*>*/}
        {/*  My Rank*/}
        {/*</Link>*/}
      </div>
    </div>
  );
};

export default Navigation;
