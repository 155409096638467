import { useEffect, useState } from "react";

import { Modal } from "antd";
import _ from "lodash";
import { Moralis } from "moralis";
import ReactPlayer from "react-player";
import { useEffectOnce } from "react-use";

import "../../assets/css/pve.css";
import ListCard from "../../components/ListCard";
import { errorModal, infoModal } from "../../components/Modal";
import Stars from "../../components/Stars";
import { useConfig } from "../../hooks/useConfig";
import { useUserWallet } from "../../hooks/useUserWallet";
import { randomIntFromInterval } from "../../utils.js";

const S3_URL = "https://nowlage-image.s3.ap-southeast-1.amazonaws.com/pve";

const LOADING_FILE = [
  `${S3_URL}/Loading/43cf1c1971ac81649af911e1aa9321c9.gif`,
  `${S3_URL}/Loading/874dab2014a9e5fc1bcafdf831f59ac3.gif`,
  `${S3_URL}/Loading/97170de60195aa377024c7566f3bac27.gif`,
];

const LOSE_FILE = [
  `${S3_URL}/Lose/lose 01.mp4`,
  `${S3_URL}/Lose/lose 02.mp4`,
  `${S3_URL}/Lose/lose 03.mp4`,
  `${S3_URL}/Lose/lose 04.mp4`,
];

const WIN_FILE = [
  `${S3_URL}/Win/win 01.mp4`,
  `${S3_URL}/Win/win 02.mp4`,
  `${S3_URL}/Win/win 03.mp4`,
  `${S3_URL}/Win/win 04.mp4`,
];

const PVE = () => {
  const { increaseBalance } = useUserWallet();
  const { config } = useConfig();

  const cardLevelByDemon = [
    [1, 2], // demon level 1
    [1, 2, 3], // demon level 2
    [2, 3, 4], // demon level 3
    [3, 4, 5], // demon level 4
    [4, 5], // demon level 5
  ];

  const [stamina, setStamina] = useState(0);
  const [isShowModalInventory, setIsShowModalInventory] = useState(false);
  const [isShowResult, setIsShowResult] = useState(false);
  const [demonLevelSelected, setDemonLevelSelected] = useState();
  const [cardLevelList, setCardLevelList] = useState([]);
  const [fightResult, setFightResult] = useState();

  const getStamina = async () => {
    const _stamina = await Moralis.Cloud.run("pve_getUserStamina");
    setStamina(_stamina);
  };

  const startFight = async (card, demonLevel) => {
    const indexLoadingGif = randomIntFromInterval(0, 2);
    infoModal(
      <div
        className="video"
        style={{
          margin: "auto",
          width: "100%",
          height: "700px",
        }}
      >
        <img src={LOADING_FILE[indexLoadingGif]} alt="PVE Loading" />
      </div>,
      { className: "message-pve-loading message-only", closable: false }
    );

    try {
      const pveFight = await Moralis.Cloud.run("pve_fight", {
        nftId: card.nftId,
        demonLevel,
      });

      setFightResult(pveFight);
      const indexResultVideo = randomIntFromInterval(0, 3);

      setIsShowModalInventory(false);
      await getStamina();

      let resultVideo =
        pveFight.result === "win"
          ? WIN_FILE[indexResultVideo]
          : LOSE_FILE[indexResultVideo];
      setTimeout(() => {
        infoModal(
          <div
            className="video"
            style={{
              margin: "auto",
              width: "100%",
              height: "700px",
            }}
          >
            <ReactPlayer
              width={"100%"}
              height={"100%"}
              url={resultVideo}
              playing={true}
              onEnded={() => {
                setIsShowResult(true);
              }}
            />

            <button
              className="pve btn-skip"
              onClick={() => {
                setIsShowResult(true);
              }}
            >
              Skip
            </button>
          </div>,
          { className: "message-pve message-only", closable: false }
        );
      }, 2000);

      increaseBalance(pveFight.reward);
    } catch (e) {
      // show error card used and other errors is here
      errorModal(
        <div className="result-info">
          <span>{e.message}</span>
        </div>,
        { className: "message-pve" }
      );
    }
  };

  const openModalInventory = async (demonLevel) => {
    setIsShowModalInventory(true);
    setDemonLevelSelected(demonLevel);
    setCardLevelList(cardLevelByDemon[demonLevel - 1]);
  };

  useEffect(() => {
    if (isShowResult && fightResult) {
      setTimeout(() => {
        let labelResult = fightResult.result === "win" ? "Victory" : "Defeat";
        infoModal(
          <div className="result-info">
            <div
              className={`title-result ${
                fightResult.result === "win" ? "title-win" : "title-lose"
              }`}
            >
              {labelResult}
            </div>
            <div className="star-num">
              <Stars
                num={fightResult.demonLevel - 1}
                starImg={"../assets/img/star01.png"}
                alt={`pve-demon-level-${fightResult.demonLevel - 1}`}
              />
            </div>
            <ul className="info">
              <li className="reward">
                Reward:{" "}
                <div>
                  <span>{fightResult.reward}</span>
                </div>
              </li>

              <li className="stamina">
                Stamina:
                <div> - {fightResult.demonLevel}</div>
              </li>
            </ul>
          </div>,
          {
            className: "message-pve",
            closable: false,
            onOk: () => {
              setFightResult(null);
              setIsShowResult(false);
            },
          }
        );
      }, 500);
    }
  }, [isShowResult, fightResult]);

  useEffectOnce(() => {
    getStamina().catch(console.error);
  });

  console.log(config);
  return (
    <div className="content">
      <div className="wrap-content pve">
        <div className="bg-title">
          Stamina: <span>{stamina}</span>
        </div>
        <div className="wrap-info">
          <div className="list-demon">
            {Array(5)
              .fill(0)
              .map((_v, _idx) => {
                const demonLevel = _idx + 1;
                return (
                  <div key={_idx} className="items-box">
                    <div className="button">
                      {" "}
                      {stamina > 0 && (
                        <button onClick={() => openModalInventory(demonLevel)}>
                          <img src={"../assets/img/fight.svg"} alt="" />
                        </button>
                      )}
                    </div>
                    <div className="items-info">
                      <div className="img">
                        <img
                          src={`../assets/img/pve_img0${demonLevel}.svg`}
                          alt=""
                        />
                      </div>
                      <div className="items-text">
                        {!_.isEmpty(config) && (
                          <>
                            <div className="text01">Reward</div>
                            <div className="price">
                              <span>
                                {config.open1BoxFee *
                                  config[`pveRewardLevel${demonLevel}`]}
                              </span>
                            </div>
                          </>
                        )}

                        <div className="text02">Guardian require</div>
                        <ul className="star">
                          {demonLevel > 1 && (
                            <li>
                              <Stars
                                num={demonLevel - 1}
                                starImg={"../assets/img/star02.png"}
                                alt={`pve-demon-level-${demonLevel - 1}`}
                              />
                            </li>
                          )}
                          <li>
                            <Stars
                              num={demonLevel}
                              starImg={"../assets/img/star02.png"}
                              alt=""
                            />
                          </li>

                          {demonLevel < 5 && (
                            <li>
                              <Stars
                                num={demonLevel + 1}
                                starImg={"../assets/img/star02.png"}
                                alt={`pve-demon-level-${demonLevel + 1}`}
                              />
                            </li>
                          )}
                        </ul>
                        <div className="text03">Stamina Cost</div>
                        <div className="num">{demonLevel}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          <Modal
            className="list-modal modal-pve"
            visible={isShowModalInventory}
            closable
            footer={false}
            onCancel={() => setIsShowModalInventory(false)}
            destroyOnClose
          >
            <div className="modal-big">
              {demonLevelSelected && (
                <>
                  {cardLevelList.map((_l, _i) => {
                    return (
                      <div key={_i} className="modal-box">
                        <h1>Card {_l} star</h1>
                        <ListCard
                          level={_l}
                          itemPerPage={3}
                          onClickCard={(card) => {
                            startFight(card, demonLevelSelected);
                          }}
                        />
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </Modal>
        </div>

        <div className="content-fixed">
          <div className="info">
            <div className="fixed-content">
              <div className="text">
                The Demons in other dimensions are invading through the Abyssal
                Rifts. They are very powerful. Your mission is to summon the
                ancient Guardians to defeat the Demons, Seal the Rifts and save
                the world!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PVE;
