import React, { useState, useRef, useEffect } from "react";

import { Moralis } from "moralis";
import "../../assets/css/dashboard.css";

import qs from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import openModal from "../../components/Modal";
import ConfirmPassword from "../../components/modal/ConfirmPassword";
import Navigation from "../../components/Navigation";
import AutoMatchingTable from "../../components/PVP/AutoMatchingTable";
import CustomRoomTable from "../../components/PVP/CustomRoomTable";
import EventTable from "../../components/PVP/EventTable";
import PrepareCard from "../../components/PVP/PrepareCard";
import TournamentTable from "../../components/PVP/TournamentTable";
import { USER_BATTLE_ROOM_STATE } from "../../constants";
import { useUserWallet } from "../../hooks/useUserWallet";
import { isNullOrWhiteSpace } from "../../utils";

// eslint-disable-next-line import/order
import { Alert, Modal } from "antd";

const PVP = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const shouldFetch = useRef(true);

  const { decreaseBalance } = useUserWallet();

  const [roomCode, setRoomCode] = useState("");
  const [cardLevel, setCardLevel] = useState(0);
  const [fee, setFee] = useState(0);
  const [password, setPassword] = useState(0);
  const [isShowPrepareCard, setShowPrepareCard] = useState(false);
  const [isShowConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isShowModalChargeFee, setShowModalChargeFee] = useState(false);

  const onJoinBattleRoom = async (_roomCode) => {
    let res = await Moralis.Cloud.run("battleRoom_get", { code: _roomCode });

    if (res.isOk) {
      const battleRoom = res.data.battleRoom;

      res = await Moralis.Cloud.run("userCard_validateAvailableCard", {
        level: battleRoom.cardLevel,
      });

      if (!res.isOk) {
        toast(res.message, { type: "error" });
        return;
      }

      setRoomCode(_roomCode);
      setCardLevel(battleRoom.cardLevel);
      setFee(battleRoom.fee);
      if (battleRoom.hasPassword) {
        setShowConfirmPassword(true);
        return;
      }

      setShowModalChargeFee(true);
      return;
    }

    toast(res.message, { type: "error" });
  };

  const joinBattleRoom = async (_roomCode, _password) => {
    const res = await Moralis.Cloud.run("battleRoom_join", {
      code: _roomCode,
      password: _password,
    });

    if (res.isOk) {
      setRoomCode(res.data.battleRoom.code);
      setShowConfirmPassword(false);
      setShowPrepareCard(true);
      return true;
    }

    toast(res.message, { type: "error" });
    return false;
  };

  const onConfirmPassword = async (_password) => {
    setPassword(_password);
    const res = await Moralis.Cloud.run("battleRoom_validatePassword", {
      code: roomCode,
      password: _password,
    });

    if (res.isOk) {
      setShowModalChargeFee(true);
    } else {
      toast("The password is incorrect.", { type: "error" });
    }
  };

  const onChargedFee = async () => {
    setShowModalChargeFee(false);

    const modal = openModal("success", {
      className: "custom-modal message-only",
      title: "Processing..",
      content: "Please do not close this window until the process is complete!",
    });

    try {
      const rs = await joinBattleRoom(roomCode, password);
      if (rs) {
        decreaseBalance(fee);
        modal.destroy();
      }
    } catch (e) {
      modal.update({
        title: "Error",
        content: e.message,
      });
    }
  };

  const prepareCards = async (cards) => {
    let res = await Moralis.Cloud.run("battleRoom_prepareCards", {
      code: roomCode,
      cards: cards,
    });

    if (!res.isOk) {
      toast(res.message, { type: "error" });
      return;
    }

    if (res.isOk) {
      res = await Moralis.Cloud.run("battleRoom_get", { code: roomCode });
      if (res.isOk) {
        const userBattleRooms = res.data.userBattleRooms;
        if (
          userBattleRooms.length === 2 &&
          !userBattleRooms.find(
            (e) => e.state !== USER_BATTLE_ROOM_STATE.WAITING
          )
        ) {
          Moralis.Cloud.run("battleRoom_get", { code: roomCode }).catch(
            console.error
          );
          navigate && navigate(`/pvp/fighting/${roomCode}`);
        }
      }
      setShowPrepareCard(false);
    }

    toast(res.message, { type: "error" });
  };

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      const params = qs.parse(search);
      const { code, password } = params;

      if (!isNullOrWhiteSpace(code)) {
        setRoomCode(code.toString());
        if (isNullOrWhiteSpace(password)) {
          onJoinBattleRoom(code).catch(console.error);
        } else {
          joinBattleRoom(code, password).catch(console.error);
        }
      }
    }
  });

  return (
    <div className="wrap-db">
      {/*Top Navigation*/}
      <Navigation linkActive={"allRoom"}></Navigation>
      {isShowConfirmPassword && (
        <ConfirmPassword
          onSubmit={onConfirmPassword}
          onClose={() => {
            setShowConfirmPassword(false);
          }}
        />
      )}

      {!isShowPrepareCard && (
        <>
          <EventTable />
          {/*Tournament*/}
          <TournamentTable />

          {/*Auto Matching*/}
          <AutoMatchingTable onAction={onJoinBattleRoom} />

          {/*Custom Room*/}
          <CustomRoomTable onAction={onJoinBattleRoom} />
        </>
      )}

      {isShowPrepareCard && (
        <PrepareCard
          battleRoomCode={roomCode}
          cardLevel={cardLevel}
          onSubmit={prepareCards}
        />
      )}

      {/* Fix bar */}
      {/*<FixNav></FixNav>*/}

      <Modal
        wrapClassName="custom-modal"
        key="modal-deposit"
        visible={isShowModalChargeFee}
        footer={null}
        onCancel={() => {
          setShowModalChargeFee(false);
        }}
      >
        <h3>Join Battle Room Fee</h3>
        <div className="text-center">
          <p className="fs-3 mb-0">
            You will be charged <strong>{fee} NAC</strong> to join room
          </p>
          <Alert
            message="The process is asynchronous so please wait a little bit."
            type="warning"
            showIcon
          />
          <div className="action">
            <div
              className="btn"
              onClick={() => {
                setShowModalChargeFee(false);
              }}
            >
              <img src={"../assets/img/cancel01.jpg"} alt="" />
            </div>
            <div className="btn" onClick={onChargedFee}>
              <img src={"../assets/img/ok.png"} alt="" />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PVP;
