import { useEffect, useRef, useState } from "react";

import { Moralis } from "moralis";
import { useMoralisSubscription } from "react-moralis";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { BATTLE_ROOM_STATE } from "../../constants";
import routes from "../../routes";
import settings from "../../settings";
import { truncate } from "../../utils";

const CustomRoomTable = ({ onAction }) => {
  const shouldFetchData = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [battleRooms, setBattleRooms] = useState([]);

  useMoralisSubscription(
    "BattleRoom",
    (q) => q.notEqualTo("owner", Moralis.User.current()),
    [],
    {
      onUpdate: (data) => {
        if (data.get("state") === BATTLE_ROOM_STATE.WAITING) {
          fetchData().catch(console.error);
        }
      },
    }
  );

  const fetchData = async () => {
    setIsLoading(true);

    const filterOptions = {
      page: currentPage,
      itemPerPage: settings.ITEM_PER_PAGE,
      searchTerm: searchTerm,
    };

    const res = await Moralis.Cloud.run(
      "battleRoom_getCustomRooms",
      filterOptions
    );
    if (res.isOk) {
      const _totalPage = Math.ceil(res.count / filterOptions.itemPerPage);
      setTotalPage(_totalPage);
      setBattleRooms(res.data);
    }

    toast(res.message, { type: "error" });

    setIsLoading(false);
  };

  useEffect(() => {
    if (shouldFetchData.current) {
      shouldFetchData.current = false;
      fetchData().catch(console.error);
    }
  }, [currentPage, searchTerm]);

  // Trick: prevent show warning: Not unsubscribe when unmount
  useEffect(() => {});

  return (
    <div className="group-tbl custom-rooms-section">
      <div className="container">
        <div className="group-tbl__header d-flex justify-content-between">
          <div className="group-left">
            <div className="gray-lbl">
              <span>Custom Rooms</span>
            </div>

            <div className="form-group group-search">
              <input
                defaultValue={""}
                type="text"
                className="form-control"
                placeholder="Room ID"
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    shouldFetchData.current = true;
                    setSearchTerm(event.target.value.trim());
                  }
                }}
                onChange={() => {}}
              />
              <img
                className="icon-right icon-search"
                src="/assets/img/icon-search.svg"
                alt=""
              />
            </div>
          </div>

          <Link to={routes.CREATE_ROOM} className="btn-long">
            <img className="img" src="/assets/img/btn-create-room.svg" alt="" />
          </Link>
        </div>
        <div className="group-tbl__body">
          <table className="table tbl-style">
            <thead>
              <tr>
                <th scope="col">Room ID</th>
                <th scope="col">Stars</th>
                <th scope="col">Created By</th>
                <th scope="col">Fee (NAC)</th>
                <th scope="col">Join</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={5}>
                    <span>Loading...</span>
                  </td>
                </tr>
              )}

              {!isLoading && battleRooms.length < 1 && (
                <tr>
                  <td colSpan={7}>
                    <span>No rooms available</span>
                  </td>
                </tr>
              )}

              {battleRooms.length > 0 &&
                battleRooms.map((e, _id) => (
                  <tr key={_id}>
                    <td scope="row">{e.code}</td>
                    <td>{e.cardLevel}</td>
                    <td>{truncate(e.owner, 10)}</td>
                    <td>{e.fee}</td>
                    <td>
                      <Link
                        to=""
                        onClick={(event) => {
                          event.preventDefault();
                          onAction(e.code);
                        }}
                      >
                        <img
                          className="img"
                          src="/assets/img/icon-play.svg"
                          alt=""
                        />
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {totalPage > 1 && (
            <div className="pagination">
              <div className="pagination__in">
                <Link
                  to="#"
                  className="btn-pagination"
                  disabled={currentPage === 1}
                  onClick={(event) => {
                    event.preventDefault();
                    if (currentPage > 1) {
                      shouldFetchData.current = true;
                      setCurrentPage(currentPage - 1);
                    }
                  }}
                >
                  <img className="img" src="/assets/img/prev.svg" alt="" />
                </Link>
                <span className="pagination-id">
                  {currentPage}/{totalPage}
                </span>
                <Link
                  to="#"
                  className="btn-pagination"
                  disabled={currentPage === totalPage}
                  onClick={(event) => {
                    event.preventDefault();
                    if (currentPage < totalPage) {
                      shouldFetchData.current = true;
                      setCurrentPage(currentPage + 1);
                    }
                  }}
                >
                  <img className="img" src="/assets/img/next.svg" alt="" />
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomRoomTable;
