import React, { useEffect, useRef, useState } from "react";

import { Moralis } from "moralis";
import Modal from "react-bootstrap/Modal";
import { useMoralisSubscription } from "react-moralis";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { EVENT_ROOM_STATE, GAME_RESULT } from "../../constants";
import settings from "../../settings";
import { truncate } from "../../utils";

const MyTournamentRoomTable = () => {
  const shouldFetchData = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [eventRooms, setEventRooms] = useState([]);

  const [ourInformation, setOurInformation] = useState({});
  const [competitorInformation, setCompetitorInformation] = useState({});
  const [score, setScore] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [result, setResult] = useState("");

  useMoralisSubscription(
    "UserEventRoom",
    (q) => q.equalTo("user", Moralis.User.current()),
    [],
    {
      onCreate: (data) => {
        fetchData().then(console.error);
      },
      onUpdate: (data) => {
        fetchData().then(console.error);
      },
    }
  );

  const [curEvent, setCurEvent] = useState("event-1");
  const [isDuplicate] = useState(false);

  const toggleEvents = (id) => {
    setCurEvent(id);
  };

  const fetchData = async () => {
    setIsLoading(true);

    const filterOptions = {
      page: currentPage,
      itemPerPage: settings.ITEM_PER_PAGE,
      searchTerm: searchTerm,
    };

    const res = await Moralis.Cloud.run(
      "eventRoom_getMyTournamentRooms",
      filterOptions
    );
    if (res.isOk) {
      const _totalPage = Math.ceil(res.count / filterOptions.itemPerPage);
      setTotalPage(_totalPage);
      setEventRooms(res.data);
    }

    toast(res.message, { type: "error" });

    setIsLoading(false);
  };

  const getResult = (detail) => {
    const numOfWin = detail.filter((n) => n === GAME_RESULT.WIN).length;
    const numOfLose = detail.filter((n) => n === GAME_RESULT.LOSE).length;
    if (numOfWin > numOfLose) {
      return GAME_RESULT.WIN;
    } else if (numOfWin < numOfLose) {
      return GAME_RESULT.LOSE;
    }

    return GAME_RESULT.DRAW;
  };

  const showDetailResult = (detail, cards, competitorCards, competitor) => {
    const yourScore = detail.filter((n) => n === GAME_RESULT.WIN).length;

    const competitorScore = detail.filter((n) => n === GAME_RESULT.LOSE).length;

    setScore(`${yourScore} - ${competitorScore}`);

    if (yourScore > competitorScore) {
      setResult(GAME_RESULT.WIN);
    } else if (yourScore < competitorScore) {
      setResult(GAME_RESULT.LOSE);
    } else {
      setResult(GAME_RESULT.DRAW);
    }

    setOurInformation({
      user: Moralis.User.current(),
      cards: cards,
      detail: detail,
    });

    setCompetitorInformation({
      user: competitor,
      cards: competitorCards,
      detail: detail,
    });
    setShowDetail(true);
  };

  useEffect(() => {
    if (shouldFetchData.current) {
      shouldFetchData.current = false;
      fetchData().catch(console.error);
    }
  }, [currentPage, searchTerm]);

  // Trick: prevent show warning: Not unsubscribe when unmount
  useEffect(() => {});

  return (
    <>
      <div className="container">
        <div className="group-tbl__header d-flex justify-content-between">
          {/*<div className="form-group select-form">*/}
          {/*  <select className="form-control">*/}
          {/*    <option>All</option>*/}
          {/*    <option>Waiting</option>*/}
          {/*    <option>Finish</option>*/}
          {/*  </select>*/}
          {/*</div>*/}
          {/*<div className="form-group group-search">*/}
          {/*  <input type="text" className="form-control" placeholder="ID Room" />*/}
          {/*  <img*/}
          {/*    className="icon-right icon-search"*/}
          {/*    src="/assets/img/icon-search.svg"*/}
          {/*    alt=""*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <div className="group-tbl__body">
          <table className="table tbl-style">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Event ID</th>
                <th scope="col">Start Time</th>
                <th scope="col">End Times</th>
                <th scope="col">Status</th>
                <th scope="col">Players</th>
                <th scope="col" style={{ width: "200px" }}>
                  Play with
                </th>

                <th scope="col">Win/lose</th>
                <th scope="col">Rank</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr className="event-info">
                  <td colSpan={9}>
                    <span>Loading...</span>
                  </td>
                </tr>
              )}

              {!isLoading && eventRooms.length < 1 && (
                <tr className="event-info">
                  <td colSpan={9}>
                    <span>No rooms available</span>
                  </td>
                </tr>
              )}
              {!isLoading &&
                eventRooms.length > 0 &&
                eventRooms.map((e, _id) => (
                  <React.Fragment key={_id}>
                    <tr
                      className="event-info"
                      onClick={() => toggleEvents(`event-${e.code}`)}
                    >
                      <td scope="row">{e.code}</td>
                      <td>{e.event}</td>
                      <td>
                        {new Intl.DateTimeFormat("en-US", {
                          month: "numeric",
                          day: "numeric",
                          year: "numeric",
                          hourCycle: "h24",
                          hour: "numeric",
                          minute: "numeric",
                        }).format(e.startTime)}
                      </td>
                      <td>
                        {new Intl.DateTimeFormat("en-US", {
                          month: "numeric",
                          day: "numeric",
                          year: "numeric",
                          hourCycle: "h24",
                          hour: "numeric",
                          minute: "numeric",
                        }).format(e.endTime)}
                      </td>
                      <td>{e.state}</td>
                      <td>
                        {e.readyPlayer}/{e.maxPlayer}
                      </td>
                      <td></td>
                      <td>{e.reward || e.lost || 0}</td>
                      <td>{e.rank}</td>
                    </tr>
                    {e.state === EVENT_ROOM_STATE.FINISHED &&
                      e.details.map((item, _idx) => (
                        <tr
                          key={_idx}
                          className={`waiting-stt ${
                            curEvent === `event-${e.code}` && !isDuplicate
                              ? "expand"
                              : ""
                          }`}
                        >
                          <td scope="row">...</td>
                          <td>...</td>
                          <td>...</td>
                          <td>...</td>
                          <td>...</td>
                          <td>...</td>
                          <td>{truncate(item.competitor.getUsername(), 10)}</td>
                          <td>{getResult(item.detail)}</td>
                          <td>
                            <Link
                              to=""
                              onClick={(event) => {
                                event.preventDefault();
                                showDetailResult(
                                  item.detail,
                                  e.cards,
                                  item.competitorCards,
                                  item.competitor
                                );
                              }}
                            >
                              ...
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
            </tbody>
          </table>

          {totalPage > 1 && (
            <div className="pagination">
              <div className="pagination__in">
                <Link
                  to="#"
                  className="btn-pagination"
                  disabled={currentPage === 1}
                  onClick={(event) => {
                    event.preventDefault();
                    if (currentPage > 1) {
                      shouldFetchData.current = true;
                      setCurrentPage(currentPage - 1);
                    }
                  }}
                >
                  <img className="img" src="/assets/img/prev.svg" alt="" />
                </Link>
                <span className="pagination-id">
                  {currentPage}/{totalPage}
                </span>
                <Link
                  to="#"
                  className="btn-pagination"
                  disabled={currentPage === totalPage}
                  onClick={(event) => {
                    event.preventDefault();
                    if (currentPage < totalPage) {
                      shouldFetchData.current = true;
                      setCurrentPage(currentPage + 1);
                    }
                  }}
                >
                  <img className="img" src="/assets/img/next.svg" alt="" />
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showDetail}
        onHide={() => {
          setShowDetail(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>You {result}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content-wrap">
            <div className="div-score">
              <div className="score-item">
                <h3>Your Score</h3>
                <div className="number-score">
                  <img className="icon" src={"/assets/img/score.svg"} alt="" />
                  <span className="text">{score}</span>
                </div>
              </div>
            </div>
            <div className="compare-cards">
              <div className="your-card">
                <div className="userPlayer">
                  <span>
                    {ourInformation.user
                      ? truncate(ourInformation.user.getUsername(), 10)
                      : ""}
                  </span>
                  {/*<img*/}
                  {/*  className="avatar"*/}
                  {/*  src={"/assets/img/avatar-2.png"}*/}
                  {/*  alt=""*/}
                  {/*/>*/}
                </div>
                <div className="list-cards">
                  {ourInformation.cards &&
                    ourInformation.cards.map((card, index) => (
                      <div
                        key={index}
                        className={`img ${
                          ourInformation.detail[index] === GAME_RESULT.LOSE &&
                          "lose"
                        }`}
                      >
                        <img
                          className="img"
                          src={`${settings.CARD_IMAGE_HOST}/${card.uri}`}
                          alt=""
                        />
                      </div>
                    ))}
                </div>
              </div>
              <div className="your-card">
                <div className="userPlayer">
                  <span>
                    {competitorInformation.user
                      ? truncate(competitorInformation.user.getUsername(), 10)
                      : ""}
                  </span>
                  {/*<img*/}
                  {/*  className="avatar"*/}
                  {/*  src={"/assets/img/avatar-1.png"}*/}
                  {/*  alt=""*/}
                  {/*/>*/}
                </div>
                <div className="list-cards">
                  {competitorInformation.cards &&
                    competitorInformation.cards.map((card, index) => (
                      <div
                        key={index}
                        className={`img ${
                          competitorInformation.detail[index] ===
                            GAME_RESULT.WIN && "lose"
                        }`}
                      >
                        <img
                          className="img"
                          src={`${settings.CARD_IMAGE_HOST}/${card.uri}`}
                          alt=""
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MyTournamentRoomTable;
