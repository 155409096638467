import { useState } from "react";

import { Moralis } from "moralis";
import { useMoralis } from "react-moralis";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

import "../../assets/css/detail.css";
import inventorySound from "../../assets/music/inventory.wav";
import Pagination from "../../components/Pagination";
import Stars from "../../components/Stars";
import { useAudio } from "../../hooks/useAudio";
import useUserNFTs from "../../hooks/useUserNFTs";
import { Card } from "../../models";
import settings from "../../settings";
import { shortenId } from "../../utils";

// eslint-disable-next-line import/order
import moment from "moment";

const Detail = () => {
  let { id } = useParams();
  const { user } = useMoralis();

  const { getNFTTransferHistories } = useUserNFTs(false);
  useAudio(inventorySound);

  const [detail, setDetail] = useState();
  const [isFetchingTransfer, setIsFetchingTransfer] = useState(true);
  const [transferHistories, setTransferHistories] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const onChangePage = async (page) => {
    setIsFetchingTransfer(true);
    setPage(page);
    await fetchCardTransferHistories(page);
  };

  const fetchCardDetail = async () => {
    let query = new Moralis.Query(Card).equalTo("nftId", Number(id));
    const _detail = await query.first();

    setDetail({
      class: _detail.get("class"),
      level: _detail.get("level"),
      uri: _detail.get("uri"),
      nftId: _detail.get("nftId"),
    });
  };

  const fetchCardTransferHistories = async (_page = 1) => {
    const transfersNFT = await getNFTTransferHistories(id, _page);
    let _totalPage = Math.ceil(transfersNFT.total / settings.ITEM_PER_PAGE);

    setTotalPage(_totalPage);
    setTransferHistories(transfersNFT.result);
    setIsFetchingTransfer(false);
  };

  useEffectOnce(() => {
    fetchCardDetail();
    fetchCardTransferHistories();
  });

  if (!detail) {
    return <span>Loading....</span>;
  }

  return (
    <div className="content detail">
      <div className="wrap-content detail">
        <div className="wrap-info detail">
          <div className="img">
            <img src={`${settings.CARD_IMAGE_HOST}/${detail.uri}`} alt="" />
          </div>
          <div className="box">
            <div className="info">
              <div className="title">Details</div>
              <div className="text">
                Guardians are one of the main NFTs the player can possess in The
                Nowl Age. Guardians can be minted by using Magic Books which you
                bought from Summoners Gate. Each Guardian comes with a random
                element and rarity (star) upon minting. Currently, there is no
                way to change the element of a Guardian.
              </div>
              <dl className="race">
                <dt>Collection ID:</dt>
                <dd>#{detail.nftId}</dd>
              </dl>
              <dl className="level">
                <span>STAR LEVEL:</span>
                <span>
                  <Stars num={detail.level} />
                </span>
              </dl>
              <dl className="element">
                <dt>Element:</dt>
                <dd>{detail.class}</dd>
              </dl>
              <dl className="element">
                <dt>Owner:</dt>
                <dd>{shortenId(user.get("ethAddress"))}</dd>
              </dl>
              {/* <ul className="c-info">
                                <li>Attack<span>3</span></li>
                                <li>Critical<span>3</span></li>
                                <li>Defense<span>3</span></li>
                                <li>HP<span>3</span></li>
                                <li>Avoid<span>4</span></li>
                            </ul> */}
            </div>
            <div className="history">
              {isFetchingTransfer && <span>...Loading Transfer Histories</span>}

              {!isFetchingTransfer && (
                <>
                  <div className="title">Trading History</div>
                  <div className="list">
                    <dl>
                      <dt>Event</dt>
                      {/* <dt>Price</dt> */}
                      <dt>From</dt>
                      <dt>To</dt>
                    </dl>
                    {transferHistories.map((transfer, idx) => {
                      return (
                        <dl key={idx}>
                          <dt>{transfer.transaction_type}</dt>
                          {/* <dd><span>{transfer.amount}</span></dd> */}
                          <dd>{shortenId(transfer.from_address)}</dd>
                          <dd>{shortenId(transfer.to_address)}</dd>
                          <dd className="time">
                            {moment(transfer.block_timestamp).fromNow()}
                          </dd>
                        </dl>
                      );
                    })}
                  </div>
                  {totalPage && (
                    <Pagination
                      totalPage={totalPage}
                      currentPage={page}
                      onChange={onChangePage}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {/* <div className="content-fixed">
                    <div className="info">
                        <div className="fixed-link">
                            <div className="first">
                                <div className="id">Token ID: 3050</div>
                                <div className="owner">Owner: <span>0x859AA...116310</span></div>
                            </div>
                            <div className="last">
                                <div className="title">Price:</div>
                                <div className="price"><span>2000</span></div>
                                <div className="btn"><a href="#"><img src="//assets/img/buy.svg" alt=""/></a></div>
                            </div>
                        </div>
                        <div className="fixed-content">
                            <div className="text">
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                                euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>
    </div>
  );
};

export default Detail;
