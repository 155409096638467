import { Chart } from "react-google-charts";

import "../../assets/css/dashboard.css";
import "../../assets/css/rank.css";
import Navigation from "../../components/Navigation";

const Rank = () => {
  // const [link, setLink] = useState("rooms");
  // const [curEvent, setCurEvent] = useState("event-1");
  // const [isDuplicate, setIsDuplicate] = useState(false);

  const dataWin = [
    ["Win", "Speakers (in millions)"],
    ["Win", 55],
    ["Lose", 45],
  ];
  const dataProfit = [
    ["Profit", "Speakers (in millions)"],
    ["Interest", 80],
    ["Lose", 20],
  ];
  const options = {
    pieSliceText: "label",
    title: "WIN/DEF",
    pieStartAngle: 100,
  };
  const optionsProfit = {
    pieSliceText: "label",
    title: "INTEREST/LOST",
    pieStartAngle: 100,
  };

  return (
    <div className="wrap-db">
      {/*Top Navigation*/}
      <Navigation linkActive={"rank"}></Navigation>

      {/* Leader Board Table */}
      <div className="group-tbl leader-board-section">
        <div className="container">
          <div className="leader-board">
            <div className="container">
              <h2 className="big-title">Leader board</h2>
            </div>
          </div>
          <div className="group-tbl__body">
            <table className="table tbl-style">
              <thead>
                <tr>
                  <th scope="col">Pos</th>
                  <th scope="col">Player</th>
                  <th scope="col">Marks</th>
                  <th scope="col">Vic/def</th>
                </tr>
              </thead>
              <tbody>
                <tr className="row-pos">
                  <td scope="row">
                    <img
                      className="icon-pos"
                      src="/assets/img/pos-1.svg"
                      alt=""
                    />
                  </td>
                  <td className="align-left">
                    <div className="userPlayer">
                      <span>Jonathan Ng</span>
                      <img
                        className="avatar"
                        src={"/assets/img/avatar-1.png"}
                        alt=""
                      />
                    </div>
                  </td>
                  <td>5682</td>
                  <td>561736/2</td>
                </tr>
                <tr className="row-pos">
                  <td scope="row">
                    <img
                      className="icon-pos"
                      src="/assets/img/pos-2.svg"
                      alt=""
                    />
                  </td>
                  <td className="align-left">
                    <div className="userPlayer">
                      <span>Bui Vien</span>
                      <img
                        className="avatar"
                        src={"/assets/img/avatar-4.png"}
                        alt=""
                      />
                    </div>
                  </td>
                  <td>5682</td>
                  <td>561736/2</td>
                </tr>
                <tr className="row-pos">
                  <td scope="row">
                    <img
                      className="icon-pos"
                      src="/assets/img/pos-3.svg"
                      alt=""
                    />
                  </td>
                  <td className="align-left">
                    <div className="userPlayer">
                      <span>Hue Black</span>
                      <img
                        className="avatar"
                        src={"/assets/img/avatar-3.png"}
                        alt=""
                      />
                    </div>
                  </td>
                  <td>5682</td>
                  <td>561736/2</td>
                </tr>
                <tr>
                  <td scope="row">4</td>
                  <td className="align-left">
                    <div className="userPlayer">
                      <span>Im Number 1</span>
                      <img
                        className="avatar"
                        src={"/assets/img/avatar-4.png"}
                        alt=""
                      />
                    </div>
                  </td>
                  <td>5682</td>
                  <td>561736/2</td>
                </tr>
                <tr>
                  <td scope="row">5</td>
                  <td className="align-left">
                    <div className="userPlayer">
                      <span>Im Number 1</span>
                      <img
                        className="avatar"
                        src={"/assets/img/avatar-5.png"}
                        alt=""
                      />
                    </div>
                  </td>
                  <td>5682</td>
                  <td>561736/2</td>
                </tr>
                <tr>
                  <td scope="row">6</td>
                  <td className="align-left">
                    <div className="userPlayer">
                      <span>Im Number 1</span>
                      <img
                        className="avatar"
                        src={"/assets/img/avatar-6.png"}
                        alt=""
                      />
                    </div>
                  </td>
                  <td>5682</td>
                  <td>561736/2</td>
                </tr>
                <tr>
                  <td scope="row">7</td>
                  <td className="align-left">
                    <div className="userPlayer">
                      <span>Im Number 1</span>
                      <img
                        className="avatar"
                        src={"/assets/img/avatar-7.png"}
                        alt=""
                      />
                    </div>
                  </td>
                  <td>5682</td>
                  <td>561736/2</td>
                </tr>
                <tr>
                  <td scope="row">8</td>
                  <td className="align-left">
                    <div className="userPlayer">
                      <span>Im Number 8</span>
                      <img
                        className="avatar"
                        src={"/assets/img/avatar-8.png"}
                        alt=""
                      />
                    </div>
                  </td>
                  <td>5682</td>
                  <td>561736/2</td>
                </tr>
                <tr>
                  <td scope="row">9</td>
                  <td className="align-left">
                    <div className="userPlayer">
                      <span>Im Number 1</span>
                      <img
                        className="avatar"
                        src={"/assets/img/avatar-9.png"}
                        alt=""
                      />
                    </div>
                  </td>
                  <td>5682</td>
                  <td>561736/2</td>
                </tr>
                <tr>
                  <td scope="row">10</td>
                  <td className="align-left">
                    <div className="userPlayer">
                      <span>Im Number 1</span>
                      <img
                        className="avatar"
                        src={"/assets/img/avatar-10.png"}
                        alt=""
                      />
                    </div>
                  </td>
                  <td>5682</td>
                  <td>561736/2</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* My Rank */}
      <div className="group-tbl my-rank-section">
        <div className="container">
          <div className="leader-board">
            <div className="container">
              <h2 className="big-title">My Rank</h2>
            </div>
          </div>
          <div className="group-tbl__body">
            <table className="table tbl-style">
              <thead>
                <tr>
                  <th scope="col">Pos</th>
                  <th scope="col">Player</th>
                  <th scope="col">Marks</th>
                  <th scope="col">Vic/def</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">1024</td>
                  <td className="align-left">
                    <div className="userPlayer">
                      <span>Loster Pham</span>
                      <img
                        className="avatar"
                        src={"/assets/img/avatar-11.png"}
                        alt=""
                      />
                    </div>
                  </td>
                  <td>5682</td>
                  <td>561736/2</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/*My Chart*/}
      <div className="my-charts">
        <div className="container">
          <div className="chart-item">
            <Chart
              chartType="PieChart"
              data={dataWin}
              options={options}
              width={"100%"}
              height={"260px"}
            />
          </div>
          <div className="chart-item">
            <Chart
              chartType="PieChart"
              data={dataProfit}
              options={optionsProfit}
              width={"100%"}
              height={"260px"}
            />
          </div>
        </div>
      </div>

      {/* Fix bar */}
      <div className="fix-bar">
        <div className="container">
          <div className="fix-bar__body">
            <div className="fix-bar__left">
              <button className="btn-long">
                <img
                  className="img"
                  src="/assets/img/btn-create-room.svg"
                  alt=""
                />
              </button>
              <button className="btn-long">
                <img
                  className="img"
                  src="/assets/img/btn-create-event.svg"
                  alt=""
                />
              </button>
            </div>
            <div className="fix-bar__right">
              <button className="btn-long">
                <img className="img" src="/assets/img/btn-refresh.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rank;
