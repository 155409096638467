const SETTINGS = {
  MORALIS_APP_ID: "bMyxFNjtH0sIPlIg2btQJtRzYT6Fi1CDC2vCXTpp",
  MORALIS_SERVER_URL: "https://vkagouyl3hcc.usemoralis.com:2053/server",

  CONTRACT_ADDRESS: "0xe198e8fe1aab441e54d9572e2402d7b132ccb15a",
  MASTER_ADDRESS: "0x8208aC0f863Fd2670abd56629B76f02F78361E65",
  // MASTER_ADDRESS: "0x8eb2e6903a9199f9b96a80f0c1c578628090852c",
  ADMIN_ADDRESS: [
    "0xf5acce5ba97fe09dff39dcf883a5049a6941b5b5",
    "0x2dd9533943568dacb6983d73bce0b98418c2434a",
    "0x091cfa5ef60ffef8544e5328583d69facfeacb61",
  ],

  CHAIN_ID: "0x38", // BSC Testnet
  CHAIN_NAME: "BSC Mainnet",
  CHAIN_RPC_URLS: ["https://bsc-dataseed.binance.org/"],
  CHAIN_EXPLORER_URLS: ["https://bscscan.com/"],

  // web3.utils.toHex('200000') === 0x30d40
  GAS_LIMIT: "0x30d40",
  GAS_PRICE: 1e10, // 10 gwei

  NFT_CONTRACT: "0xda2074fec09c8c929bccbda5b40944d89f6b7ce3",
  MARKETPLACE_CONTRACT: "0xD1017Ea79BCe9172a83fA55a9e68E1976EB41339",
  ITEM_PER_PAGE: 12,
  CARD_IMAGE_HOST: "https://nowlage-image.s3.ap-southeast-1.amazonaws.com",
  DAPP_URL: "https://dapp.nowlage.com",
};

export default SETTINGS;
