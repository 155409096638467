const MODE = {
  AUTO: "AUTO",
  CUSTOM: "CUSTOM",
};

const USER_BATTLE_ROOM_STATE = {
  PAYMENT: "PAYMENT",
  WAITING: "WAITING",
  FIGHTING: "FIGHTING",
  FINISHED: "FINISHED",
};

const BATTLE_ROOM_STATE = {
  PROCESSING: "PROCESSING", // For create new BattleRoom
  WAITING: "WAITING", // There is one fighter in the room
  PREPARING: "PREPARING", // Second fighter payment, are preparing cards
  FIGHTING: "FIGHTING", // Two fighters are fighting
  FINISHED: "FINISHED", // After fighting
};

const GAME_RESULT = {
  WIN: "WIN",
  LOSE: "LOSE",
  DRAW: "DRAW",
};

const EVENT_ROOM_STATE = {
  WAITING: "WAITING", // There is one fighter in the room
  PREPARING: "PREPARING", // Second fighter payment, are preparing cards
  FIGHTING: "FIGHTING", // Two fighters are fighting
  FINISHED: "FINISHED", // After fighting
};

export {
  MODE,
  BATTLE_ROOM_STATE,
  USER_BATTLE_ROOM_STATE,
  GAME_RESULT,
  EVENT_ROOM_STATE,
};
