import React, { useEffect, useRef, useState } from "react";

import { Alert, Modal } from "antd";
import { Moralis } from "moralis";
import { useMoralisSubscription } from "react-moralis";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useUserWallet } from "../../hooks/useUserWallet";
import routes from "../../routes";
import settings from "../../settings";
import openModal from "../Modal";

const EventTable = () => {
  const navigate = useNavigate();
  const { decreaseBalance } = useUserWallet();

  const shouldFetchData = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [isShowModalChargeFee, setShowModalChargeFee] = useState(false);

  const countdownTimer = useRef(null);

  useMoralisSubscription(
    "Event",
    (q) => q.notEqualTo("owner", Moralis.User.current()),
    [],
    {
      onCreate: () => {
        fetchData().catch(console.error);
      },
    }
  );

  const fetchData = async () => {
    setIsLoading(true);

    const res = await Moralis.Cloud.run("event_getAll");
    if (res.isOk) {
      const data = res.data;

      if (countdownTimer.current) {
        clearTimeout(countdownTimer.current);
      }

      if (data && data.length > 0) {
        countdownTimer.current = setTimeout(function tick() {
          const now = new Date();
          now.setMilliseconds(0);

          for (let item of data) {
            const startTime = item.startTime;
            const timeInterval = item.timeInterval;
            const timeout = item.timeout;

            const fromStartTimeToNow =
              (now.getTime() - startTime.getTime()) / 1000;

            const timeleft =
              timeout * 60 -
              (fromStartTimeToNow -
                Math.floor(fromStartTimeToNow / (timeInterval * 60)) *
                  timeInterval *
                  60);

            item.remainingTime = getDisplayRemainingTime(timeleft);
          }
          setEvents([...events, ...data]);
          countdownTimer.current = setTimeout(tick, 1000);
        }, 1000);
      } else {
        setEvents(data);
      }
    }

    toast(res.message, { type: "error" });

    setIsLoading(false);
  };

  const onJoinEvent = async (_eventCode) => {
    let res = await Moralis.Cloud.run("event_get", { code: _eventCode });

    if (res.isOk) {
      const event = res.data;

      res = await Moralis.Cloud.run("userCard_validateAvailableCard", {
        level: event.cardLevel,
      });

      if (!res.isOk) {
        toast(res.message, { type: "error" });
        return;
      }

      setSelectedEvent(event);
      setShowModalChargeFee(true);
      return;
    }

    toast(res.message, { type: "error" });
  };

  const deleteEvent = async (_eventCode) => {
    let res = await Moralis.Cloud.run("event_delete", { code: _eventCode });

    if (res.isOk) {
      toast("Delete event successfully!", { type: "success" });
      fetchData().catch(console.error);
      return;
    }

    toast(res.message, { type: "error" });
  };

  const changeState = async (_eventCode) => {
    let res = await Moralis.Cloud.run("event_changeState", {
      code: _eventCode,
    });

    if (res.isOk) {
      toast("Update event successfully!", { type: "success" });
      fetchData().catch(console.error);
      return;
    }

    toast(res.message, { type: "error" });
  };

  const onChargedFee = async () => {
    setShowModalChargeFee(false);

    const modal = openModal("success", {
      className: "custom-modal message-only",
      title: "Processing..",
      content: "Please do not close this window until the process is complete!",
    });

    try {
      const rs = await Moralis.Cloud.run("event_join", {
        code: selectedEvent.code,
      });
      if (rs.isOk) {
        decreaseBalance(selectedEvent.fee);
        modal.destroy();
        navigate && navigate(`/event-room/${rs.data.eventRoom.code}`);
      } else {
        modal.update({
          title: "Error",
          content: rs.message,
        });
      }
    } catch (e) {
      modal.update({
        title: "Error",
        content: e.message,
      });
    }
  };

  const getDisplayRemainingTime = (value) => {
    if (value <= 0) {
      return "00:00";
    }
    const remainingMinute = Math.floor(value / 60);
    const remainingSecond = value % 60;
    return `${
      remainingMinute < 10 ? "0" + remainingMinute : remainingMinute
    } : ${remainingSecond < 10 ? "0" + remainingSecond : remainingSecond}`;
  };

  useEffect(() => {
    if (shouldFetchData.current) {
      shouldFetchData.current = false;
      fetchData().catch(console.error);
    }
    return () => {
      if (countdownTimer.current) {
        clearTimeout(countdownTimer.current);
      }
    };
  }, []);

  // Trick: prevent show warning: Not unsubscribe when unmount
  useEffect(() => {});

  return (
    <div className="group-tbl tournament-section">
      <div className="container">
        <div className="group-tbl__header d-flex justify-content-center two-buttons">
          <div className="gray-lbl">
            <span>
              <img className="img" src="/assets/img/icon-event.svg" alt="" />
              Tournament
            </span>
          </div>
          {settings.ADMIN_ADDRESS.includes(
            Moralis.User.current().get("ethAddress")
          ) && (
            <Link to={routes.CREATE_EVENT} className="btn-long">
              <img
                className="img"
                src="/assets/img/btn-create-event.svg"
                alt=""
              />
            </Link>
          )}
        </div>
        <div className="group-tbl__body">
          <table className="table tbl-style">
            <thead>
              <tr>
                <th scope="col">EVent ID</th>
                <th scope="col">Start</th>
                <th scope="col">End</th>
                <th scope="col">Stars</th>
                <th scope="col">Fee (NAC)</th>
                <th scope="col" className="hot-col">
                  Sponsor (NAC)
                  <img className="img" src="/assets/img/hot-icon.gif" alt="" />
                </th>
                <th scope="col">Min player</th>
                <th scope="col">Max player</th>
                <th scope="col">Remaining time</th>
                <th scope="col">Join</th>
                {settings.ADMIN_ADDRESS.includes(
                  Moralis.User.current().get("ethAddress")
                ) && (
                  <>
                    <th scope="col">Active</th>
                    <th scope="col">Action</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={12}>
                    <span>Loading...</span>
                  </td>
                </tr>
              )}

              {!isLoading && events.length < 1 && (
                <tr>
                  <td colSpan={12}>
                    <span>No rooms available</span>
                  </td>
                </tr>
              )}
              {!isLoading &&
                events.length > 0 &&
                events.map((e, _idx) => (
                  <tr key={_idx}>
                    <td scope="row">{e.code}</td>
                    <td>
                      {new Intl.DateTimeFormat("en-US", {
                        month: "numeric",
                        day: "numeric",
                        year: "numeric",
                        hourCycle: "h24",
                        hour: "numeric",
                        minute: "numeric",
                      }).format(e.startTime)}
                    </td>
                    <td>
                      {new Intl.DateTimeFormat("en-US", {
                        month: "numeric",
                        day: "numeric",
                        year: "numeric",
                        hourCycle: "h24",
                        hour: "numeric",
                        minute: "numeric",
                      }).format(e.endTime)}
                    </td>
                    <td>{e.cardLevel}</td>
                    <td>{e.fee}</td>
                    <td>{e.sponsor}</td>
                    <td>{e.minPlayer}</td>
                    <td>{e.maxPlayer}</td>
                    <td>{e.remainingTime}</td>
                    <td>
                      <Link
                        to={""}
                        onClick={(event) => {
                          event.preventDefault();
                          onJoinEvent(e.code).catch(console.error);
                        }}
                      >
                        <img
                          className="img"
                          src="/assets/img/icon-play.svg"
                          alt=""
                        />
                      </Link>
                    </td>
                    {settings.ADMIN_ADDRESS.includes(
                      Moralis.User.current().get("ethAddress")
                    ) && (
                      <>
                        <td>
                          <Link
                            to={""}
                            onClick={(event) => {
                              event.preventDefault();
                              changeState(e.code).catch(console.error);
                            }}
                          >
                            {e.isActive ? "Active" : "Inactive"}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={""}
                            onClick={(event) => {
                              event.preventDefault();
                              deleteEvent(e.code).catch(console.error);
                            }}
                          >
                            Delete
                          </Link>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        wrapClassName="custom-modal"
        key="modal-deposit"
        visible={isShowModalChargeFee}
        footer={null}
        onCancel={() => {
          setShowModalChargeFee(false);
        }}
      >
        <h3>Join Battle Room Fee</h3>
        <div className="text-center">
          <p className="fs-3 mb-0">
            You will be charged <strong>{selectedEvent.fee} NAC</strong> to join
            room
          </p>
          <Alert
            message="The process is asynchronous so please wait a little bit."
            type="warning"
            showIcon
          />
          <div className="action">
            <div
              className="btn"
              onClick={() => {
                setShowModalChargeFee(false);
              }}
            >
              <img src={"../assets/img/cancel01.jpg"} alt="" />
            </div>
            <div className="btn" onClick={onChargedFee}>
              <img src={"../assets/img/ok.png"} alt="" />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EventTable;
