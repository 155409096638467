import { CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";

export const defaultModalProps = {
  centered: true,
  closable: true,
  closeIcon: <CloseOutlined style={{ color: "white", fontSize: 30 }} />,
  cancelButtonProps: {
    danger: true,
  },
  okButtonProps: {
    type: "success",
  },
  className: "custom-modal",
  icon: false,
};

const openModal = (type, props) => {
  return Modal[type]({
    ...defaultModalProps,
    ...props,
  });
};

const shortcutModal = (type, message, _props = {}) => {
  Modal.destroyAll();

  let props = { content: message, ..._props };
  return openModal(type, props);
};

export const errorModal = (message, _props) =>
  shortcutModal("error", message, _props);
export const successModal = (message, _props) =>
  shortcutModal("success", message, _props);
export const infoModal = (message, _props) =>
  shortcutModal("info", message, _props);
export const warningModal = (message, _props) =>
  shortcutModal("warning", message, _props);

export default openModal;
