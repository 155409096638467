import { useEffect, useRef, useState } from "react";

import { Moralis } from "moralis";
import "../../assets/css/createEvent.css";
import "../../assets/css/createRoom.css";
import "../../assets/css/dashboard.css";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Navigation from "../../components/Navigation";

import "react-datepicker/dist/react-datepicker.css";

const CreateEvent = () => {
  const navigate = useNavigate();
  const shouldFetchData = useRef(true);

  const roomTypes = [
    {
      displayName: "ONE STAR",
      fee: 100,
      cardLevel: 1,
    },
    {
      displayName: "TWO STAR",
      fee: 200,
      cardLevel: 2,
    },
    {
      displayName: "THREE STAR",
      fee: 300,
      cardLevel: 3,
    },
    {
      displayName: "FOUR STAR",
      fee: 400,
      cardLevel: 4,
    },
    {
      displayName: "FIVE STAR",
      fee: 500,
      cardLevel: 5,
    },
  ];

  const [defaultFee, setDefaultFee] = useState(100);
  const [defaultMinPlayer] = useState(4);
  const [defaultMaxPlayer] = useState(20);
  const [event, setEvent] = useState({
    name: "",
    cardLevel: 1,
    fee: 100,
    sponsor: 0,
    startTime: new Date(),
    endTime: new Date(),
    timeInterval: 15,
    timeout: 15,
    minPlayer: 4,
    maxPlayer: 20,
  });

  const onChangeEventName = (e) => {
    setEvent({ ...event, name: e.target.value.trim() });
  };

  const onChangeRoomType = (e) => {
    setEvent({ ...event, cardLevel: parseInt(e.target.value) });
  };

  const onChangeFee = (e) => {
    setEvent({ ...event, fee: e.target.valueAsNumber });
    setDefaultFee(e.target.valueAsNumber);
  };

  const onChangeSponsor = (e) => {
    setEvent({ ...event, sponsor: e.target.valueAsNumber });
  };

  const onChangeTimeInterval = (e) => {
    setEvent({ ...event, timeInterval: parseInt(e.target.value) });
  };

  const onChangeTimeout = (e) => {
    setEvent({ ...event, timeout: parseInt(e.target.value) });
  };

  const onChangeMinPlayer = (e) => {
    setEvent({ ...event, minPlayer: e.target.valueAsNumber });
  };

  const onChangeMaxPlayer = (e) => {
    setEvent({ ...event, maxPlayer: e.target.valueAsNumber });
  };

  const onChangeStartTime = (date) => {
    setEvent({ ...event, startTime: date });
  };

  const onChangeEndTime = (date) => {
    setEvent({ ...event, endTime: date });
  };

  const onCreateEvent = async () => {
    // const startTime = new Date(event.startTime.toUTCString());
    // startTime.setSeconds(0);
    // startTime.setMilliseconds(0);
    //
    // const endTime = new Date(event.endTime.toUTCString());
    // endTime.setSeconds(0);
    // endTime.setMilliseconds(0);

    event.startTime.setSeconds(0);
    event.startTime.setMilliseconds(0);

    event.endTime.setSeconds(0);
    event.endTime.setMilliseconds(0);
    const res = await Moralis.Cloud.run("event_create", event);

    if (res.isOk) {
      toast("Event was created successfully", { type: "success" });
      navigate && navigate(`/pvp`);
      return;
    }

    toast(res.message, { type: "error" });
  };

  useEffect(() => {
    if (shouldFetchData.current) {
      shouldFetchData.current = false;
      const now = new Date();
      now.setSeconds(0);
      now.setMilliseconds(0);

      setEvent({ ...event, startTime: now });
      now.setDate(now.getDate() + 7);
      setEvent({ ...event, endTime: now });
    }
  });

  return (
    <div className="wrap-db">
      {/*Top Navigation*/}
      <Navigation linkActive={""}></Navigation>

      {/*Top Tab*/}
      <div className="create-room medium-page">
        <div className="container">
          <div className="create-room__header">
            <h1 className="big-title">EVENT SETUP</h1>
          </div>
          <div className="create-room___body">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label className="lbl-input">Event Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={onChangeEventName}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label className="lbl-input">Select Type of Room *</label>
                  <select className="form-control" onChange={onChangeRoomType}>
                    {roomTypes.map((e, i) => (
                      <option key={i} value={e.cardLevel}>
                        {e.displayName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label className="lbl-input">Fee (NAC) *</label>
                  <input
                    value={defaultFee}
                    type="number"
                    className="form-control"
                    onChange={onChangeFee}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label className="lbl-input">Sponsor (NAC)</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="1000"
                    onChange={onChangeSponsor}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label className="lbl-input">Start Time</label>
                  <div className="form-control">
                    <DatePicker
                      selected={event.startTime}
                      onChange={onChangeStartTime}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="dd/MM/yyyy HH:mm"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label className="lbl-input">End Time</label>
                  <div className="form-control">
                    <DatePicker
                      selected={event.endTime}
                      onChange={onChangeEndTime}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="dd/MM/yyyy HH:mm"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg">
                <div className="form-group">
                  <label className="lbl-input">Time interval *</label>
                  <select
                    className="form-control"
                    onChange={onChangeTimeInterval}
                  >
                    <option value="15">15 minutes</option>
                    <option value="30">30 minutes</option>
                    <option value="45">45 minutes</option>
                    <option value="60">60 minutes</option>
                  </select>
                </div>
              </div>
              <div className="col-lg">
                <div className="form-group">
                  <label className="lbl-input">Timeout *</label>
                  <select className="form-control" onChange={onChangeTimeout}>
                    <option value="15">15 minutes</option>
                    <option value="30">30 minutes</option>
                    <option value="45">45 minutes</option>
                    <option value="60">60 minutes</option>
                  </select>
                </div>
              </div>
              <div className="col-lg">
                <div className="form-group">
                  <label className="lbl-input">Number players *</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Min"
                    defaultValue={defaultMinPlayer}
                    onChange={onChangeMinPlayer}
                  />
                </div>
              </div>
              <div className="col-lg">
                <div className="form-group">
                  <label className="lbl-input">&nbsp;</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="max"
                    defaultValue={defaultMaxPlayer}
                    onChange={onChangeMaxPlayer}
                  />
                </div>
              </div>
            </div>

            <div className="form-cta">
              <button type="submit" className="btn">
                <img
                  className="img"
                  src="/assets/img/btn-create.svg"
                  alt=""
                  onClick={onCreateEvent}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateEvent;
