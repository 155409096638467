import { useEffect, useRef, useState } from "react";

import { Moralis } from "moralis";
import { useMoralis, useMoralisSubscription } from "react-moralis";
import { Link } from "react-router-dom";

import useComponentVisible from "../hooks/useComponentVisible";

const Notification = () => {
  const shouldFetchData = useRef(true);
  const { isAuthenticated } = useMoralis();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  // const [isShow, setIsShow] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState([]);

  const onClickNotification = async (e) => {
    e.preventDefault();
    setIsComponentVisible(true);
    if (unreadCount > 0) {
      await Moralis.Cloud.run("notification_markReadAll");
    }
  };

  useMoralisSubscription(
    "Notification",
    (q) => q.equalTo("owner", Moralis.User.current()),
    [],
    {
      onCreate: (data) => {
        getUnreadCount().catch(console.error);
      },
      onUpdate: (data) => {
        getUnreadCount().catch(console.error);
      },
    }
  );

  const getUnreadCount = async () => {
    const res = await Moralis.Cloud.run("notification_getUnreadCount");
    if (res.isOk) {
      setUnreadCount(res.count);
    }
  };

  const getNotifications = async () => {
    const res = await Moralis.Cloud.run("notification_getAll");
    if (res.isOk) {
      setNotifications(res.data);
    }
  };

  useEffect(() => {
    if (isComponentVisible) {
      getNotifications().catch(console.error);
    }
  }, [isComponentVisible]);

  useEffect(() => {
    if (shouldFetchData.current && isAuthenticated) {
      shouldFetchData.current = false;
      getUnreadCount().catch(console.error);
    }
  }, [isAuthenticated]);

  return (
    <>
      <div className="position-relative">
        <Link
          to={""}
          className="notification__link"
          // onMouseEnter={onHover}
          // onMouseLeave={onHover}
          onClick={onClickNotification}
        >
          {unreadCount > 0 && (
            <div className="notification__number">{unreadCount}</div>
          )}

          <img src={"/assets/img/notification.svg"} alt="" />
        </Link>
        {isComponentVisible && (
          <div ref={ref} className="notification__dropdown-wrap">
            <div className="notification__dropdown">
              <ul className="ul notification__ul">
                {notifications.length > 0 &&
                  notifications.map((notification, idx) => (
                    <li
                      key={idx}
                      dangerouslySetInnerHTML={{
                        __html: notification.message,
                      }}
                    ></li>
                  ))}
              </ul>
              <div className="view-all">View All</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Notification;
