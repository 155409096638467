import { useEffect, useRef, useState } from "react";

import { Moralis } from "moralis";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const AutoMatchingTable = ({ onAction }) => {
  const shouldFetchData = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [battleRooms, setBattleRooms] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);

    const res = await Moralis.Cloud.run("battleRoom_getAutoMatchingRooms", {});
    if (res.isOk) {
      setBattleRooms(res.data);
    }

    toast(res.message, { type: "error" });

    setIsLoading(false);
  };

  useEffect(() => {
    if (shouldFetchData.current) {
      shouldFetchData.current = false;
      fetchData().catch(console.error);
    }
  }, []);

  return (
    <div className="group-tbl">
      <div className="container">
        <div className="group-tbl__header">
          <div className="gray-lbl">
            <span>Auto Matching</span>
          </div>
        </div>
        <div className="group-tbl__body">
          {isLoading && <p>Loading ...</p>}
          {!isLoading && (
            <ul className="ul auto-matching">
              {battleRooms.length > 0 &&
                battleRooms.map((e, _id) => (
                  <li key={_id}>
                    <div className="auto-matching__item">
                      <div className="number-fee">
                        {e.fee}
                        <span>NAC</span>
                      </div>
                      <div className="number-star">
                        {[...Array(e.cardLevel).keys()].map((a, b) => (
                          <img
                            key={b}
                            className="icon-star"
                            src="/assets/img/star.svg"
                            alt=""
                          />
                        ))}
                      </div>
                      <div className="auto-matching__action">
                        <Link
                          to={""}
                          className="btn-join"
                          onClick={(event) => {
                            event.preventDefault();
                            onAction(e.code);
                          }}
                        >
                          <img
                            className="icon-right icon-search"
                            src="/assets/img/btn-join.svg"
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default AutoMatchingTable;
