import { useState } from "react";

import { Input, Modal } from "antd";

const ConfirmPassword = ({ onSubmit, onClose }) => {
  const [password, setPassword] = useState("");

  const onChangePassword = (event) => {
    setPassword(event.target.value.trim());
  };

  return (
    <Modal
      wrapClassName="custom-modal"
      visible={true}
      footer={null}
      onCancel={onClose}
    >
      <h3>Password</h3>
      <div className="text-center">
        <Input defaultValue={""} onChange={onChangePassword} />
        <div className="action">
          <div className="btn" onClick={() => onSubmit(password)}>
            <img src={"../assets/img/ok.png"} alt="" />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmPassword;
