export const shortenId = (id) => {
  const l = id.length;
  return id.slice(0, 4) + "..." + id.slice(l - 3, l);
};

export const level = (exp) => {
  return Math.floor(exp / 10);
};

export const randId = (length) => {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const randShortId = () => {
  return shortenId(randId(12));
};

export const randomIdx = (total) => {
  return Math.floor(Math.random() * total);
};

export const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const normalize = (value, decimals = 2) => {
  // get only 2 decimals
  return (
    Math.round(parseFloat(value) * Math.pow(10, decimals)) /
    Math.pow(10, decimals)
  );
};

export const getUTCDay = () => {
  const utcNow = new Date();
  const utcDay = utcNow.toISOString().split("T")[0];
  return utcDay;
};

export const rc4 = (key, str) => {
  var s = [],
    j = 0,
    x,
    res = "";
  for (var i = 0; i < 256; i++) {
    s[i] = i;
  }
  for (i = 0; i < 256; i++) {
    j = (j + s[i] + key.charCodeAt(i % key.length)) % 256;
    x = s[i];
    s[i] = s[j];
    s[j] = x;
  }
  i = 0;
  j = 0;
  for (var y = 0; y < str.length; y++) {
    i = (i + 1) % 256;
    j = (j + s[i]) % 256;
    x = s[i];
    s[i] = s[j];
    s[j] = x;
    res += String.fromCharCode(str.charCodeAt(y) ^ s[(s[i] + s[j]) % 256]);
  }
  return res;
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// This is an util to generate a shuffled array, with deterministic randomness provided by the seed.
export const shuffleWithSeed = (array, seed) => {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;
  seed = seed || 1;
  let random = function () {
    var x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  };
  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(random() * currentIndex);
    currentIndex -= 1;
    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
};

export const toRoute = (baseRoute, params) => {
  let route = baseRoute;
  if (params) {
    Object.keys(params).forEach((k) => {
      const value = params[k];
      route = route.replace(":" + k, value);
    });
  }
  return route;
};

export const hasDuplicates = (array) => {
  return new Set(array).size !== array.length;
};

export const isNull = (value) => {
  return value === null || value === undefined;
};

export const isNotNull = (value) => {
  return !isNull(value);
};

export const isNullOrWhiteSpace = (value) => {
  return isNull(value) || value.trim() === "";
};

export const isEmpty = (value) => {
  if (isNull(value)) {
    return true;
  }

  if (typeof value === "object") {
    return Object.keys(value).length === 0;
  }

  if (Array.isArray(value)) {
    return value.length === 0;
  }

  return false;
};

export const isNotEmpty = (value) => {
  return !isEmpty(value);
};

export const truncate = (value, max) => {
  const str = String(value || "");
  return str.substring(0, max) + (str.length > max ? "..." : "");
};
