import { useEffect, useState } from "react";

import { useSoundState } from "./useSoundState";

export const useAudio = (url) => {
  const { isSoundOn, setSoundState } = useSoundState();
  const [audio] = useState(new Audio(url));

  const toggleAudio = () => setSoundState(!isSoundOn);
  useEffect(() => {
    isSoundOn ? audio.play() : audio.pause();
  }, [isSoundOn, audio]);

  useEffect(() => {
    return () => {
      audio.pause();
    };
  }, []);

  return { toggleAudio, url, isSoundOn };
};
