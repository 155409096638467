import React, { useEffect, useState, useRef } from "react";

import { Moralis } from "moralis";

import settings from "../../settings";
import Pagination from ".././Pagination";

const AvailableCard = ({ limitLevel, itemPerPage, excludes, onClickCard }) => {
  const CARD_LEVELS = [
    {
      value: 0,
      displayName: "All",
    },
    {
      value: 1,
      displayName: "Level 1",
    },
    {
      value: 2,
      displayName: "Level 2",
    },
    {
      value: 3,
      displayName: "Level 3",
    },
    {
      value: 4,
      displayName: "Level 4",
    },
    {
      value: 5,
      displayName: "Level 5",
    },
  ];

  const CARD_TYPES = [
    {
      value: "",
      displayName: "All",
    },
    {
      value: "leaf",
      displayName: "Leaf",
    },
    {
      value: " wind",
      displayName: "Wind",
    },
    {
      value: "water",
      displayName: "Water",
    },
    {
      value: "earth",
      displayName: "Earth",
    },
    {
      value: "fire",
      displayName: "Fire",
    },
  ];

  const shouldFetchData = useRef(true);

  const ITEM_PER_PAGE = itemPerPage ? itemPerPage : settings.ITEM_PER_PAGE;
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [userCards, setUserCards] = useState([]);
  const [selectedCardLevel, setSelectedCardLevel] = useState(0);
  const [selectedCardType, setSelectedCardType] = useState("");

  const onChangeCardLevel = async (e) => {
    shouldFetchData.current = true;
    setSelectedCardLevel(parseInt(e.target.value));
  };

  const onChangeCardType = async (e) => {
    shouldFetchData.current = true;
    setSelectedCardType(e.target.value);
  };

  const onChangePage = async (page) => {
    shouldFetchData.current = true;
    setPage(page);
  };

  const fetchUserCards = async () => {
    setIsLoading(false);
    const res = await Moralis.Cloud.run("userCard_getAvailableCards", {
      page: page,
      itemPerPage: ITEM_PER_PAGE,
      limitLevel: limitLevel,
      excludes: excludes,
      cardLevel: selectedCardLevel,
      cardType: selectedCardType,
    });

    if (res.isOk) {
      let _totalPage = Math.ceil(res.total / ITEM_PER_PAGE);
      setUserCards(res.cards);
      setTotalPage(_totalPage);
      setIsLoading(true);
    }
  };

  useEffect(() => {
    if (shouldFetchData.current) {
      shouldFetchData.current = false;
      fetchUserCards().catch(console.error);
    }
  }, [page, selectedCardLevel, selectedCardType]);

  return (
    <>
      <div className="filter-card">
        <div className="form-group">
          <label className="lbl-input">Level</label>
          <select className="form-control" onChange={onChangeCardLevel}>
            {CARD_LEVELS.map((e, i) => (
              <option key={i} value={e.value}>
                {e.displayName}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label className="lbl-input">Type</label>
          <select className="form-control" onChange={onChangeCardType}>
            {CARD_TYPES.map((e, i) => (
              <option key={i} value={e.value}>
                {e.displayName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="list" style={{ display: "flex" }}>
        {!isLoading && <span>Loading...</span>}\
        {isLoading && userCards.length < 1 && (
          <span className="fs-2">No cards available</span>
        )}
        {isLoading &&
          userCards.length > 0 &&
          userCards.map((card, idx) => {
            return (
              <div
                key={idx}
                className="img"
                style={{ width: 150, cursor: "pointer" }}
              >
                <div onClick={() => onClickCard(card)}>
                  {card.uri && (
                    <img
                      src={`${settings.CARD_IMAGE_HOST}/${card.uri}`}
                      alt=""
                    />
                  )}
                  <div style={{ color: "#000" }}>
                    #{card.nftId} {card.isNew && <span>(New Card)</span>}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <Pagination
        totalPage={totalPage}
        currentPage={page}
        onChange={onChangePage}
      />
    </>
  );
};

export default AvailableCard;
