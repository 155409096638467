import React, { useEffect, useRef, useState } from "react";

import { Alert, Modal } from "antd";
import { Moralis } from "moralis";
import { useMoralisSubscription } from "react-moralis";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { EVENT_ROOM_STATE } from "../../constants";
import settings from "../../settings";

const TournamentTable = ({ onAction }) => {
  const shouldFetchData = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [events, setEvents] = useState([]);
  const [fee, setFee] = useState(0);
  const [isShowModalChargeFee, setShowModalChargeFee] = useState(false);

  useMoralisSubscription(
    "EventRoom",
    (q) => q.notEqualTo("owner", Moralis.User.current()),
    [],
    {
      onUpdate: (data) => {
        if (data.get("state") === EVENT_ROOM_STATE.WAITING) {
          fetchData().catch(console.error);
        }
      },
      onCreate: () => {
        fetchData().catch(console.error);
      },
    }
  );

  const fetchData = async () => {
    setIsLoading(true);

    const filterOptions = {
      page: currentPage,
      itemPerPage: settings.ITEM_PER_PAGE,
      searchTerm: "",
    };

    const res = await Moralis.Cloud.run("eventRoom_getActive");
    if (res.isOk) {
      const _totalPage = Math.ceil(res.count / filterOptions.itemPerPage);
      setTotalPage(_totalPage);
      setEvents(res.data);
    }

    toast(res.message, { type: "error" });

    setIsLoading(false);
  };

  const onChargedFee = async () => {};

  useEffect(() => {
    if (shouldFetchData.current) {
      shouldFetchData.current = false;
      fetchData().catch(console.error);
    }
  }, [currentPage]);

  // Trick: prevent show warning: Not unsubscribe when unmount
  useEffect(() => {});

  return (
    <div className="group-tbl tournament-section">
      <div className="container">
        {/*<div className="group-tbl__header d-flex justify-content-center">*/}
        {/*  <div className="gray-lbl">*/}
        {/*    <span>*/}
        {/*      <img className="img" src="/assets/img/icon-event.svg" alt="" />*/}
        {/*      Tournament*/}
        {/*    </span>*/}
        {/*  </div>*/}
        {/*  <Link to={routes.CREATE_EVENT} className="btn-long">*/}
        {/*    <img*/}
        {/*      className="img"*/}
        {/*      src="/assets/img/btn-create-event.svg"*/}
        {/*      alt=""*/}
        {/*    />*/}
        {/*  </Link>*/}
        {/*</div>*/}
        <div className="group-tbl__body">
          <table className="table tbl-style">
            <thead>
              <tr>
                <th scope="col">Room ID</th>
                <th scope="col">Start</th>
                <th scope="col">End</th>
                <th scope="col">Stars</th>
                <th scope="col">Fee (NAC)</th>
                <th scope="col">Funding</th>
                <th scope="col">Ready</th>
                {/*<th scope="col">Join</th>*/}
              </tr>
            </thead>
            <tbody>
              {!isLoading &&
                events.length > 0 &&
                events.map((e, _idx) => (
                  <tr key={_idx}>
                    <td scope="row">{e.code}</td>
                    <td>
                      {new Intl.DateTimeFormat("en-US", {
                        month: "numeric",
                        day: "numeric",
                        year: "numeric",
                        hourCycle: "h24",
                        hour: "numeric",
                        minute: "numeric",
                      }).format(e.startTime)}
                    </td>
                    <td>
                      {new Intl.DateTimeFormat("en-US", {
                        month: "numeric",
                        day: "numeric",
                        year: "numeric",
                        hourCycle: "h24",
                        hour: "numeric",
                        minute: "numeric",
                      }).format(e.endTime)}
                    </td>
                    <td>{e.cardLevel}</td>
                    <td>{e.fee}</td>
                    <td>{e.sponsor}</td>
                    <td>
                      {e.readyPlayer}/{e.maxPlayer}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          {totalPage > 1 && (
            <div className="pagination">
              <div className="pagination__in">
                <Link
                  to="#"
                  className="btn-pagination"
                  disabled={currentPage === 1}
                  onClick={(event) => {
                    event.preventDefault();
                    if (currentPage > 1) {
                      shouldFetchData.current = true;
                      setCurrentPage(currentPage - 1);
                    }
                  }}
                >
                  <img className="img" src="/assets/img/prev.svg" alt="" />
                </Link>
                <span className="pagination-id">
                  {currentPage}/{totalPage}
                </span>
                <Link
                  to="#"
                  className="btn-pagination"
                  disabled={currentPage === totalPage}
                  onClick={(event) => {
                    event.preventDefault();
                    if (currentPage < totalPage) {
                      shouldFetchData.current = true;
                      setCurrentPage(currentPage + 1);
                    }
                  }}
                >
                  <img className="img" src="/assets/img/next.svg" alt="" />
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        wrapClassName="custom-modal"
        key="modal-deposit"
        visible={isShowModalChargeFee}
        footer={null}
        onCancel={() => {
          setShowModalChargeFee(false);
        }}
      >
        <h3>Join Battle Room Fee</h3>
        <div className="text-center">
          <p className="fs-3 mb-0">
            You will be charged <strong>{fee} NAC</strong> to join room
          </p>
          <Alert
            message="The process is asynchronous so please wait a little bit."
            type="warning"
            showIcon
          />
          <div className="action">
            <div
              className="btn"
              onClick={() => {
                setShowModalChargeFee(false);
              }}
            >
              <img src={"../assets/img/cancel01.jpg"} alt="" />
            </div>
            <div className="btn" onClick={onChargedFee}>
              <img src={"../assets/img/ok.png"} alt="" />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TournamentTable;
