import { useEffect, useState } from "react";

import { Input, Modal } from "antd";
import _ from "lodash";
import { Moralis } from "moralis";
import { useMoralis } from "react-moralis";
import { useNavigate } from "react-router-dom";

import "../../assets/css/inventory.css";
import inventorySound from "../../assets/music/inventory.wav";
import openModal from "../../components/Modal";
import Pagination from "../../components/Pagination";
import ContractUtils from "../../ContractUtils";
import { useAudio } from "../../hooks/useAudio";
import useUserNFTs from "../../hooks/useUserNFTs";
import routes from "../../routes";
import settings from "../../settings";
import { toRoute } from "../../utils";

const Inventory = () => {
  const navigate = useNavigate();
  useAudio(inventorySound);
  const { user } = useMoralis();

  const OPTIONS = [
    { label: "Star Asc", value: "level-1" },
    { label: "Star Desc", value: "level-0" },
    { label: "Id Asc", value: "nftId-1" },
    { label: "Id Desc", value: "nftId-0" },
  ];

  const STAR_OPTIONS = [
    { label: "All", value: 0 },
    { label: "1 Star", value: 1 },
    { label: "2 Stars", value: 2 },
    { label: "3 Stars", value: 3 },
    { label: "4 Stars", value: 4 },
    { label: "5 Stars", value: 5 },
  ];

  const CLASS_OPTIONS = [
    { label: "All", value: "" },
    { label: "Water", value: "water" },
    { label: "Leaf", value: "leaf" },
    { label: "Earth", value: "earth" },
    { label: "Wind", value: "wind" },
    { label: "Fire", value: "fire" },
  ];

  const [page, setPage] = useState(1);
  const [isReady, setIsReady] = useState(false);
  const [totalCard, setTotalCard] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [userCards, setUserCards] = useState([]);

  const [orderState, setOrderState] = useState("level-1");
  const [starFiltered, setStarFiltered] = useState(0);
  const [classFiltered, setClassFiltered] = useState("");
  const [isSellModalVisible, setIsSellModalVisible] = useState(false);
  const [price, setPrice] = useState(0);
  const [sellingCard, setSellingCard] = useState(undefined);

  const [viewMode, setViewMode] = useState("inventory");
  const [yourListingOffers, setYourListingOffers] = useState([]);
  const [yourListingOffersPaginated, setYourListingOffersPaginated] = useState(
    []
  );

  const { getUserNFTsData } = useUserNFTs(false);

  const onChangePage = async (page) => {
    setPage(page);
  };

  const fetchUserCards = async (
    _class = "",
    _level = 0,
    _page = 1,
    _orderBy = "level",
    _asc = 1
  ) => {
    setIsReady(false);
    const nftsData = await getUserNFTsData(
      _class,
      _level,
      _page,
      _orderBy,
      _asc,
      settings.ITEM_PER_PAGE
    );
    let _totalPage = Math.ceil(nftsData.total / settings.ITEM_PER_PAGE);

    setTotalCard(nftsData.total);
    setUserCards(nftsData.cards);
    setTotalPage(_totalPage);
    setIsReady(true);
  };

  const onClickCard = async (card) => {
    if (card.isNew) {
      await Moralis.Cloud.run("card_seenCard", { nftId: card.nftId });
    }
    navigate(toRoute(routes.INVENTORY_DETAIL, { id: card.nftId }));
  };

  const onChangeSelectOrder = (e) => {
    setOrderState(e.target.value);
    setPage(1);
  };

  const onChangeSelectStarFiltered = (e) => {
    setStarFiltered(Number(e.target.value));
    setPage(1);
  };

  const onChangeClassFiltered = (e) => {
    setClassFiltered(e.target.value);
    setPage(1);
  };

  const onSellClick = async (card) => {
    if (card.farming) {
      return;
    }
    setSellingCard(card);
    setIsSellModalVisible(true);
  };

  const approveTransaction = (nftId) => {
    return new Promise((resolve, reject) => {
      let messageModal = null;
      let txHash = null;

      window.nftContract.methods
        .approve(settings.MARKETPLACE_CONTRACT, nftId)
        .send({
          from: user.get("ethAddress"),
        })
        .on("transactionHash", (hash) => {
          txHash = hash;
          messageModal = openModal("warning", {
            className: "message-only",
            title: "Approving",
            content: (
              <div className="text-center">
                The transaction hash:
                <div> {hash}</div>
                <h3 className="m-3">Please wait...</h3>
              </div>
            ),
          });
        })
        .on("error", (error) => {
          reject(error);
        })
        // .on('confirmation', (number, data) => console.log('confirm', data))
        .on("receipt", async (data) => {
          resolve({ messageModal, data, txHash });
        });
    });
  };

  const makeOpenOfferTransaction = (nftId, price) => {
    return new Promise((resolve, reject) => {
      let messageModal = null;
      let txHash = null;

      window.marketplaceContract.methods
        .openOffer(nftId, Moralis.Units.Token(price, "18"))
        .send({
          from: user.get("ethAddress"),
        })
        .on("transactionHash", (hash) => {
          txHash = hash;
          messageModal = openModal("warning", {
            className: "message-only",
            title: "Processing",
            content: (
              <div className="text-center">
                The transaction hash:
                <div> {hash}</div>
                <h3 className="m-3">Please wait...</h3>
              </div>
            ),
          });
        })
        .on("error", (error) => {
          reject(error);
        })
        // .on('confirmation', (number, data) => console.log('confirm', data))
        .on("receipt", async (data) => {
          resolve({ messageModal, data, txHash });
        });
    });
  };

  const onSellAction = async (card) => {
    if (card.farming) {
      return;
    }

    const contractApproved = await window.nftContract.methods
      .getApproved(card.nftId)
      .call();

    try {
      if (contractApproved !== settings.MARKETPLACE_CONTRACT) {
        const transaction = await approveTransaction(card.nftId);
        const { messageModal } = transaction;

        messageModal.destroy();
      }

      const transaction = await makeOpenOfferTransaction(card.nftId, price);
      const { messageModal } = transaction;
      messageModal.update({
        title: "Success",
        content: <div>Your NFT with ID #{card.nftId} listed successfully</div>,
      });

      // history.push(routes.MARKETPLACE);
      navigate && navigate(routes.MARKETPLACE);
      // removeCardFromUserCards(nftId);
      // setIsSellModalVisible(false);
      // setPrice(0);
      // setSellingCard(0);
      // fetchYourListing();
    } catch (error) {
      openModal("error", {
        title: "Error",
        content: "Unable to process the transaction.",
      });
    }
  };

  const fetchYourListing = async () => {
    const data = await window.marketplaceContract.methods
      .fetchListingByAddress(ContractUtils.address)
      .call();
    const listingOffers = data.map((offer) => {
      return {
        id: offer["id"],
        class: offer["class"],
        nftId: Number(offer["item"]),
        level: Number(offer["level"]),
        price: offer["price"],
        uri: offer["uri"],
        status: offer["status"],
      };
    });
    const listingOffersFiltered = listingOffers.filter(
      (offer) => offer.level > 0
    );
    const totalItems = listingOffersFiltered.length;
    const totalPages = Math.ceil(
      listingOffersFiltered.length / settings.ITEM_PER_PAGE
    );

    setYourListingOffers(listingOffersFiltered);
    setTotalCard(totalItems);
    setTotalPage(totalPages);
    setPage(1);
  };

  const onChangeViewMode = (mode) => {
    setViewMode(mode);
    setPage(1);
  };

  const onFilterListingChange = (listingCards, _page, orderBy, order) => {
    if (page > 0 && listingCards.length) {
      let _yourListingOffers = _.orderBy(
        listingCards,
        [orderBy],
        [order === "1" ? "asc" : "desc"]
      );

      let _yourListingOffersFiltered = _yourListingOffers;
      if (starFiltered) {
        _yourListingOffersFiltered = _.filter(
          _yourListingOffers,
          (c) => Number(c.level) === starFiltered
        );
      }

      if (classFiltered) {
        _yourListingOffersFiltered = _.filter(
          _yourListingOffers,
          (c) => c.class === classFiltered
        );
      }

      const _yourListingOffersPaginated = [];

      const start = (_page - 1) * settings.ITEM_PER_PAGE;
      const end = start + settings.ITEM_PER_PAGE;

      if (_yourListingOffersFiltered) {
        for (let i = start; i < end; i++) {
          if (_yourListingOffersFiltered[i]) {
            _yourListingOffersPaginated.push(_yourListingOffersFiltered[i]);
          }
        }
      }

      const _totalCard = _yourListingOffersFiltered.length;
      const _totalPage = Math.ceil(_totalCard / settings.ITEM_PER_PAGE);

      setYourListingOffersPaginated(_yourListingOffersPaginated);
      setTotalPage(_totalPage);
      setTotalCard(_totalCard);
    }
  };

  useEffect(() => {
    if (viewMode === "inventory") {
      fetchUserCards();
    } else {
      fetchYourListing();
    }
  }, [viewMode]);

  useEffect(() => {
    let _orderState = orderState.split("-");
    if (viewMode === "inventory") {
      fetchUserCards(
        classFiltered,
        starFiltered,
        page,
        _orderState[0],
        Number(_orderState[1])
      );
    }

    if (viewMode === "listing") {
      onFilterListingChange(
        yourListingOffers,
        page,
        _orderState[0],
        _orderState[1]
      );
    }
  }, [
    viewMode,
    page,
    yourListingOffers,
    orderState,
    starFiltered,
    classFiltered,
  ]);

  if (!isReady) {
    return <span>Loading...</span>;
  }

  return (
    <div className="content inventory">
      <div className="wrap-content inventory">
        <div className="wrap-info">
          <div className="find-top">
            <div className="find-btn">
              <button onClick={() => onChangeViewMode("inventory")}>
                Inventory
              </button>
              <button onClick={() => onChangeViewMode("listing")}>
                Listing
              </button>
            </div>
            <div className="title">
              Total card: <span>{totalCard}</span>
            </div>
            <div className="find-info">
              <div className="sub-list">
                <span>Sort: </span>
                <select onChange={onChangeSelectOrder}>
                  {OPTIONS.map((o) => {
                    return (
                      <option
                        key={`sort_${o.value}`}
                        value={o.value}
                        selected={o.value === orderState}
                      >
                        {o.label}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="sub-list">
                <span>Star: </span>
                <select onChange={onChangeSelectStarFiltered}>
                  {STAR_OPTIONS.map((o) => {
                    return (
                      <option
                        key={`start_${o.value}`}
                        value={o.value}
                        selected={o.value === starFiltered}
                      >
                        {o.label}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="sub-list">
                <span>Class: </span>
                <select onChange={onChangeClassFiltered}>
                  {CLASS_OPTIONS.map((o) => {
                    return (
                      <option
                        key={`class_${o.value}`}
                        value={o.value}
                        selected={o.value === classFiltered}
                      >
                        {o.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>

          {viewMode === "inventory" && (
            <>
              <div className="list">
                {userCards.map((card) => {
                  return (
                    <div
                      key={`card_${card.nftId}`}
                      className="img"
                      style={{ cursor: "pointer" }}
                    >
                      <div onClick={() => onClickCard(card)}>
                        {card.uri && (
                          <img
                            src={`${settings.CARD_IMAGE_HOST}/${card.uri}`}
                            alt=""
                          />
                        )}
                        <div style={{ color: "#000" }}>
                          #{card.nftId}
                          {card.isNew && <span>(New Card)</span>}
                          {card.farming && <div>Farming</div>}
                        </div>
                      </div>
                      {!card.farming && (
                        <div>
                          <button onClick={() => onSellClick(card)}>
                            SELL
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          )}

          {viewMode === "listing" && (
            <>
              <div className="list">
                {/* <p>Your Listing</p> */}
                {yourListingOffersPaginated.map((offer) => {
                  return (
                    <div key={`listing_${offer?.nftId}`} className="img">
                      <div>
                        {offer?.uri && (
                          <img
                            src={`${settings.CARD_IMAGE_HOST}/${offer?.uri}`}
                            alt=""
                          />
                        )}
                        <div style={{ color: "#000" }}>
                          #{offer?.nftId}: {offer?.price / 1e18} NAC
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}

          <Pagination
            totalPage={totalPage}
            currentPage={page}
            onChange={onChangePage}
          />
        </div>
        {/* {cardSelected &&
                    <div className="content-fixed">
                        <div className="info">
                            <div className="fixed-link inventory">
                                <div className="star">
                                    <div className="title">Star level:</div>
                                    <ul className="list-star">
                                        <li><img src="/assets/img/star.png" alt="" /></li>
                                        <li><img src="/assets/img/star.png" alt="" /></li>
                                        <li><img src="/assets/img/star.png" alt="" /></li>
                                        <li><img src="/assets/img/star.png" alt="" /></li>
                                        <li><img src="/assets/img/star.png" alt="" /></li>
                                    </ul>
                                </div>
                                <ul className="fixed-menu">
                                    <li><a href="inventory_detail.html">Details</a></li>
                                    <li><a href="#">Sell</a></li>
                                    <li><a href="#">Transfer</a></li>
                                    <li><a href="farming">Farming</a></li>
                                </ul>
                            </div>
                            <div className="fixed-content">
                                <div className="text">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                                    euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                                </div>
                            </div>
                        </div>
                    </div>
                } */}

        <Modal
          className="custom-modal inven"
          visible={isSellModalVisible}
          closable
          footer={false}
          onCancel={() => setIsSellModalVisible(false)}
          destroyOnClose
        >
          {sellingCard && (
            <div className="item">
              <img
                style={{ width: "300px" }}
                src={`${settings.CARD_IMAGE_HOST}/${sellingCard.uri}`}
                alt=""
              />
              <Input
                placeholder="Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
              <button onClick={() => onSellAction(sellingCard)}>SEll</button>
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default Inventory;
