const Pagination = ({ totalPage, currentPage, onChange }) => {
  let startPage = currentPage > 4 ? currentPage - 4 : 1;
  let endPage = currentPage < totalPage - 4 ? currentPage + 4 : totalPage;
  const visiblePages = Array(endPage - startPage + 1)
    .fill()
    .map((_, idx) => startPage + idx);

  if (!totalPage) {
    return <></>;
  }

  return (
    <div className="pagination">
      <button onClick={() => onChange(1)}>{"<<"}</button>
      <button onClick={() => onChange(currentPage > 1 ? currentPage - 1 : 1)}>
        {"<"}
      </button>
      {visiblePages.map((_page) => {
        return (
          <button key={`pagination_${_page}`} onClick={() => onChange(_page)}>
            {_page} {_page === currentPage && "*"}
          </button>
        );
      })}
      <button
        onClick={() =>
          onChange(currentPage < totalPage ? currentPage + 1 : totalPage)
        }
      >
        {">"}
      </button>
      <button onClick={() => onChange(totalPage)}>{">>"}</button>
    </div>
  );
};

export default Pagination;
