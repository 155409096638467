import { useEffect, useState, useRef } from "react";

import useUserNFTs from "../hooks/useUserNFTs";
import settings from "../settings";
import Pagination from "./Pagination";

const ListCard = ({ level, onClickCard, itemPerPage }) => {
  const shouldFetchData = useRef(true);

  const ITEM_PER_PAGE = itemPerPage ? itemPerPage : settings.ITEM_PER_PAGE;
  const [page, setPage] = useState(1);
  const [isReady, setIsReady] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [userCards, setUserCards] = useState([]);

  const { getUserNFTsData } = useUserNFTs(false);

  const onChangePage = async (page) => {
    shouldFetchData.current = true;
    setPage(page);
  };

  const fetchUserCards = async (
    _class = "",
    _level = 0,
    _page = 1,
    _orderBy = "level",
    _asc = 1
  ) => {
    setIsReady(false);
    const nftsData = await getUserNFTsData(
      _class,
      _level,
      _page,
      _orderBy,
      _asc,
      ITEM_PER_PAGE
    );
    let _totalPage = Math.ceil(nftsData.total / ITEM_PER_PAGE);
    setUserCards(nftsData.cards);
    setTotalPage(_totalPage);
    setIsReady(true);
  };

  useEffect(() => {
    if (shouldFetchData.current) {
      shouldFetchData.current = false;
      fetchUserCards("", level, page).catch(console.error);
    }
  }, [page, level]);

  if (!isReady) {
    return <span>Loading...</span>;
  }

  if (userCards.length < 1) {
    return <span className="fs-2">No cards available</span>;
  }
  return (
    <>
      <div className="list" style={{ display: "flex" }}>
        {userCards.map((card, idx) => {
          return (
            <div
              key={idx}
              className="img"
              style={{ width: 150, cursor: "pointer" }}
            >
              <div onClick={() => onClickCard(card)}>
                {card.uri && (
                  <img src={`${settings.CARD_IMAGE_HOST}/${card.uri}`} alt="" />
                )}
                <div style={{ color: "#000" }}>
                  #{card.nftId} {card.isNew && <span>(New Card)</span>}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Pagination
        totalPage={totalPage}
        currentPage={page}
        onChange={onChangePage}
      />
    </>
  );
};

export default ListCard;
