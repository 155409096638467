import React from "react";

import { Link, useLocation } from "react-router-dom";

import router from "../routes";

const LeftMenu = () => {
  const location = useLocation();
  let pathName = location.pathname;
  let hashName = location.hash.replace("#/", "/");

  pathName = pathName || hashName;

  if (pathName === "/") pathName = "/shop";

  return (
    <div className="side">
      <div className="s-box">
        <div className="s-logo">
          <Link className="s-nav-list__link" to={router.HOME}>
            <img src={"/assets/img/s_logo.svg"} alt="" />
          </Link>
        </div>
        <ul className="s-nav">
          <li
            className={`s-nav-list__item ${
              pathName === router.SHOP ? "current" : ""
            }`}
          >
            <Link to={router.SHOP} className="s-nav-list__link">
              <span>Summoners Gate</span>
            </Link>
          </li>
          <li
            className={`s-nav-list__item ${
              pathName === router.INVENTORY ? "current" : ""
            }`}
          >
            <Link to={router.INVENTORY} className="s-nav-list__link">
              <span>Inventory</span>
            </Link>
          </li>
          <li
            className={`s-nav-list__item ${
              pathName === router.MARKETPLACE ? "current" : ""
            }`}
          >
            <Link to={router.MARKETPLACE} className="s-nav-list__link">
              <span>Marketplace</span>
            </Link>
          </li>
          <li
            className={`s-nav-list__item ${
              pathName === router.FARM ? "current" : ""
            }`}
          >
            <Link to={router.FARM} className="s-nav-list__link">
              <span>Guardians Camp</span>
            </Link>
          </li>
          {/*<li*/}
          {/*  className={`s-nav-list__item ${*/}
          {/*    pathName === router.SHOP ? "current" : ""*/}
          {/*  }`}*/}
          {/*  id="arena"*/}
          {/*>*/}
          {/*  <Link to={router.PVE} className="s-nav-list__link">*/}
          {/*    <span className="soon">Arena of valor</span>*/}
          {/*  </Link>*/}
          {/*</li>*/}

          <li
            className={`s-nav-list__item ${
              pathName === router.PVE ? "current" : ""
            }`}
            id="pve"
          >
            <Link to={router.PVE} className="s-nav-list__link">
              <span>PVE</span>
            </Link>
          </li>
          <li
            className={`s-nav-list__item ${
              pathName === router.PVP ? "current" : ""
            }`}
            id="pvp"
          >
            <Link to={router.PVP} className="s-nav-list__link">
              <span>
                PVP
                <img className="img" src="/assets/img/new-icon.jpg" alt="" />
              </span>
            </Link>
          </li>
        </ul>
        <div className="btn">
          <Link to="#arena">
            <img src="/assets/img/icon05_off.svg" alt="" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LeftMenu;
