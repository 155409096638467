import React, { useEffect, useRef, useState } from "react";

// import { Button } from "antd";

import { Moralis } from "moralis";
import { useParams } from "react-router-dom";
import { a, useTrail } from "react-spring";

import { toast } from "react-toastify";
import "../../assets/css/animate.css";
import "../../assets/css/dashboard.css";
import "../../assets/css/play.css";

import FightingResult from "../../components/modal/FightingResult";
import Navigation from "../../components/Navigation";
import { GAME_RESULT } from "../../constants";

import settings from "../../settings";
import { truncate } from "../../utils";

function Trail({ open, children, ...props }) {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    width: open ? 100 : 0,
    from: { opacity: 0, x: 20, width: 0 },
  });

  return (
    <div className="trails-main" {...props}>
      <div className="list-cards">
        {trail.map(({ x, height, ...rest }, index) => (
          <a.div
            key={index}
            className="trails-text"
            style={{
              ...rest,
              transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),
            }}
          >
            <a.div style={height}>{items[index]}</a.div>
          </a.div>
        ))}
      </div>
    </div>
  );
}

const Fighting = () => {
  const { code } = useParams();

  const shouldFetchData = useRef(true);
  const [open] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ourInformation, setOurInformation] = useState({});
  const [competitorInformation, setCompetitorInformation] = useState({});
  const [idCurrent, setIdCurrent] = useState(0);
  const [isAvailable, setIsAvailable] = useState(false);
  const [finalResult, setFinalResult] = useState([]);

  const timer = useRef(null);
  const gameResult = useRef([]);
  const currentId = useRef(0);
  ``;
  const fetchData = async () => {
    setIsLoading(true);

    let res = await Moralis.Cloud.run("battleRoom_get", { code });
    if (res.isOk) {
      const ourInfo =
        res.data.userBattleRooms.find(
          (e) => e.user.id === Moralis.User.current().id
        ) || {};

      const competitorInfo =
        res.data.userBattleRooms.find(
          (e) => e.user.id !== Moralis.User.current().id
        ) || {};

      res = await Moralis.Cloud.run("battleRoom_startFighting", {
        code,
      });

      if (res.isOk) {
        ourInfo["detailResult"] =
          res.data.find((x) => x.user === Moralis.User.current().id)
            .detailResult || [];

        competitorInfo["detailResult"] =
          res.data.find((x) => x.user !== Moralis.User.current().id)
            .detailResult || [];

        ourInfo["cards"].forEach((item, _idx) => {
          gameResult.current.push({
            mySelf: `${settings.CARD_IMAGE_HOST}/${item["uri"]}`,
            competitor: `${settings.CARD_IMAGE_HOST}/${competitorInfo["cards"][_idx]["uri"]}`,
            final: ourInfo["detailResult"][_idx],
            finished: false,
            finishedCompetitor: false,
          });
        });

        setFinalResult(gameResult.current);

        let timeDelay = 8000;

        timer.current = setTimeout(function tick() {
          const newFinalGame = [...gameResult.current];
          const activeItem = newFinalGame[currentId.current];

          newFinalGame[currentId.current].finished =
            activeItem.final === GAME_RESULT.WIN;
          newFinalGame[currentId.current].finishedCompetitor =
            activeItem.final === GAME_RESULT.LOSE;

          gameResult.current = newFinalGame;
          setFinalResult(newFinalGame);

          if (currentId.current >= 0) {
            timeDelay = 5000;
          }

          if (currentId.current + 1 === 5) {
            setTimeout(() => {
              showFightingResult();
            }, 500);
            clearTimeout(timer.current);
            return;
          }

          currentId.current++;
          setIdCurrent(currentId.current);

          timer.current = setTimeout(tick, timeDelay);
        }, timeDelay);
      }

      setOurInformation(ourInfo);
      setCompetitorInformation(competitorInfo);
    }

    toast(res.message, { type: "error" });

    setIsLoading(false);
    if (!isLoading) {
      setTimeout(() => {
        // Your logic here
        setIsAvailable(true);
      }, 3000);
    }
  };

  const showFightingResult = () => {
    setShowResult(true);
  };

  useEffect(() => {
    if (shouldFetchData.current) {
      shouldFetchData.current = false;
      fetchData().catch(console.error);
    }

    // return () => {
    //   if (timer.current) {
    //     console.log(111111111);
    //     clearTimeout(timer.current);
    //   }
    // };
  });
  useEffect(() => {});

  return (
    <div className="wrap-content wrap-db play-screen re-play">
      <div className="play-screen__content">
        {/*Top Navigation*/}
        <Navigation linkActive={""}></Navigation>
        {/*<Button variant="primary" onClick={showFightingResult}>*/}
        {/*  Finish Game*/}
        {/*</Button>*/}
      </div>
      <div className="game-board">
        <div className="game-board__in">
          <div className="room-info">
            <p className="RoomName">Room: {code}</p>
            {/*<p className="start-at">Start at: 15h:20’ 23-Jul-22</p>*/}
          </div>
          <div className="game-board__content">
            <div className="competitor-card">
              <Trail open={open}>
                {competitorInformation.cards &&
                  competitorInformation.cards.map((card, index) => (
                    <div
                      key={index}
                      className={`card-item ${
                        index === idCurrent ? "active" : ""
                      }`}
                    >
                      <img
                        className="img"
                        src={`${settings.CARD_IMAGE_HOST}/${card.uri}`}
                        alt=""
                      />
                    </div>
                  ))}
              </Trail>
            </div>
            <div className="room-board">
              <img
                className="img"
                src={"/assets/img/Table-play-2.png"}
                alt=""
              />
              <div className="room-board__body">
                <div
                  className={`play-begin zoom-begin ${
                    isAvailable ? "no-ready" : "ready"
                  }`}
                >
                  Begin
                </div>
                <div
                  className={`room-board-in ${
                    isAvailable ? "ready" : "no-ready"
                  }`}
                >
                  <div className="id-round">Round {idCurrent + 1}</div>
                  {!isLoading &&
                    finalResult.map((turn, index) => (
                      <div
                        className={`round-item round-${index} ${
                          index === idCurrent ? "show" : "hide"
                        } ${idCurrent === 0 ? "delay-animation" : ""}`}
                        key={index}
                      >
                        <img
                          className="earth animate__zoomOut"
                          src={
                            "/assets/img/Lets-Go-Football-GIF-by-Sports-unscreen.gif"
                          }
                          alt=""
                        />
                        <div className="area-mySelf">
                          <div className="area-in ">
                            <div
                              className={`card-item ${
                                turn.final !== GAME_RESULT.LOSE
                                  ? "win-style"
                                  : "lose-style swing"
                              }`}
                            >
                              <img
                                className={`img `}
                                src={turn.mySelf}
                                alt=""
                              />
                              <img
                                className="win"
                                src={"/assets/img/win-3.png"}
                                alt=""
                              />
                              <div className="explose">
                                <video
                                  className="video-bg d-none d-lg-block "
                                  preload="auto"
                                  loop={true}
                                  playsInline
                                  autoPlay="on"
                                  muted="on"
                                  id="heroVideo"
                                  // src="/assets/img/fire.mp4"
                                >
                                  <source
                                    src="/assets/img/fire.mp4"
                                    type="video/mp4"
                                  />
                                </video>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="area-competitor ">
                          <div className="area-in ">
                            <div
                              className={`card-item ${
                                turn.final !== GAME_RESULT.LOSE
                                  ? "lose-style swing"
                                  : "win-style"
                              }`}
                            >
                              <img
                                className={`img  img-${index}`}
                                src={turn.competitor}
                                alt=""
                              />
                              <img
                                className="win"
                                src={"/assets/img/win-3.png"}
                                alt=""
                              />
                              <div className="explose">
                                <video
                                  className="video-bg d-none d-lg-block "
                                  preload="auto"
                                  loop={true}
                                  playsInline
                                  autoPlay="on"
                                  muted="on"
                                  id="heroVideo"
                                  // src="/assets/img/fire.mp4"
                                >
                                  <source
                                    src="/assets/img/fire.mp4"
                                    type="video/mp4"
                                  />
                                </video>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="our-cards">
              <Trail open={open}>
                {ourInformation.cards &&
                  ourInformation.cards.map((card, index) => (
                    <div
                      key={index}
                      className={`card-item ${
                        index === idCurrent ? "active" : ""
                      }`}
                    >
                      <img
                        className="img"
                        src={`${settings.CARD_IMAGE_HOST}/${card.uri}`}
                        alt=""
                      />
                    </div>
                  ))}
              </Trail>
            </div>
          </div>
          <div className="room-state">
            <div className="competitor-state">
              <div className="userPlayer">
                <span>
                  {competitorInformation.user
                    ? truncate(competitorInformation.user.userName, 10)
                    : ""}
                </span>
                {/*<img*/}
                {/*  className="avatar"*/}
                {/*  src={"/assets/img/avatar-1.png"}*/}
                {/*  alt=""*/}
                {/*/>*/}
              </div>

              <div className="stars d-flex">
                {!isLoading &&
                  gameResult.current.map((turn, index) => (
                    <img
                      key={index}
                      className={`star-turn ${
                        index + 1 === idCurrent ? "currently" : ""
                      } ${turn.finishedCompetitor ? "active" : ""}`}
                      src={"/assets/img/star01.png"}
                      alt=""
                    />
                  ))}
              </div>
            </div>
            <div className="my-self-state">
              <div className="userPlayer">
                <span>
                  {ourInformation.user
                    ? truncate(ourInformation.user.userName, 10)
                    : ""}
                </span>
                {/*<img*/}
                {/*  className="avatar"*/}
                {/*  src={"/assets/img/avatar-2.png"}*/}
                {/*  alt=""*/}
                {/*/>*/}
              </div>
              <div className="stars d-flex">
                {!isLoading &&
                  gameResult.current.map((turn, index) => (
                    <img
                      key={index}
                      className={`star-turn ${
                        index === idCurrent ? "currently" : ""
                      } ${turn.finished ? "active" : ""}`}
                      src={"/assets/img/star01.png"}
                      alt=""
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showResult && (
        <FightingResult code={code} handleClose={() => setShowResult(false)} />
      )}
    </div>
  );
};

export default Fighting;
