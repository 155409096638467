import { useEffect, useRef, useState } from "react";

import { CaretDownOutlined } from "@ant-design/icons";
import { Alert, Dropdown, InputNumber, Modal } from "antd";
import { Moralis } from "moralis";
import { useMoralis } from "react-moralis";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useEffectOnce } from "react-use";

import openModal from "../components/Modal";
import { useAudio } from "../hooks/useAudio";
import { useChain } from "../hooks/useChain";
import { useConfig } from "../hooks/useConfig";
import { useUserWallet } from "../hooks/useUserWallet";
import router from "../routes";
import routes from "../routes";
import settings from "../settings";
import { normalize, sleep } from "../utils";
import Notification from "./Notification";

const TopMenu = () => {
  const isListeningRef = useRef(false);
  const { config } = useConfig();

  const { authenticate, isAuthenticated, user, logout } = useMoralis();
  const { currentChainId, showNetworkError, addChain } = useChain();
  const { userBalance, increaseBalance } = useUserWallet();
  const [nacPrice, setNacPrice] = useState(0);
  let navigate = useNavigate();
  const { toggleAudio, isSoundOn } = useAudio();

  const [isShowModalDeposit, setIsShowModalDeposit] = useState(false);
  const [isShowModalWithdraw, setIsShowModalWithdraw] = useState(false);
  const [isShowModalSignIn, setIsShowModalSignIn] = useState(false);
  const [depositAmount, setDepositAmount] = useState(0);
  const [withdrawalAmount, setWithdrawalAmount] = useState(config.withDrawFee);

  const location = useLocation();
  let pathName = location.pathname;
  let hashName = location.hash.replace("#/", "/");

  pathName = pathName || hashName;
  if (pathName === "/") pathName = "/shop";

  const _checkNetwork = async () => {
    if (currentChainId !== settings.CHAIN_ID) {
      showNetworkError();
      await addChain();
      return false;
    }
    return true;
  };

  const showModalDeposit = async () => {
    const isNetworkOk = await _checkNetwork();
    if (isNetworkOk) {
      setIsShowModalDeposit(true);
    }
  };

  const showModalWithdrawal = async () => {
    const isNetworkOk = await _checkNetwork();
    if (isNetworkOk) {
      setIsShowModalWithdraw(true);
    }
  };

  const hideModalWithdrawal = () => setIsShowModalWithdraw(false);
  const hideModalDeposit = () => setIsShowModalDeposit(false);

  const onLogin = async () => {
    const isNetworkOk = await _checkNetwork();
    if (isNetworkOk) {
      await authenticate({ signingMessage: "Connect to Nowl" });
      setIsShowModalSignIn(false);
    }
  };

  const onLogout = async () => {
    navigate && navigate("/");
    await logout();
  };

  const getDisplayAddress = () => {
    const addr = user.get("ethAddress");
    const length = addr.length;
    return addr.slice(0, 6) + "..." + addr.slice(length - 4, length);
  };

  const withdrawal = async () => {
    hideModalWithdrawal();

    if (!withdrawalAmount) {
      return openModal("error", {
        title: "Error",
        content: "withdraw amount is empty or is less than or equal 0",
      });
    }

    if (withdrawalAmount <= config.withDrawFee) {
      return openModal("error", {
        title: "Error",
        content: "You cannot withdraw more than your withdraw fee.",
      });
    }

    if (withdrawalAmount > userBalance) {
      return openModal("error", {
        title: "Error",
        content: "You cannot withdraw more than your balance.",
      });
    }

    const amount = normalize(withdrawalAmount);
    let messageModal;
    try {
      messageModal = openModal("confirm", {
        className: "message-only",
        title: "Processing...",
        content: "Please wait a little bit...",
      });

      await Moralis.Cloud.run("userWallet_asyncWithdrawal", { amount });

      messageModal.update({
        className: "message-only",
        title: "Successfully",
        content: (
          <div>
            Your transaction has been registered successfully. Please wait a
            little bit before refreshing your browser to update the balance.
          </div>
        ),
      });
    } catch (e) {
      messageModal.update({
        className: "message-only",
        title: "Error",
        content: "Unable to withdrawal your account. Please contact admin.",
      });
    }
  };

  const makeDepositTransaction = (amount) => {
    return new Promise((resolve, reject) => {
      let messageModal;
      let txHash = null;

      window.contract.methods
        .transfer(settings.MASTER_ADDRESS, Moralis.Units.Token(amount, "18"))
        .send({
          from: user.get("ethAddress"),
          // Avoid "This gas fee has been suggested by" in metamask
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        })
        .on("transactionHash", (hash) => {
          txHash = hash;
          messageModal = openModal("warning", {
            className: "message-only",
            title: "Processing",
            content: (
              <div className="text-center">
                The transaction hash:
                <div> {hash}</div>
                <h3 className="m-3">Please wait...</h3>
              </div>
            ),
          });
        })
        .on("error", (error) => {
          reject(error);
        })
        // .on('confirmation', (number, data) => console.log('confirm', data))
        .on("receipt", async (data) => {
          resolve({ messageModal, data, txHash });
        });
    });
  };

  const deposit = async () => {
    hideModalDeposit();

    try {
      const transaction = await makeDepositTransaction(depositAmount);
      const { messageModal, txHash } = transaction;

      // Wait for being sure that transaction is inserted in DB
      await sleep(1000);

      await Moralis.Cloud.run("userWallet_syncDepositTransaction", {
        transactionHash: txHash,
      });

      // Everything is OK then update balance
      increaseBalance(depositAmount);

      messageModal.update({
        title: "Success",
        content: (
          <div>
            Deposit successfully <b className="text-orange">{depositAmount}</b>{" "}
            tokens.
          </div>
        ),
      });
    } catch (e) {
      openModal("error", {
        title: "Error",
        content: "Unable to process the transaction.",
      });
    }
  };

  const fetchNACPrice = async () => {
    // Get real price on mainnet
    const resp = await Moralis.Web3API.token.getTokenPrice({
      chain: "0x38", // Mainnnet chainID
      address: "0xe198e8Fe1aaB441E54d9572E2402D7B132ccB15a", // Contract mainnet
      exchange: "PancakeSwapv2",
    });
    setNacPrice(resp.usdPrice);
  };

  // Logout if chain or account changes
  useEffect(() => {
    if (!isListeningRef.current && history && logout) {
      Moralis.onAccountChanged(onLogout);
      Moralis.onChainChanged(onLogout);

      isListeningRef.current = true;
    }
  }, [isListeningRef.current, history, logout]);

  useEffectOnce(() => {
    fetchNACPrice().catch(console.error);
  });

  return (
    <>
      <nav className="drawer-nav">
        <ul className="drawer-menu">
          <li>
            <ul className="sp-nav-list">
              <li className="sp-nav-list__item current">
                <Link to={router.SHOP} className="sp-nav-list__link">
                  Shop
                </Link>
              </li>
              <li className="sp-nav-list__item">
                <Link to={router.INVENTORY} className="sp-nav-list__link">
                  Inventory
                </Link>
              </li>
              <li className="sp-nav-list__item">
                <Link to={router.MARKETPLACE} className="sp-nav-list__link">
                  Marketplace
                </Link>
              </li>
              <li className="sp-nav-list__item">
                <Link to={router.FARM} className="sp-nav-list__link">
                  NFT Farming
                </Link>
              </li>
              <li className="sp-nav-list__item">
                <Link to={router.BATTLEFIELD} className="sp-nav-list__link">
                  battlefield
                </Link>
              </li>
              <li className="sp-nav-list__item">
                <Link to={router.SHOP} className="sp-nav-list__link">
                  Arena of valor
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <header id="header">
        <div className="h-nav">
          <div className="h-nav__inner">
            <div className="h-nav__left">
              <p className="h-logo wink">
                <Link to="/" className="h-logo__link">
                  <img src={"/assets/img/h_logo.svg"} alt="" />
                </Link>
              </p>
            </div>
            <div className="h-nav__right">
              <div className="btn01">
                <div className="img">
                  <img src={"/assets/img/icon04.svg"} alt="" />
                </div>
                <div className="text">{userBalance}</div>
                <div
                  className="btn"
                  onClick={() => setIsShowModalDeposit(true)}
                >
                  <img src={"/assets/img/icon03.svg"} alt="" />
                </div>
              </div>
              <div className="btn02">
                <div className="img">
                  <img src={"/assets/img/icon04.svg"} alt="" />
                </div>
                <div className="text">
                  <div className="first">
                    1 <span>NAC</span>
                  </div>
                  <div className="note">~</div>
                  <div className="last">
                    {normalize(nacPrice, 4)} <span>usd</span>
                  </div>
                </div>
                <div
                  className="btn"
                  onClick={() => setIsShowModalDeposit(true)}
                >
                  <img src={"/assets/img/icon03.svg"} alt="" />
                </div>
              </div>

              {!isAuthenticated && (
                <button
                  onClick={() => {
                    setIsShowModalSignIn(true);
                  }}
                  className="button open-modal"
                >
                  <img src={"/assets/img/h_btn.svg"} alt="" />
                </button>
              )}
              {/*Notification*/}
              <Notification />
              {/* End Notification*/}
              {isAuthenticated && (
                <Dropdown
                  overlay={
                    <div className="d-flex flex-column">
                      <div
                        className="custom-btn btn-yellow mt-1"
                        onClick={showModalDeposit}
                      >
                        Deposit
                      </div>
                      <div
                        className="custom-btn btn-green mt-1"
                        onClick={showModalWithdrawal}
                      >
                        Withdraw
                      </div>
                      <div
                        className="custom-btn btn-green mt-1"
                        onClick={() => {
                          navigate && navigate(routes.WITHDRAWAL_HISTORY);
                        }}
                      >
                        Withdraw History
                      </div>
                      <div
                        className="custom-btn btn-red mt-1"
                        onClick={onLogout}
                      >
                        Logout
                      </div>
                    </div>
                  }
                  trigger={["click", "hover"]}
                >
                  <div className="button" style={{ lineHeight: "30px" }}>
                    {getDisplayAddress()}
                    <CaretDownOutlined />
                  </div>
                </Dropdown>
              )}

              {/* {isAuthenticated &&
                                <button className="button open-modal">
                            <>
                                <div className="header__account-address mx-4">
                                    {getDisplayAddress()}
                                </div>

                                <button onClick={logout} className='header__account-logout-btn px-4'>
                                    Logout
                                </button>

                                <button onClick={logout} className='header__account-logout-btn px-4'>
                                    Logout
                                </button>
                            </>
                            }
                            } */}

              <div className="song">
                <div className="pause" onClick={toggleAudio}>
                  {isSoundOn ? (
                    <img src={"/assets/img/mute_on.png"} alt="" />
                  ) : (
                    <img src={"/assets/img/mute_off.png"} alt="" />
                  )}
                </div>
              </div>

              {/* <button onClick={onLogin} className="button open-modal respon">
                                <img src="/assets/img/h_btn.svg" alt="" />
                            </button> */}
            </div>
          </div>
        </div>
        <Modal
          wrapClassName="login-modal"
          visible={isShowModalSignIn}
          onCancel={() => {
            setIsShowModalSignIn(false);
          }}
          footer={false}
          closable={false}
        >
          <div className="title">
            Select a wallet
            <br />
            provider
          </div>
          <div className="box">
            <div className="aside">
              <div className="img">
                <img src={"/assets/img/popup_img01.png"} alt="" />
              </div>
              <div className="text">Metamask</div>
              <div className="btn" onClick={onLogin}>
                <img src={"/assets/img/connect.svg"} alt="" />
              </div>
            </div>
            <div className="aside">
              <div className="img">
                <img src={"/assets/img/popup_img02.png"} alt="" />
              </div>
              <div className="text">WalletConnect</div>
              <div className="btn">
                <img src={"/assets/img/connect.svg"} alt="" />
              </div>
            </div>
          </div>
          <button
            className="close-modal"
            onClick={() => setIsShowModalSignIn(false)}
          >
            <img src={"/assets/img/cancel.png"} alt="" />
          </button>
        </Modal>

        <Modal
          wrapClassName="custom-modal"
          key="modal-deposit"
          visible={isShowModalDeposit}
          onCancel={() => {
            setIsShowModalDeposit(false);
          }}
          // onOk={deposit}
          footer={null}
        >
          <h3>Deposit Token</h3>
          <div className="text-center">
            <p className="fs-3 mb-0">How many tokens you wanna deposit?</p>
            <div className="input-wrapper">
              <InputNumber
                min={0}
                defaultValue={0}
                onChange={setDepositAmount}
              />
              NAC
            </div>

            <Alert
              message="The process is asynchronous so please wait a little bit after depositing tokens."
              type="warning"
              showIcon
            />
            <div className="action">
              <div
                className="btn"
                onClick={() => {
                  setIsShowModalDeposit(false);
                }}
              >
                <img src={"/assets/img/cancel01.jpg"} alt="" />
              </div>
              <div className="btn" onClick={deposit}>
                <img src={"/assets/img/ok.png"} alt="" />
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          wrapClassName="custom-modal"
          key="modal-withdraw"
          visible={isShowModalWithdraw}
          onCancel={() => {
            setIsShowModalWithdraw(false);
          }}
          // onOk={withdrawal}
          footer={null}
        >
          <h3>Withdraw Tokens</h3>
          <div className="text-center">
            <div className="mt-3 text-white">
              <p className="fs-3 mb-0">How many tokens you wanna withdraw?</p>
              <div className="input-wrapper">
                <InputNumber
                  min={config.withDrawFee}
                  defaultValue={withdrawalAmount}
                  value={withdrawalAmount}
                  onChange={setWithdrawalAmount}
                />
                NAC
              </div>

              <div>Network Fee: {config.withDrawFee} NAC</div>
              <div>
                Receive Amount:{" "}
                {withdrawalAmount >= config.withDrawFee
                  ? withdrawalAmount - config.withDrawFee
                  : ""}{" "}
                NAC
              </div>
            </div>

            <Alert
              message="The process is asynchronous so please wait a little bit after withdrawing tokens."
              type="warning"
              showIcon
            />
            <div className="action">
              <div
                className="btn"
                onClick={() => {
                  setIsShowModalWithdraw(false);
                }}
              >
                <img src={"/assets/img/cancel01.jpg"} alt="" />
              </div>
              <div className="btn" onClick={withdrawal}>
                <img src={"/assets/img/ok.png"} alt="" />
              </div>
            </div>
          </div>
        </Modal>
      </header>
    </>
  );
};

export default TopMenu;
