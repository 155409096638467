import { useGlobalState } from "../stateContext";
import { normalize } from "../utils";

export const useUserWallet = () => {
  const [globalState, setGlobalState] = useGlobalState();
  let userWallet = globalState.userWallet;
  let userBalance = userWallet.balance || 0;

  const setUserWallet = (userWallet) => {
    setGlobalState((state) => {
      return { ...state, userWallet };
    });
  };

  const setBalance = (balance) => {
    setGlobalState((state) => {
      userWallet.balance = normalize(balance);
      return { ...state, userWallet };
    });
  };

  const decreaseBalance = (amount) => {
    setBalance(userBalance - amount);
  };

  const increaseBalance = (amount) => {
    setBalance(userBalance + amount);
  };

  return {
    userBalance,
    userWallet,
    setUserWallet,
    setBalance,
    increaseBalance,
    decreaseBalance,
  };
};
