import { useState } from "react";

import { Modal } from "antd";
import { Moralis } from "moralis";
import { useNavigate } from "react-router-dom";

import "../../assets/css/shop.css";
import shopSound from "../../assets/music/shop.mp3";
import openModal from "../../components/Modal";

import { useAudio } from "../../hooks/useAudio";
import { useConfig } from "../../hooks/useConfig";
import { useUserWallet } from "../../hooks/useUserWallet";
import routes from "../../routes";
import settings from "../../settings";

const Shop = () => {
  const navigate = useNavigate();
  const BOX_1_DESCRIPTION = `An NFT Shop (1 box) offers a variety of Magic Books so that players can get started in the game by using the book to summon the Ancient Guardians.
                            In addition, the NFT Shop also sells special equipment to help Guardians develop more strengths when fighting.`;
  const BOX_5_DESCRIPTION = `An NFT Shop (5 boxes) offers a variety of Magic Books so that players can get started in the game by using the book to summon the Ancient Guardians.
    In addition, the NFT Shop also sells special equipment to help Guardians develop more strengths when fighting.`;
  const BOX_10_DESCRIPTION = `An NFT Shop (10 boxes) offers a variety of Magic Books so that players can get started in the game by using the book to summon the Ancient Guardians.
    In addition, the NFT Shop also sells special equipment to help Guardians develop more strengths when fighting.`;

  const { config } = useConfig();
  useAudio(shopSound);

  const { decreaseBalance } = useUserWallet();
  const [newCards, setNewCards] = useState([]);
  const [isNewCardModalVisible, setIsNewCardModalModalVisible] =
    useState(false);
  const [boxDescription, setBoxDescription] = useState(BOX_1_DESCRIPTION);

  const summonBook = async (quantity) => {
    if (![1, 5, 10].includes(quantity)) {
      return alert(`Quantity is not valid. Value: ${quantity}`);
    }

    // if (!window.confirm(`Are you sure to summon ${quantity} box(es) ?`)) return;

    const modal = openModal("success", {
      className: "custom-modal message-only",
      title: "Processing..",
      content:
        "Please do not close this window until the process is complete !",
    });

    try {
      const cards = await Moralis.Cloud.run("userCard_summonBox", { quantity });

      setNewCards(cards);

      const openBoxFee = config[`open${quantity}BoxFee`];
      decreaseBalance(openBoxFee);
      modal.destroy();
      setIsNewCardModalModalVisible(true);
    } catch (e) {
      modal.update({
        title: "Error",
        content: e.message,
      });
    }
  };

  const confirmOpenBox = (quantity) => {
    if (![1, 5, 10].includes(quantity)) {
      return alert(`Quantity is not valid. Value: ${quantity}`);
    }
    const totalFree = config[`open${quantity}BoxFee`];
    openModal("confirm", {
      className: "custom-modal",
      title: `Are you sure to open ${quantity} summon books?`,
      content: `Total ${totalFree} NAC`,
      onOk: () => {
        summonBook(quantity);
      },
    });
  };

  return (
    <div className="content shop">
      <div className="wrap-content">
        <div className="wrap-info">
          <div className="list">
            <div className="items" id="items1">
              <div
                className="img"
                onClick={() => setBoxDescription(BOX_1_DESCRIPTION)}
              >
                <img src={"/assets/img/shop_img01.png"} alt="" />
              </div>
              <div className="title">Summon 1x</div>
              <div className="info-buy">
                <div className="price">
                  <span>{config.open1BoxFee}</span>
                </div>
                <div onClick={() => confirmOpenBox(1)} className="btn">
                  <img src={"/assets/img/buy.svg"} alt="" />
                </div>
              </div>

              <div className="info">
                <div className="title">Normal Summon Book1</div>
              </div>
            </div>
            <div className="items" id="items2">
              <div
                className="img"
                onClick={() => setBoxDescription(BOX_5_DESCRIPTION)}
              >
                <img src={"/assets/img/shop_img02.png"} alt="" />
              </div>
              <div className="title">Summon 5x</div>
              <div className="info-buy">
                <div className="price">
                  <span>{config.open5BoxFee}</span>
                </div>

                <div onClick={() => confirmOpenBox(5)} className="btn">
                  <img src={"/assets/img/buy.svg"} alt="" />
                </div>
              </div>

              <div className="info">
                <div className="title">Normal Summon Book2</div>
              </div>
            </div>
            <div className="items" id="items3">
              <div
                className="img"
                onClick={() => setBoxDescription(BOX_10_DESCRIPTION)}
              >
                <img src={"/assets/img/shop_img03.png"} alt="" />
              </div>
              <div className="title">
                Summon 10x<span className="note">(1x 4* card guaranteed)</span>
              </div>

              <div className="info-buy">
                <div className="price">
                  <span>{config.open10BoxFee}</span>
                </div>

                <div onClick={() => confirmOpenBox(10)} className="btn">
                  <img src={"/assets/img/buy.svg"} alt="" />
                </div>
              </div>

              <div className="info">
                <div className="title">Normal Summon Book3</div>
              </div>
            </div>
          </div>

          <div className="show-info">
            <div className="title" id="info-title">
              Summoners Gate
            </div>
            <div className="buy">
              <div className="price">
                <div className="quanlity">
                  <div className="text">Quantity:</div>
                  <div className="buttons_added shop">
                    {/* <!-- <input onclick="var result = document.getElementById('quantity'); var qty = result.value; if( !isNaN(qty) &amp; qty > 1 ) result.value&#45;&#45;;return false;" type='button' value='-' className="minus" />--> */}
                    <input type="button" value="-" className="minus" />
                    <input
                      id="quantity"
                      min="1"
                      name="quantity"
                      type="text"
                      value="15"
                      className="input-qty"
                    />
                    <input type="button" value="+" className="plus" />
                    {/* <!-- <input onclick="var result = document.getElementById('quantity'); var qty = result.value; if( !isNaN(qty)) result.value++;return false;" type='button' value='+' className="plus" />--> */}
                  </div>
                </div>
                <div className="sum" id="total-paid">
                  30000
                </div>
              </div>
              <div className="btn">
                <a href="#">
                  <img src={"/assets/img/buy.svg"} alt="" />
                </a>
              </div>
            </div>

            <div className="text-box" id="info-desc">
              {boxDescription}
            </div>
          </div>
        </div>
      </div>

      <Modal
        wrapClassName="list-modal"
        visible={isNewCardModalVisible}
        closable
        footer={false}
        onCancel={() => setIsNewCardModalModalVisible(false)}
        destroyOnClose
      >
        <>
          {newCards.map((card, index) => {
            return (
              <div key={index} className="new-card-modal">
                <div>
                  <img
                    src={`${settings.CARD_IMAGE_HOST}/${card.uri}`}
                    alt={card.nftId}
                  />
                </div>
                <span>Element : {card.class}</span> <br />
                <span>Star : {card.level}</span> <br />
                <span>collectionID : #{card.nftId}</span>
              </div>
            );
          })}
        </>
        <button
          className="custom-btn-close-modal"
          onClick={() => setIsNewCardModalModalVisible(false)}
        ></button>
        <button
          className="custom-btn-inventory-modal"
          onClick={() => navigate(routes.INVENTORY)}
        ></button>
      </Modal>
    </div>
  );
};

export default Shop;
