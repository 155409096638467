import openModal from "../components/Modal";
import settings from "../settings";

export const useChain = () => {
  const addChain = async () => {
    return await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: settings.CHAIN_ID,
          chainName: settings.CHAIN_NAME,
          rpcUrls: settings.CHAIN_RPC_URLS,
          nativeCurrency: {
            name: "tBNB",
            symbol: "tBNB",
            decimals: 18,
          },
          blockExplorerUrls: settings.CHAIN_EXPLORER_URLS,
        },
      ],
    });
  };

  const showNetworkError = () => {
    return openModal("confirm", {
      className: "message-only",
      title: "Warning",
      content: "Please switch network to BSC",
    });
  };

  return {
    currentChainId: window.web3.currentProvider.chainId,
    addChain,
    showNetworkError,
  };
};
