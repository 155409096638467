import { Moralis } from "moralis";
import Web3 from "web3";

import MarketplaceJson from "./Marketplace.json";
import NFTJson from "./NFT.json";
import settings from "./settings";
import TokenJson from "./Token.json";

class Contract {
  static async init() {
    await Moralis.enableWeb3();
    const web3 = new Web3(Web3.givenProvider);
    window.web3 = web3;

    let contract = new web3.eth.Contract(
      TokenJson.abi,
      settings.CONTRACT_ADDRESS,
      {
        // Define gas
        gasLimit: web3.utils.toHex(settings.GAS_LIMIT),
        gasPrice: web3.utils.toHex(settings.GAS_PRICE),
      }
    );
    window.contract = contract;

    let nftContract = new web3.eth.Contract(NFTJson, settings.NFT_CONTRACT, {
      // Define gas
      gasLimit: web3.utils.toHex(settings.GAS_LIMIT),
      gasPrice: web3.utils.toHex(settings.GAS_PRICE),
    });
    window.nftContract = nftContract;

    let marketplaceContract = new web3.eth.Contract(
      MarketplaceJson,
      settings.MARKETPLACE_CONTRACT,
      {
        // Define gas
        //gasLimit: web3.utils.toHex(settings.GAS_LIMIT),
        gasPrice: web3.utils.toHex(settings.GAS_PRICE),
      }
    );
    window.marketplaceContract = marketplaceContract;

    return { contract, web3, nftContract, marketplaceContract };
  }
}

export default Contract;
