import { useGlobalState } from "../stateContext";

export const useSoundState = () => {
  const [globalState, setGlobalState] = useGlobalState();

  const setSoundState = (isSoundOn) => {
    setGlobalState((state) => {
      return { ...state, isSoundOn };
    });
  };

  return {
    isSoundOn: globalState.isSoundOn,
    setSoundState,
  };
};
