import { useState } from "react";

import "../../assets/css/dashboard.css";
import "../../assets/css/myRoom.css";

import Navigation from "../../components/Navigation";
import MyRoomTable from "../../components/PVP/MyRoomTable";
import MyTournamentRoomTable from "../../components/PVP/MyTournamentRoomTable";

const MyRoom = () => {
  const [link, setLink] = useState("rooms");

  const active = (value) => {
    setLink(value);
  };

  return (
    <div className="wrap-db">
      {/*Top Navigation*/}
      <Navigation linkActive={"myRoom"}></Navigation>

      {/*Top Tab*/}
      <div className="custom-tabs">
        <div className="container">
          <div
            className={`item-tab ${link === "rooms" ? "active" : ""}`}
            onClick={() => active("rooms")}
          >
            ROOMs
          </div>
          <div
            className={`item-tab ${link === "tournament" ? "active" : ""}`}
            onClick={() => active("tournament")}
          >
            Tournament
          </div>
        </div>
      </div>

      {/*My Rooms*/}
      <div
        className={`group-tbl my-rooms-section ${
          link === "rooms" ? "show" : ""
        }`}
      >
        <MyRoomTable />
      </div>

      {/* My Events Rooms */}
      <div
        className={`group-tbl my-rooms-section my-event-rooms-section ${
          link === "tournament" ? "show" : ""
        }`}
      >
        <MyTournamentRoomTable />
      </div>
    </div>
  );
};

export default MyRoom;
