import React, { useEffect, useRef, useState } from "react";

import { CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import classNames from "classnames";
import { Moralis } from "moralis";
import Countdown from "react-countdown";

import "../../assets/css/dashboard.css";
import "../../assets/css/event-room.css";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";

import AvailableCard from "../../components/modal/AvailableCard";
import Navigation from "../../components/Navigation";

import settings from "../../settings";

const EventRoom = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const shouldFetchData = useRef(true);
  const [, setIsLoading] = useState(false);
  const [eventRoom, setEventRoom] = useState({});

  const nodeRef = React.useRef(null);
  const [cards, setCards] = useState(new Array(5));
  const [isShowModalInventory, setIsShowModalInventory] = useState(false);
  const [currentCardPosition, setCurrentCardPosition] = useState(0);
  const [remainingTime, setRemainingTime] = useState("");

  const timer = useRef(null);

  const Completionist = () => <span>0:0</span>;

  const fetchData = async () => {
    setIsLoading(true);

    const res = await Moralis.Cloud.run("eventRoom_get", { code });

    if (res.isOk) {
      setEventRoom(res.data);
      const now = new Date();
      now.setMilliseconds(0);
      const startTime = res.data.startTime;
      const timeInterval = res.data.timeInterval;
      const timeout = res.data.timeout;

      const fromStartTimeToNow = (now.getTime() - startTime.getTime()) / 1000;

      let timeleft =
        timeout * 60 -
        (fromStartTimeToNow -
          Math.floor(fromStartTimeToNow / (timeInterval * 60)) *
            timeInterval *
            60);
      showRemainingTime(timeleft);

      timer.current = setTimeout(function tick() {
        timeleft = timeleft - 1;
        if (timeleft >= 0) {
          showRemainingTime(timeleft);
          timer.current = setTimeout(tick, 1000); // (*)
        } else {
          clearTimeout(timer.current);
        }
      }, 1000);
      return;
    }

    toast(res.message, { type: "error" });

    setIsLoading(false);
  };

  const showRemainingTime = (value) => {
    const remainingMinute = Math.floor(value / 60);
    const remainingSecond = value % 60;
    const remaining = `${
      remainingMinute < 10 ? "0" + remainingMinute : remainingMinute
    } : ${remainingSecond < 10 ? "0" + remainingSecond : remainingSecond}`;
    setRemainingTime(remaining);
  };

  const onSelectCard = (card) => {
    setIsShowModalInventory(false);

    if (cards.map((e) => e.nftId).includes(card.nftId)) {
      toast("The cards can't be duplicated.", { type: "error" });
    } else {
      cards[currentCardPosition] = card;
      setCards(cards);
    }
  };

  const onReady = async () => {
    let res = await Moralis.Cloud.run("eventRoom_prepareCards", {
      code: eventRoom.code,
      cards: cards,
    });

    if (!res.isOk) {
      toast(res.message, { type: "error" });
      return;
    }

    if (res.isOk) {
      navigate && navigate(`/pvp`);
    } else {
      toast(res.message, { type: "error" });
    }
  };

  // Renderer callback with condition
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return <span>{remainingTime}</span>;
    }
  };

  useEffect(() => {
    if (shouldFetchData.current) {
      shouldFetchData.current = false;
      fetchData().catch(console.error);
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  });

  return (
    <div className="wrap-db">
      {/*Top Navigation*/}
      <Navigation linkActive={""}></Navigation>

      {/*Top Tab*/}
      <div className="event-room medium-page">
        <div className="container">
          <div className="event-room__header">
            <h1 className="big-title">Event : {eventRoom.code}</h1>
            <div className="description">
              Min: <span>{eventRoom.minPlayer}</span> users - Max:{" "}
              <span>{eventRoom.maxPlayer}</span> users - Fee:{" "}
              <span>{eventRoom.fee}</span> Nac
              <br />
              Please setup your Desk first then click Ready!
            </div>
          </div>
          <div className="event-room__body">
            <div className="event-room__info">
              <div className="time-count-down">
                time left
                <div>
                  &nbsp;
                  <Countdown date={Date.now() + 5000} renderer={renderer} />
                </div>
              </div>
              <div className="event-room__cta">
                <a className="btn" onClick={onReady}>
                  <img className="img" src="/assets/img/btn-ready.svg" alt="" />
                </a>
              </div>
            </div>
            <div className="event-your-desk">
              {Array(5)
                .fill(0)
                .map((_v, _idx) => {
                  return (
                    <div
                      className="item-desk"
                      key={_idx}
                      onClick={async () => {
                        setCurrentCardPosition(_idx);
                        setIsShowModalInventory(true);
                      }}
                    >
                      <img
                        className={classNames(
                          `img  ${cards[_idx] ? "img-card" : "img-placeholder"}`
                        )}
                        src={
                          cards[_idx]
                            ? `${settings.CARD_IMAGE_HOST}/${cards[_idx].uri}`
                            : "/assets/img/Card-Off-question.png"
                        }
                        alt=""
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      {/* Ready Players */}
      {/*<div className="group-tbl ready-player-section">*/}
      {/*  <div className="container">*/}
      {/*    <div className="group-tbl__body">*/}
      {/*      <table className="table tbl-style">*/}
      {/*        <thead>*/}
      {/*          <tr>*/}
      {/*            <th scope="col">4/20</th>*/}
      {/*            <th scope="col">UserName</th>*/}
      {/*            <th scope="col">Fee (NAC)</th>*/}
      {/*            <th scope="col">Status</th>*/}
      {/*          </tr>*/}
      {/*        </thead>*/}
      {/*        <tbody>*/}
      {/*          <tr>*/}
      {/*            <td scope="row">1</td>*/}
      {/*            <td>Hue Nguyen</td>*/}
      {/*            <td>100</td>*/}
      {/*            <td>READY</td>*/}
      {/*          </tr>*/}
      {/*          <tr className="own-status">*/}
      {/*            <td scope="row">2</td>*/}
      {/*            <td>Hue Nguyen</td>*/}
      {/*            <td>100</td>*/}
      {/*            <td>READY</td>*/}
      {/*          </tr>*/}
      {/*          <tr>*/}
      {/*            <td scope="row">3</td>*/}
      {/*            <td>Hue Nguyen</td>*/}
      {/*            <td>100</td>*/}
      {/*            <td>READY</td>*/}
      {/*          </tr>*/}
      {/*          <tr>*/}
      {/*            <td scope="row">4</td>*/}
      {/*            <td>Hue Nguyen</td>*/}
      {/*            <td>100</td>*/}
      {/*            <td>READY</td>*/}
      {/*          </tr>*/}
      {/*        </tbody>*/}
      {/*      </table>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <Modal
        centered={true}
        className="custom-modal"
        visible={isShowModalInventory}
        closable
        closeIcon={<CloseOutlined style={{ color: "white", fontSize: 30 }} />}
        footer={false}
        onCancel={() => setIsShowModalInventory(false)}
        destroyOnClose
      >
        <div className="modal-big" ref={nodeRef}>
          <AvailableCard
            limitLevel={eventRoom.cardLevel}
            itemPerPage={6}
            excludes={cards.filter((n) => n).map((e) => e.nftId)}
            onClickCard={(card) => {
              onSelectCard(card);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};
export default EventRoom;
