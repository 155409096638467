import React, { useState } from "react";

import { CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import classNames from "classnames";

import { toast } from "react-toastify";
import "../../assets/css/dashboard.css";
import "../../assets/css/event-room.css";

import settings from "../../settings";
import AvailableCard from "../modal/AvailableCard";

const PrepareCard = ({ battleRoomCode, cardLevel, onSubmit }) => {
  const nodeRef = React.useRef(null);
  const [cards, setCards] = useState(new Array(5));
  const [isShowModalInventory, setIsShowModalInventory] = useState(false);
  const [currentCardPosition, setCurrentCardPosition] = useState(0);

  const onSelectCard = (card) => {
    setIsShowModalInventory(false);

    if (cards.map((e) => e.nftId).includes(card.nftId)) {
      toast("The cards can't be duplicated.", { type: "error" });
    } else {
      cards[currentCardPosition] = card;
      setCards(cards);
    }
  };

  const handleOk = () => {
    if (cards.includes(undefined) || cards.includes(null)) {
      toast("The cards have to be set.", { type: "error" });
      return;
    }

    onSubmit(cards);
  };

  return (
    <>
      {/*Top Tab*/}
      <div className="event-room medium-page">
        <div className="container">
          <div className="event-room__header">
            <h1 className="big-title">Select Cards</h1>
            <div className="description">Please setup your Desk First</div>
            <div className="room-id">Room: {battleRoomCode}</div>
          </div>
          <div className="event-room__body">
            <div className="event-your-desk">
              {Array(5)
                .fill(0)
                .map((_v, _idx) => {
                  return (
                    <div
                      className="item-desk"
                      key={_idx}
                      onClick={async () => {
                        setCurrentCardPosition(_idx);
                        setIsShowModalInventory(true);
                      }}
                    >
                      <img
                        className={classNames(
                          `img  ${cards[_idx] ? "img-card" : "img-placeholder"}`
                        )}
                        src={
                          cards[_idx]
                            ? `${settings.CARD_IMAGE_HOST}/${cards[_idx].uri}`
                            : "/assets/img/Card-Off-question.png"
                        }
                        alt=""
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      {/* Ready Players */}
      <div className="group-tbl ready-player-section">
        <div className="container">
          <div className="form-cta">
            <button type="submit" className="btn" onClick={handleOk}>
              <img className="img" src="/assets/img/btn-play.svg" alt="" />
            </button>
          </div>
        </div>
      </div>

      <Modal
        centered={true}
        className="custom-modal"
        visible={isShowModalInventory}
        closable
        closeIcon={<CloseOutlined style={{ color: "white", fontSize: 30 }} />}
        footer={false}
        onCancel={() => setIsShowModalInventory(false)}
        destroyOnClose
      >
        <div className="modal-big" ref={nodeRef}>
          <AvailableCard
            limitLevel={cardLevel}
            itemPerPage={6}
            excludes={cards.filter((n) => n).map((e) => e.nftId)}
            onClickCard={(card) => {
              onSelectCard(card);
            }}
          />
        </div>
      </Modal>
    </>
  );
};
export default PrepareCard;
