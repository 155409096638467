import { Link } from "react-router-dom";
import "../../assets/css/marketplace.css";
import "../../assets/js/slick/carousel.css";

import routes from "../../routes";

const Auction = () => {
  return (
    <div className="content auction">
      <div className="wrap-content marketplace">
        <ul className="bread">
          <li>
            <Link to={routes.MARKETPLACE}>Listings</Link>
          </li>
          <li>Auction</li>
        </ul>
        <div className="wrap-info">
          <div className="wrap-slider">
            <div className="title">
              <img src={"../../assets/img/hotntf.svg"} alt="" />
            </div>
            <div className="swiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide" onClick="showdetail1(this);">
                  <div className="swiper-content">
                    <div className="img">
                      <img
                        src="/assets/img/marketplace_img01.png"
                        alt="gallery_img"
                      />
                    </div>
                    <div className="price">
                      <span>2000</span>
                    </div>
                    <div className="time">00:16:43</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wrap-gallery">
            <div className="gallery-info">
              <div className="box" onClick="showdetail1(this);">
                <div className="img">
                  <img
                    src="/assets/img/marketplace_img06.png"
                    alt="gallery_img"
                  />
                </div>
                <ul>
                  <li className="price">
                    <span>3000</span>
                  </li>
                  <li className="time">00:15:43</li>
                </ul>
              </div>
              <div className="box" onClick="showdetail1(this);">
                <div className="img">
                  <img
                    src="/assets/img/marketplace_img07.png"
                    alt="gallery_img"
                  />
                </div>
                <ul>
                  <li className="price">
                    <span>2000</span>
                  </li>
                  <li className="time">00:16:43</li>
                </ul>
              </div>
              <div className="box" onClick="showdetail1(this);">
                <div className="img">
                  <img
                    src="/assets/img/marketplace_img08.png"
                    alt="gallery_img"
                  />
                </div>
                <ul>
                  <li className="price">
                    <span>2000</span>
                  </li>
                  <li className="time">00:15:43</li>
                </ul>
              </div>
              <div className="box" onClick="showdetail1(this);">
                <div className="img">
                  <img
                    src="/assets/img/marketplace_img09.png"
                    alt="gallery_img"
                  />
                </div>
                <ul>
                  <li className="price">
                    <span>2000</span>
                  </li>
                  <li className="time">00:15:43</li>
                </ul>
              </div>
              <div className="box" onClick="showdetail1(this);">
                <div className="img">
                  <img
                    src="/assets/img/marketplace_img10.png"
                    alt="gallery_img"
                  />
                </div>
                <ul>
                  <li className="price">
                    <span>2000</span>
                  </li>
                  <li className="time">00:15:43</li>
                </ul>
              </div>
              <div className="box" onClick="showdetail1(this);">
                <div className="img">
                  <img
                    src="/assets/img/marketplace_img11.png"
                    alt="gallery_img"
                  />
                </div>
                <ul>
                  <li className="price">
                    <span>2000</span>
                  </li>
                  <li className="time">00:15:43</li>
                </ul>
              </div>
              <div className="box" onClick="showdetail1(this);">
                <div className="img">
                  <img
                    src="/assets/img/marketplace_img06.png"
                    alt="gallery_img"
                  />
                </div>
                <ul>
                  <li className="price">
                    <span>2000</span>
                  </li>
                  <li className="time">00:15:43</li>
                </ul>
              </div>
              <div className="box" onClick="showdetail1(this);">
                <div className="img">
                  <img
                    src="/assets/img/marketplace_img07.png"
                    alt="gallery_img"
                  />
                </div>
                <ul>
                  <li className="price">
                    <span>2000</span>
                  </li>
                  <li className="time">00:15:43</li>
                </ul>
              </div>
              <div className="box" onClick="showdetail1(this);">
                <div className="img">
                  <img
                    src="/assets/img/marketplace_img08.png"
                    alt="gallery_img"
                  />
                </div>
                <ul>
                  <li className="price">
                    <span>2000</span>
                  </li>
                  <li className="time">00:15:43</li>
                </ul>
              </div>
              <div className="box" onClick="showdetail1(this);">
                <div className="img">
                  <img
                    src="/assets/img/marketplace_img09.png"
                    alt="gallery_img"
                  />
                </div>
                <ul>
                  <li className="price">
                    <span>2000</span>
                  </li>
                  <li className="time">00:15:43</li>
                </ul>
              </div>
              <div className="box" onClick="showdetail1(this);">
                <div className="img">
                  <img
                    src="/assets/img/marketplace_img10.png"
                    alt="gallery_img"
                  />
                </div>
                <ul>
                  <li className="price">
                    <span>2000</span>
                  </li>
                  <li className="time">00:15:43</li>
                </ul>
              </div>
              <div className="box" onClick="showdetail1(this);">
                <div className="img">
                  <img
                    src="/assets/img/marketplace_img11.png"
                    alt="gallery_img"
                  />
                </div>
                <ul>
                  <li className="price">
                    <span>2000</span>
                  </li>
                  <li className="time">00:15:43</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="content-fixed">*/}
        {/*    <div className="info">*/}
        {/*        <div className="fixed-link">*/}
        {/*            <div className="first">*/}
        {/*                <div className="title">Nearest price:</div>*/}
        {/*                <div className="price">*/}
        {/*                    <span id="price">2000</span>*/}
        {/*                </div>*/}
        {/*                <div className="time" id="time">00:15:43</div>*/}
        {/*            </div>*/}
        {/*            <div className="last">*/}
        {/*                <div className="level">*/}
        {/*                    <div className="title">Star level: </div>*/}
        {/*                    <ul className="star">*/}
        {/*                        <li><span><img src="/assets/img/star.png" alt="" /></span></li>*/}
        {/*                        <li><span><img src="/assets/img/star.png" alt="" /></span></li>*/}
        {/*                        <li><span><img src="/assets/img/star.png" alt="" /></span></li>*/}
        {/*                        <li><span><img src="/assets/img/star.png" alt="" /></span></li>*/}
        {/*                        <li><span><img src="/assets/img/star.png" alt="" /></span></li>*/}
        {/*                    </ul>*/}
        {/*                </div>*/}
        {/*                <div className="detail"><a href="auction_detail.html">Details</a></div>*/}
        {/*                <div className="button"><a href="#"><img src="/assets/img/buy.svg" alt="" /></a> </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        <div className="fixed-content">*/}
        {/*            <div className="text">*/}
        {/*                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh*/}
        {/*                euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default Auction;
