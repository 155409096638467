import React, { useState } from "react";

import "../../assets/css/dashboard.css";
import "../../assets/css/event-room.css";
import { Link } from "react-router-dom";

import routes from "../../routes";
import settings from "../../settings";

const CreateRoomSuccess = ({ battleRoom }) => {
  const [copyLink] = useState(
    `${settings.DAPP_URL}/#/pvp/join?code=${battleRoom.code}${
      battleRoom.hasPassword ? "&password=" + battleRoom.password : ""
    }`
  );
  const handleCopy = () => {
    navigator.clipboard.writeText(copyLink).catch(console.error);
  };

  return (
    <div className="wrap-db">
      {/*Top Tab*/}
      <div className="event-room medium-page">
        <div className="container">
          <div className="event-room__header">
            <h1 className="big-title">Room was created successfully</h1>
            <div className="description">Share link and play</div>
            <div className="room-id">Room: {battleRoom.code}</div>
          </div>
          <div className="event-room__body">
            <div className="event-your-desk">
              {battleRoom.cards?.map((_v, _idx) => {
                return (
                  <div className="item-desk" key={_idx}>
                    <img
                      className="img"
                      src={`${settings.CARD_IMAGE_HOST}/${_v.uri}`}
                      alt=""
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Ready Players */}
      <div className="group-tbl ready-player-section">
        <div className="container">
          <div className="link-room-share">
            <span>Link Room</span>
            <div className="div-copy-link">
              <input
                type="text"
                value={copyLink}
                className="form-control"
                disabled={true}
              />
              <img
                className="icon-copy"
                src="/assets/img/icon-copy.svg"
                alt="Copy"
                onClick={handleCopy}
              />
            </div>
          </div>
          <div className="form-cta">
            <Link to={routes.PVP} className="btn-long">
              <img className="img" src="/assets/img/btn-finish.svg" alt="" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateRoomSuccess;
