import { useEffect, useRef, useState } from "react";

import { Moralis } from "moralis";

import "../../assets/css/history.css";
import { useUserWallet } from "../../hooks/useUserWallet";
import { normalize } from "../../utils";

const History = () => {
  const [histories, setHistories] = useState([]);
  const balanceRef = useRef(0);
  const { userBalance, setBalance } = useUserWallet();
  balanceRef.current = userBalance;

  const fetchHistories = async () => {
    let newHistories = await Moralis.Cloud.run(
      "userWallet_getWithdrawalHistory"
    );
    setHistories((currentHistories) => {
      newHistories = newHistories.map((h) => {
        // Decrease balance if needed
        const currentHistory = currentHistories.find(
          (history) => history.objectId === h.objectId
        );

        if (
          currentHistory &&
          currentHistory.state !== "SUCCESS" &&
          h.state === "SUCCESS"
        ) {
          setBalance(balanceRef.current - h.amount);
        }
        h.key = h.objectId;
        return h;
      });

      return newHistories;
    });
  };

  useEffect(() => {
    fetchHistories().catch(console.error);
    const _interval = setInterval(() => {
      fetchHistories().catch(console.error);
    }, 10 * 1000);
    return () => {
      clearInterval(_interval);
    };
  }, []);

  return (
    <div className="content">
      <div className="wrap-content history">
        <div className="wrap-info">
          <table className="table-box">
            <tr>
              <th>Id</th>
              <th>Created At</th>
              <th>Amount</th>
              <th>State</th>
              <th>Error</th>
            </tr>
            {histories.map((h, idx) => (
              <tr key={idx}>
                <td>{h.objectId}</td>
                <td>{new Date(h.createdAt).toLocaleString()}</td>
                <td>{`${normalize(h.amount)} NAC`}</td>
                <td>{h.state}</td>
                <td>{h.error}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
};

export default History;
