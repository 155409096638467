import { Moralis } from "moralis";

class _ContractUtils {
  get user() {
    return Moralis.User.current();
  }

  get address() {
    return this.user.get("ethAddress");
  }

  async getBalance() {
    const balanceBN = await window.contract.methods
      .balanceOf(this.address)
      .call({ from: this.address });
    return balanceBN / 1e18;
  }
}

export default new _ContractUtils();
