import { useGlobalState } from "../stateContext";

export const useConfig = () => {
  const [globalState, setGlobalState] = useGlobalState();

  const setConfig = (config) => {
    setGlobalState((state) => {
      return { ...state, config };
    });
  };

  return {
    config: globalState.config,
    setConfig,
  };
};
