import { useEffect, useState } from "react";

import { Alert, Modal } from "antd";
import "../../assets/css/createRoom.css";
import "../../assets/css/dashboard.css";

import { Moralis } from "moralis";
import { toast } from "react-toastify";

import openModal from "../../components/Modal";
import Navigation from "../../components/Navigation";
import PrepareCard from "../../components/PVP/PrepareCard";
import { BATTLE_ROOM_STATE } from "../../constants";
import { useUserWallet } from "../../hooks/useUserWallet";
import { isNullOrWhiteSpace } from "../../utils";
import CreateRoomSuccess from "./CreateSuccess";

const CreateRoom = () => {
  const roomTypes = [
    {
      displayName: "ONE STAR",
      fee: 100,
      cardLevel: 1,
    },
    {
      displayName: "TWO STAR",
      fee: 200,
      cardLevel: 2,
    },
    {
      displayName: "THREE STAR",
      fee: 300,
      cardLevel: 3,
    },
    {
      displayName: "FOUR STAR",
      fee: 400,
      cardLevel: 4,
    },
    {
      displayName: "FIVE STAR",
      fee: 500,
      cardLevel: 5,
    },
  ];

  const { decreaseBalance } = useUserWallet();

  const [selectedCardLevel, setSelectedCardLevel] = useState(1);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fee, setFee] = useState(100);
  const [defaultFee, setDefaultFee] = useState(100);
  const [hasPassword, setHasPassword] = useState(false);
  const [isShowModalChargeFee, setShowModalChargeFee] = useState(false);
  const [isShowPrepareCard, setShowPrepareCard] = useState(false);
  const [battleRoom, setBattleRoom] = useState({});
  const [battleRoomState, setBattleRoomState] = useState(
    BATTLE_ROOM_STATE.PROCESSING
  );

  const onChangeRoomType = (event) => {
    setSelectedCardLevel(parseInt(event.target.value));
  };

  const onChangeFee = (event) => {
    setFee(event.target.valueAsNumber);
    setDefaultFee(event.target.valueAsNumber);
  };

  const onChangePassword = (event) => {
    setPassword(event.target.value.trim());
  };

  const onChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value.trim());
  };

  const onChangeHasPassword = () => {
    setHasPassword(!hasPassword);
  };

  const onCreateBattleRoom = async () => {
    let res = await Moralis.Cloud.run("userCard_validateAvailableCard", {
      level: selectedCardLevel,
    });

    if (!res.isOk) {
      toast(res.message, { type: "error" });
      return;
    }

    if (hasPassword) {
      if (isNullOrWhiteSpace(password)) {
        toast("{password} is required.", { type: "error" });
        return;
      }

      if (isNullOrWhiteSpace(confirmPassword)) {
        toast("{confirmPassword} is required.", { type: "error" });
        return;
      }

      if (password !== confirmPassword) {
        toast("{password} and {confirmPassword} must be matched.", {
          type: "error",
        });
        return;
      }
    }

    setShowModalChargeFee(true);
  };

  const createBattleRoom = async () => {
    const res = await Moralis.Cloud.run("battleRoom_create", {
      cardLevel: selectedCardLevel,
      fee: fee,
      password: password,
      hasPassword: hasPassword,
    });

    if (res.isOk) {
      setBattleRoom(res.data);
      setShowPrepareCard(true);
      return true;
    }

    toast(res.message, { type: "error" });
    return false;
  };

  const onChargedFee = async () => {
    setShowModalChargeFee(false);

    const modal = openModal("success", {
      className: "custom-modal message-only",
      title: "Processing..",
      content:
        "Please do not close this window until the process is complete !",
    });

    try {
      const rs = await createBattleRoom();
      if (rs) {
        decreaseBalance(fee);
        modal.destroy();
      }
      // else {
      //   modal.update({
      //     title: "Error",
      //     content: res.message,
      //   });
      // }
    } catch (e) {
      modal.update({
        title: "Error",
        content: e.message,
      });
    }
  };

  const prepareCards = async (cards) => {
    const res = await Moralis.Cloud.run("battleRoom_prepareCards", {
      code: battleRoom.code,
      cards: cards,
    });

    if (!res.isOk) {
      toast(res.message, { type: "error" });
      return;
    }

    if (res.isOk) {
      setBattleRoomState(BATTLE_ROOM_STATE.WAITING);
      setBattleRoom({
        ...battleRoom,
        password: password,
        hasPassword: hasPassword,
        cards: cards,
      });
    }
  };

  useEffect(() => {
    setFee(roomTypes.find((e) => e.cardLevel === selectedCardLevel).fee);
    setDefaultFee(roomTypes.find((e) => e.cardLevel === selectedCardLevel).fee);
  }, [selectedCardLevel]);

  return (
    <div className="wrap-db">
      {/*Top Navigation*/}
      <Navigation linkActive={""}></Navigation>
      {battleRoomState === BATTLE_ROOM_STATE.WAITING && (
        <CreateRoomSuccess battleRoom={battleRoom} />
      )}
      {battleRoomState !== BATTLE_ROOM_STATE.WAITING && isShowPrepareCard && (
        <PrepareCard
          battleRoomCode={battleRoom.code}
          onSubmit={prepareCards}
          cardLevel={battleRoom.cardLevel}
        />
      )}
      {battleRoomState !== BATTLE_ROOM_STATE.WAITING && !isShowPrepareCard && (
        <>
          {/*Top Tab*/}
          <div className="create-room medium-page">
            <div className="container">
              <div className="create-room__header">
                <h1 className="big-title">ROOM SETUP</h1>
              </div>
              <div className="create-room___body">
                <div className="form-group">
                  <label className="lbl-input">Room Type *</label>
                  <select className="form-control" onChange={onChangeRoomType}>
                    {roomTypes.map((e, i) => (
                      <option key={i} value={e.cardLevel}>
                        {e.displayName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label className="lbl-input">Fee (Nac) *</label>
                  <input
                    type="number"
                    className="form-control"
                    value={defaultFee}
                    onChange={onChangeFee}
                  />
                </div>
                {hasPassword && (
                  <div className="password-group active">
                    <div className="form-group">
                      <label className="lbl-input">Password </label>
                      <input
                        type="password"
                        className="form-control"
                        defaultValue={""}
                        onChange={onChangePassword}
                      />
                    </div>
                    <div className="form-group">
                      <label className="lbl-input">Re-Password</label>
                      <input
                        type="password"
                        className="form-control"
                        defaultValue={""}
                        onChange={onChangeConfirmPassword}
                      />
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <div className="checkboxDiv">
                    <span>Do you want set password ?</span>
                    <label className="switch">
                      <input type="checkbox" onClick={onChangeHasPassword} />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                <div className="form-cta">
                  <button
                    type="submit"
                    className="btn"
                    onClick={onCreateBattleRoom}
                  >
                    <img
                      className="img"
                      src="/assets/img/btn-continue.svg"
                      alt=""
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div>
            <Modal
              wrapClassName="custom-modal"
              key="modal-deposit"
              visible={isShowModalChargeFee}
              footer={null}
              onCancel={() => {
                setShowModalChargeFee(false);
              }}
            >
              <h3>Join Battle Room Fee</h3>
              <div className="text-center">
                <p className="fs-3 mb-0">
                  You will be charged <strong>{fee} NAC</strong> to join room
                </p>
                <Alert
                  message="The process is asynchronous so please wait a little bit after depositing tokens."
                  type="warning"
                  showIcon
                />
                <div className="action">
                  <div
                    className="btn"
                    onClick={() => {
                      setShowModalChargeFee(false);
                    }}
                  >
                    <img src={"../assets/img/cancel01.jpg"} alt="" />
                  </div>
                  <div className="btn" onClick={onChargedFee}>
                    <img src={"../assets/img/ok.png"} alt="" />
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </>
      )}
    </div>
  );
};
export default CreateRoom;
