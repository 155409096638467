const routes = {
  HOME: "/",
  SHOP: "/shop",
  INVENTORY: "/inventory",
  INVENTORY_DETAIL: "/inventory/:id",
  FARM: "/farm",
  PVE: "/pve",
  BATTLEFIELD: "/battlefield",
  MARKETPLACE: "/marketplace",
  AUCTION: "/auction",
  AUCTION_DETAIL: "/auction/detail",
  COMBINE: "/combine",
  BET: "/bet",
  WITHDRAWAL_HISTORY: "/withdrawal-history",
  PVP: "/pvp",
  JOIN_PVP: "/pvp/join",
  FIGHTING: "/pvp/fighting/:code",
  MY_ROOMS: "/my-rooms",
  RANK: "/rank",
  EVENT_ROOM: "/event-room/:code",
  CREATE_EVENT: "/create-event",
  CREATE_ROOM: "/create-room",
  SORT_CARDS: "/sort-cards",
  CREATED_ROOM: "/created-room",
};

export default routes;
