import React, { useEffect, useRef, useState } from "react";

import { Modal, Alert } from "antd";

import { Moralis } from "moralis";
import { Chart } from "react-google-charts";
import { useMoralisSubscription } from "react-moralis";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { BATTLE_ROOM_STATE } from "../../constants";
import { useUserWallet } from "../../hooks/useUserWallet";
import settings from "../../settings";
import { truncate } from "../../utils";
import openModal from "../Modal";
import FightingResult from "../modal/FightingResult";
import PrepareCard from "./PrepareCard";

const MyRoomTable = () => {
  const shouldFetchData = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [battleRooms, setBattleRooms] = useState([]);
  const [selectedBattleRoom, setSelectedBattleRoom] = useState({});
  const [isShowPrepareCard, setShowPrepareCard] = useState(false);
  const [isShowModalChargeFee, setShowModalChargeFee] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [statisticData, setStatisticData] = useState({
    matches: [
      ["Win", "Speakers (in millions)"],
      ["Win", 99],
      ["Lose", 45],
    ],
    profit: [
      ["Profit", "Speakers (in millions)"],
      ["Interest", 80],
      ["Lose", 20],
    ],
  });

  const { decreaseBalance } = useUserWallet();

  const options = {
    pieSliceText: "label",
    title: "WIN/LOSE",
    pieStartAngle: 100,
  };
  const optionsProfit = {
    pieSliceText: "label",
    title: "INTEREST/LOST",
    pieStartAngle: 100,
  };

  useMoralisSubscription(
    "UserBattleRoom",
    (q) => q.equalTo("user", Moralis.User.current()),
    [],
    {
      onCreate: (data) => {
        fetchData().then(console.error);
      },
      onUpdate: (data) => {
        fetchData().then(console.error);
        fetchStatisticData().then(console.error);
      },
    }
  );

  const onCopyLink = (link) => {
    navigator.clipboard.writeText(link).catch(console.error);
    toast("Copy to clipboard!", { type: "success" });
  };

  const onChargedFee = async () => {
    setShowModalChargeFee(false);

    const modal = openModal("success", {
      className: "custom-modal message-only",
      title: "Processing..",
      content: "Please do not close this window until the process is complete!",
    });

    try {
      const rs = await Moralis.Cloud.run("battleRoom_replay", {
        code: selectedBattleRoom.code,
      });
      if (rs.isOk) {
        setShowPrepareCard(true);
        setSelectedBattleRoom(rs.data);
        decreaseBalance(rs.data.fee);
        modal.destroy();
      }
    } catch (e) {
      modal.update({
        title: "Error",
        content: e.message,
      });
    }
  };

  const prepareCards = async (cards) => {
    let res = await Moralis.Cloud.run("battleRoom_prepareCards", {
      code: selectedBattleRoom.code,
      cards: cards,
    });

    if (!res.isOk) {
      toast(res.message, { type: "error" });
      return;
    }

    if (res.isOk) {
      setShowPrepareCard(false);
    }

    toast(res.message, { type: "error" });
  };

  const fetchData = async () => {
    setIsLoading(true);

    const filterOptions = {
      page: currentPage,
      itemPerPage: settings.ITEM_PER_PAGE,
      searchTerm: searchTerm,
    };

    const res = await Moralis.Cloud.run("battleRoom_getMyRooms", filterOptions);
    if (res.isOk) {
      const _totalPage = Math.ceil(res.count / filterOptions.itemPerPage);
      setTotalPage(_totalPage);
      setBattleRooms(res.data);
    }

    toast(res.message, { type: "error" });

    setIsLoading(false);
  };

  const fetchStatisticData = async () => {
    const res = await Moralis.Cloud.run("battleRoom_getStatistic");
    if (res.isOk) {
      setStatisticData({
        matches: [
          ["Win", "Speakers (in millions)"],
          ["Win", res.data.matches.win],
          ["Lose", res.data.matches.lose],
          ["Draw", res.data.matches.draw],
        ],
        profit: [
          ["Profit", "Speakers (in millions)"],
          ["Reward", res.data.profit.reward],
          ["Lost", res.data.profit.lost],
        ],
      });
    }
  };

  const onReplay = async (roomCode) => {
    const res = await Moralis.Cloud.run("battleRoom_get", { code: roomCode });
    if (res.isOk) {
      setSelectedBattleRoom(res.data.battleRoom);
      setShowModalChargeFee(true);
    }
  };

  const showFightingResult = async (roomCode) => {
    setSelectedBattleRoom({ code: roomCode });
    setShowResult(true);
  };

  useEffect(() => {
    if (shouldFetchData.current) {
      shouldFetchData.current = false;
      fetchData().catch(console.error);
      fetchStatisticData().catch(console.error);
    }
  }, [currentPage, searchTerm]);

  // Trick: prevent show warning: Not unsubscribe when unmount
  useEffect(() => {});

  return (
    <div className="container">
      {!isShowPrepareCard && (
        <>
          {/*My Chart*/}
          <div className="my-charts">
            <div className="container">
              <div className="chart-item">
                <Chart
                  chartType="PieChart"
                  data={statisticData.matches}
                  options={options}
                  width={"100%"}
                  height={"260px"}
                />
              </div>
              <div className="chart-item">
                <Chart
                  chartType="PieChart"
                  data={statisticData.profit}
                  options={optionsProfit}
                  width={"100%"}
                  height={"260px"}
                />
              </div>
            </div>
          </div>
          <div className="group-tbl__header d-flex justify-content-between mt-3r">
            {/*<div className="form-group select-form">*/}
            {/*  <select className="form-control">*/}
            {/*    <option>All</option>*/}
            {/*    <option>Waiting</option>*/}
            {/*    <option>Finish</option>*/}
            {/*  </select>*/}
            {/*</div>*/}
            <div className="group-left">
              <div className="gray-lbl">
                <span>My Rooms</span>
              </div>
              <div className="form-group group-search">
                <input
                  defaultValue={""}
                  type="text"
                  className="form-control"
                  placeholder="ID Room"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      shouldFetchData.current = true;
                      setSearchTerm(event.target.value.trim());
                    }
                  }}
                  onChange={() => {}}
                />
                <img
                  className="icon-right icon-search"
                  src="/assets/img/icon-search.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="group-tbl__body">
            <table className="table tbl-style">
              <thead>
                <tr>
                  <th scope="col">Room ID</th>
                  <th scope="col">Stars</th>
                  <th scope="col">Status</th>
                  <th scope="col" style={{ width: "200px" }}>
                    Play with
                  </th>
                  <th scope="col">Win/lose</th>
                  <th scope="col">ShareLink</th>
                  <th scope="col">Replay</th>
                  <th scope="col">Result</th>
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan={8}>
                      <span>Loading...</span>
                    </td>
                  </tr>
                )}

                {!isLoading && battleRooms.length < 1 && (
                  <tr>
                    <td colSpan={8}>
                      <span>No rooms available</span>
                    </td>
                  </tr>
                )}

                {!isLoading &&
                  battleRooms.length > 0 &&
                  battleRooms.map((e, _id) => (
                    <tr key={_id} className="waiting-stt">
                      <td scope="row">{e.code}</td>

                      <td>{e.cardLevel}</td>
                      <td>{e.state}</td>
                      <td>{truncate(e.competitor, 10)}</td>
                      <td>
                        {e.reward ? `+${e.reward}` : e.lost ? `-${e.lost}` : ""}
                      </td>
                      {/*<td className="\">Link</td>*/}
                      <td className="">
                        <img
                          className="icon-copy"
                          src="/assets/img/icon-copy.svg"
                          alt="Copy"
                          onClick={() =>
                            onCopyLink(`${settings.DAPP_URL}/#/${e.uri}`)
                          }
                        />
                      </td>

                      <td>
                        {e.state === BATTLE_ROOM_STATE.FINISHED && (
                          <Link
                            to=""
                            onClick={(event) => {
                              event.preventDefault();
                              onReplay(e.code).catch(console.error);
                            }}
                          >
                            <img
                              className="img"
                              src="/assets/img/icon-play.svg"
                              alt=""
                            />
                          </Link>
                        )}
                      </td>
                      <td>
                        {e.state === BATTLE_ROOM_STATE.FINISHED && (
                          <Link
                            to=""
                            onClick={(event) => {
                              event.preventDefault();
                              showFightingResult(e.code).catch(console.error);
                            }}
                          >
                            ...
                          </Link>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {totalPage > 1 && (
              <div className="pagination">
                <div className="pagination__in">
                  <Link
                    to="#"
                    className="btn-pagination"
                    disabled={currentPage === 1}
                    onClick={(event) => {
                      event.preventDefault();
                      if (currentPage > 1) {
                        shouldFetchData.current = true;
                        setCurrentPage(currentPage - 1);
                      }
                    }}
                  >
                    <img className="img" src="/assets/img/prev.svg" alt="" />
                  </Link>
                  <span className="pagination-id">
                    {currentPage}/{totalPage}
                  </span>
                  <Link
                    to="#"
                    className="btn-pagination"
                    disabled={currentPage === totalPage}
                    onClick={(event) => {
                      event.preventDefault();
                      if (currentPage < totalPage) {
                        shouldFetchData.current = true;
                        setCurrentPage(currentPage + 1);
                      }
                    }}
                  >
                    <img className="img" src="/assets/img/next.svg" alt="" />
                  </Link>
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {isShowPrepareCard && (
        <PrepareCard
          battleRoomCode={selectedBattleRoom.code}
          cardLevel={selectedBattleRoom.cardLevel}
          onSubmit={prepareCards}
        />
      )}

      {showResult && (
        <FightingResult
          code={selectedBattleRoom.code}
          handleClose={() => setShowResult(false)}
        />
      )}

      <Modal
        wrapClassName="custom-modal"
        key="modal-deposit"
        visible={isShowModalChargeFee}
        footer={null}
        onCancel={() => {
          setShowModalChargeFee(false);
        }}
      >
        <h3>Join Battle Room Fee</h3>
        <div className="text-center">
          <p className="fs-3 mb-0">
            You will be charged <strong>{selectedBattleRoom.fee} NAC</strong> to
            join room
          </p>
          <Alert
            message="The process is asynchronous so please wait a little bit."
            type="warning"
            showIcon
          />
          <div className="action">
            <div
              className="btn"
              onClick={() => {
                setShowModalChargeFee(false);
              }}
            >
              <img src={"../assets/img/cancel01.jpg"} alt="" />
            </div>
            <div className="btn" onClick={onChargedFee}>
              <img src={"../assets/img/ok.png"} alt="" />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MyRoomTable;
