import { useRef, useState } from "react";

import { Modal } from "antd";
import { Moralis } from "moralis";
import Slider from "react-slick";
import { useEffectOnce, useUnmount } from "react-use";
import "../../assets/css/farming.css";
import "../../assets/js/slick/carousel01.css";

import farmSound from "../../assets/music/farming.wav";
import ListCard from "../../components/ListCard";
import openModal from "../../components/Modal";
import Stars from "../../components/Stars";
import { useAudio } from "../../hooks/useAudio";
import { useUserWallet } from "../../hooks/useUserWallet";

const Farming = () => {
  const { increaseBalance } = useUserWallet();
  useAudio(farmSound);

  const intervalLoadSlotInfo = useRef();

  const sliderSettings = {
    infinite: true,
    speed: 200,
    slidesToShow: 5,
    // autoplay: true,
    slidesToScroll: 1,
  };

  const [isShowModalInventory, setIsShowModalInventory] = useState(false);
  const [listSlot, setListSlot] = useState([]);
  const [slotSelected, setSlotSelected] = useState();
  const [slotDescription, setSlotDescription] = useState();

  const fetchFarmSlots = async () => {
    const slots = await Moralis.Cloud.run("farm_getSlots");
    setListSlot(slots);
  };

  const startFarming = async (card) => {
    try {
      await Moralis.Cloud.run("farm_startFarming", {
        nftId: card.nftId,
      });

      setIsShowModalInventory(false);
      await fetchFarmSlots();
    } catch (e) {
      console.log(e);
    }
  };

  const claimReward = async (slot) => {
    const modal = openModal("success", {
      className: "message-only",
      title: "Processing..",
      content:
        "Please do not close this window until the process is complete !",
    });
    try {
      const response = await Moralis.Cloud.run("farm_claimReward", {
        nftId: slot.nftId,
        slotLevel: slot.slotLevel,
      });

      const totalRewards = response.totalRewards;
      modal.update({
        title: "Claim Reward Success",
        content: `you have received ${totalRewards} NAC`,
      });

      increaseBalance(totalRewards);
      // reset slot
      await fetchFarmSlots();
    } catch (e) {
      modal.update({
        title: "Error",
        content: e.message,
      });
    }
  };

  const openModalInventory = async (slot) => {
    setIsShowModalInventory(true);
    setSlotSelected(slot);
  };

  useEffectOnce(() => {
    fetchFarmSlots().catch(console.error);

    intervalLoadSlotInfo.current = setInterval(() => {
      fetchFarmSlots().catch(console.error);
    }, 60 * 1000);
  });

  useUnmount(() => {
    if (intervalLoadSlotInfo.current) {
      clearInterval(intervalLoadSlotInfo.current);
    }
  });

  return (
    <div className="content farming">
      <div className="wrap-content farming">
        <ul className="bread">
          <li>Single Farming</li>
          <li>
            <span>Collection Farming</span>
          </li>
        </ul>
        <div className="wrap-info farming">
          <div className="wrap-slider">
            <div className="swiper farming">
              <Slider {...sliderSettings} className="swiper-wrapper">
                {listSlot.map((slot, idx) => {
                  return (
                    <div
                      key={`slot-${idx}`}
                      className="swiper-slide"
                      onClick={() => setSlotDescription(slot)}
                    >
                      <div className="swiper-content">
                        <div className="img">
                          <img
                            src={`../../assets/img/farming_img0${idx + 1}.png`}
                            alt="gallery_img"
                          />
                        </div>
                        <div className="title">revolution</div>
                        <dl className="deposit">
                          <dt>Deposit</dt>
                          <dd>
                            <div className="star depo">
                              <Stars
                                num={slot.slotLevel}
                                starImg={"../../assets/img/star01.png"}
                              />
                            </div>
                          </dd>
                        </dl>

                        <dl className="earn">
                          <dt>Earn</dt>
                          <dd className="earn-info">
                            {slot?.totalRewards?.toFixed(2)} NAC
                          </dd>
                        </dl>
                        <dl className="apy">
                          <dt>APY</dt>
                          <dd className="apy-info">
                            {slot?.slotApy?.toFixed(2)}%
                          </dd>
                        </dl>
                        {/* <dl className="total">
                                                    <dt>Total Liquidity</dt>
                                                    <dd>$15.961.496</dd>
                                                </dl> */}
                        {slot.state === "farming" ? (
                          <button
                            className="btn open-modal1"
                            onClick={() => claimReward(slot)}
                          >
                            Claim Reward
                          </button>
                        ) : (
                          <button
                            className="btn open-modal1"
                            onClick={() => openModalInventory(slot)}
                          >
                            Start Farming
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>

          <div className="content-fixed">
            {slotDescription && (
              <div className="info">
                <div className="fixed-link">
                  <div className="first">
                    <div className="title">
                      APY:{" "}
                      <span id="apy">
                        {slotDescription?.slotApy?.toFixed(2)}%
                      </span>
                    </div>
                    {/* <div className="time" id="time">
                                        00:15:43
                                    </div> */}
                  </div>
                  <div className="last">
                    <div className="deposit">
                      <div className="title">Deposit:</div>
                      <div className="star depo">
                        <Stars
                          num={slotDescription.slotLevel}
                          starImg={"../../assets/img/star01.png"}
                        />
                      </div>
                      {/* <ul className="star" id="deposit">
                                            <li><span><img src="/assets/img/star.png" alt=""/></span></li>
                                            <li><span><img src="/assets/img/star.png" alt=""/></span></li>
                                            <li><span><img src="/assets/img/star.png" alt=""/></span></li>
                                        </ul> */}
                    </div>
                    <div className="earn">
                      Earn:{" "}
                      <span id="earn">
                        {" "}
                        {slotDescription?.totalRewards?.toFixed(2)} NAC
                      </span>
                    </div>
                    <div
                      className="button"
                      onClick={() =>
                        openModalInventory(slotDescription.slotLevel)
                      }
                    >
                      <img src="/assets/img/farm.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="fixed-content">
                  <div className="text">
                    Guardians after Summoned can be sent to the Guardians Camp
                    to earn rewards. The Guardian will produce NAC tokens per
                    block. Users need to choose the pool corresponding to the
                    type of Guardians they own to stake.
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        className="custom-modal farm"
        visible={isShowModalInventory}
        closable
        footer={false}
        onCancel={() => setIsShowModalInventory(false)}
        destroyOnClose
      >
        {slotSelected && (
          <ListCard
            itemPerPage={3}
            level={slotSelected.slotLevel}
            onClickCard={(card) => {
              startFarming(card);
            }}
          />
        )}
      </Modal>
    </div>
  );
};

export default Farming;
