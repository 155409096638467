import "bootstrap/dist/css/bootstrap.min.css";

import { useEffect, useRef, useState } from "react";

import { Moralis } from "moralis";

import { useMoralis } from "react-moralis";
import { useNavigate, Route, Routes } from "react-router-dom";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import LeftMenu from "./components/LeftMenu";
import RightMenu from "./components/RightMenu";
import TopMenu from "./components/TopMenu";
import Contract from "./contract";
import ContractUtils from "./ContractUtils";
import { useConfig } from "./hooks/useConfig";
import { useUserWallet } from "./hooks/useUserWallet";
import Auction from "./pages/Auction";
import AuctionDetail from "./pages/Auction/Detail";
import CreateRoom from "./pages/CreateRoom/CreateRoom";
import CreateRoomSuccess from "./pages/CreateRoom/CreateSuccess";
import CreateEvent from "./pages/Events/CreateEvent";
import EventRoom from "./pages/Events/EventRoom";
import Farming from "./pages/Farming";
import Fighting from "./pages/Fighting";
import WithdrawalHistory from "./pages/History";
import Inventory from "./pages/Inventory";
import InventoryDetail from "./pages/Inventory/Detail";
import MarketPlace from "./pages/MarketPlace";
import MyRoom from "./pages/MyRoom";
import PVE from "./pages/PVE";
import PVP from "./pages/PVP";
import Rank from "./pages/Rank";
import Shop from "./pages/Shop";
import routes from "./routes";

function App() {
  /**
   * Needed steps:
   * 1. Get current user
   */

  const navigate = useNavigate();
  const { isAuthenticated, isInitialized, logout } = useMoralis();
  const { userBalance, setUserWallet } = useUserWallet();
  const { setConfig } = useConfig();

  const balanceRef = useRef(userBalance);
  const [isAppReady, setIsAppReady] = useState(true);
  const [isContractReady, setIsContractReady] = useState(true);

  const initContract = async () => {
    await Contract.init();
    setIsContractReady(true);
  };

  const logoutAndGoHome = async () => {
    navigate && navigate("/");
    await logout();
  };

  const initGlobalState = async () => {
    console.debug(">>> Init global state");

    // If session is still valid then init the data
    try {
      const res = await Moralis.Cloud.run("user_getData");
      const config = await Moralis.Cloud.run("user_getConfigs");

      setConfig(config);
      setUserWallet(res.userWallet);
    } catch (e) {
      console.error(e);
    }
  };

  const initApp = async () => {
    console.debug(">>> Init app");

    // If user detected then check if session is still valid
    if (ContractUtils.user) {
      try {
        await Moralis.Cloud.run("user_checkSession");
      } catch (e) {
        console.warn(">>> Session is expired:", e);
        logoutAndGoHome().catch(console.error);
      }
    }

    setIsAppReady(true);
  };

  // Update ref when balance change
  useEffect(() => {
    balanceRef.current = userBalance;
  }, [userBalance]);

  // Init app only then Moralis is ready
  useEffect(() => {
    if (isInitialized) {
      initApp().catch(console.error);
    }
  }, [isInitialized]);

  // Init global state only when app ready and authenticated
  useEffect(() => {
    if (isAppReady && isAuthenticated) {
      initContract().catch(console.error);
      initGlobalState().catch(console.error);
    }
  }, [isAppReady, isAuthenticated]);

  // isReady when Contract object is init
  // isInitialized when Moralis object is init
  if (!isContractReady) {
    return (
      <h1 className="w-100 text-center text-orange mt-4">
        Loading smart contract...
      </h1>
    );
  }

  if (!isInitialized) {
    return (
      <h1 className="w-100 text-center text-orange mt-4">Loading Core...</h1>
    );
  }
  if (!isAppReady) {
    return (
      <h1 className="w-100 text-center text-orange mt-4">
        Loading Interface...
      </h1>
    );
  }

  return (
    <div id="wrap">
      <button type="button" className="drawer-toggle drawer-hamburger">
        <span className="sr-only" />
        <span className="drawer-hamburger-icon" />
      </button>

      <TopMenu />

      <div className="main" id="#main">
        {/*<div className="content">*/}
        <Routes>
          {!isAuthenticated && (
            <Route
              component={() => {
                return <div>Please login to continue</div>;
              }}
            />
          )}
          {isAuthenticated && (
            <>
              <Route exact path={routes.HOME} element={<Shop />} />
              <Route exact path={routes.SHOP} element={<Shop />} />
              <Route exact path={routes.FIGHTING} element={<Fighting />} />
              <Route exact path={routes.PVP} element={<PVP />} />
              <Route exact path={routes.JOIN_PVP} element={<PVP />} />
              <Route exact path={routes.MY_ROOMS} element={<MyRoom />} />
              <Route exact path={routes.RANK} element={<Rank />} />
              <Route exact path={routes.EVENT_ROOM} element={<EventRoom />} />
              <Route exact path={routes.CREATE_ROOM} element={<CreateRoom />} />
              <Route
                exact
                path={routes.CREATE_EVENT}
                element={<CreateEvent />}
              />
              <Route
                exact
                path={routes.CREATED_ROOM}
                element={<CreateRoomSuccess />}
              />

              <Route exact path={routes.INVENTORY} element={<Inventory />} />
              <Route
                exact
                path={routes.MARKETPLACE}
                element={<MarketPlace />}
              />
              <Route exact path={routes.AUCTION} element={<Auction />} />
              <Route exact path={routes.FARM} element={<Farming />} />
              <Route exact path={routes.PVE} element={<PVE />} />
              <Route
                exact
                path={routes.INVENTORY_DETAIL}
                element={<InventoryDetail />}
              />
              <Route
                exact
                path={routes.AUCTION_DETAIL}
                element={<AuctionDetail />}
              />
              <Route
                exact
                path={routes.WITHDRAWAL_HISTORY}
                element={<WithdrawalHistory />}
              />
              <Route
                exact
                path={routes.CREATED_ROOM}
                element={<CreateRoomSuccess />}
              />
            </>
          )}
        </Routes>
        {/*</div>*/}
        <LeftMenu />
        <RightMenu />
      </div>
      <ToastContainer
        // hideProgressBar={true}
        pauseOnHover={true}
        theme={"colored"}
        autoClose={3000}
      />
    </div>
  );
}

export default App;
