import { useEffect, useState } from "react";

import { Moralis } from "moralis";

import settings from "../settings";

const useUserNFTs = (autoFetch) => {
  const [userNFTs, setUserNFTs] = useState([]);
  const [total, setTotal] = useState(0);

  const getUserNFTsData = async (
    _class = "",
    level = 0,
    page = 1,
    orderBy = "level",
    asc = 1,
    itemsPerPage = settings.ITEM_PER_PAGE
  ) => {
    let offset = (page - 1) * itemsPerPage;

    const userNFTsData = await Moralis.Cloud.run("userCard_getMines", {
      class: _class,
      level,
      offset,
      limit: itemsPerPage,
      orderBy,
      asc,
    });

    return userNFTsData;
  };

  const getNFTTransferHistories = async (nftId, page = 1) => {
    let offset = (page - 1) * settings.ITEM_PER_PAGE;
    const userNFTsData = await Moralis.Web3API.account.getNFTTransfers({
      chain: settings.CHAIN_ID,
      token_address: settings.NFT_CONTRACT,
      token_id: nftId,
      offset,
      limit: settings.ITEM_PER_PAGE,
    });

    return userNFTsData;
  };

  const _fetchUserNFTs = async () => {
    const userNFTsData = await getUserNFTsData();

    setUserNFTs(userNFTsData.result);
    setTotal(userNFTsData.total);
  };

  useEffect(() => {
    if (autoFetch) _fetchUserNFTs().catch(console.error);
  }, []);

  return {
    total,
    userNFTs,
    setUserNFTs,
    getUserNFTsData,
    getNFTTransferHistories,
  };
};

export default useUserNFTs;
