import React from "react";

import ReactDOM from "react-dom/client";
import { MoralisProvider } from "react-moralis";
import { HashRouter } from "react-router-dom";

import App from "./App";
import "./assets/css/antd.css";
import "./assets/css/style.css";
import "./assets/css/common.css";
import "./assets/css/slick/slick-theme.min.css";
import "./assets/css/slick/slick.min.css";
import "./assets/css/custom.css";
import "./index.css";
import settings from "./settings";
import { GlobalStateProvider } from "./stateContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MoralisProvider
      appId={settings.MORALIS_APP_ID}
      serverUrl={settings.MORALIS_SERVER_URL}
    >
      <GlobalStateProvider>
        <HashRouter>
          <App />
        </HashRouter>
      </GlobalStateProvider>
    </MoralisProvider>
  </React.StrictMode>
);
