import "../../assets/css/detail.css";

const Detail = () => {
  return (
    <div className="content detail">
      <div className="wrap-content detail">
        <div className="bread">Auction</div>
        <div className="wrap-info detail">
          <div className="img">
            <a href="#">
              <img src="/assets/img/detail_img01.png" alt="" />
            </a>
          </div>
          <div className="box detail">
            <div className="info">
              <div className="title">Details</div>
              <div className="text">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
              </div>
              <div className="level">
                <span>STAR LEVEL:</span>
                <img src="/assets/img/star.png" alt="" />
              </div>
              <dl className="race">
                <dt>RACE:</dt>
                <dd>dwarf</dd>
              </dl>
              <dl className="element">
                <dt>element:</dt>
                <dd>water</dd>
              </dl>
              <ul className="c-info">
                <li>
                  Attack<span>3</span>
                </li>
                <li>
                  Critical<span>3</span>
                </li>
                <li>
                  Defense<span>3</span>
                </li>
                <li>
                  HP<span>3</span>
                </li>
                <li>
                  Avoid<span>4</span>
                </li>
              </ul>
            </div>
            <div className="history">
              <div className="title">Trading History</div>
              <div className="list">
                <dl>
                  <dt>Event</dt>
                  <dt>Price</dt>
                  <dt>From</dt>
                  <dt>To</dt>
                </dl>
                <dl>
                  <dt>Sale</dt>
                  <dd>
                    <span>2400</span>
                  </dd>
                  <dd>0x8...45</dd>
                  <dd>0x8...45</dd>
                  <dd className="time">1 hour ago</dd>
                </dl>
                <dl>
                  <dt>Transfer</dt>
                  <dd></dd>
                  <dd>0x8...45</dd>
                  <dd>0x8...45</dd>
                  <dd className="time">1 hour ago</dd>
                </dl>
                <dl>
                  <dt>Farming</dt>
                  <dd></dd>
                  <dd>0x8...45</dd>
                  <dd>0x8...45</dd>
                  <dd className="time">1 hour ago</dd>
                </dl>
                <dl>
                  <dt>Minted</dt>
                  <dd></dd>
                  <dd className="null">NullAddress</dd>
                  <dd className="time">1 hour ago</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div className="content-fixed">
          <div className="info">
            <div className="fixed-link">
              <div className="first">
                <div className="id">Token ID: 3050</div>
                <div className="owner">
                  Owner: <span>0x859AA...116310</span>
                </div>
              </div>
              <div className="last">
                <div className="title">Price:</div>
                <div className="price">
                  <span>2000</span>
                </div>
                <div className="btn">
                  <a href="#">
                    <img src="/assets/img/buy.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="fixed-content">
              <div className="text">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
