import { useEffect, useState, useRef } from "react";

import { Moralis } from "moralis";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { GAME_RESULT } from "../../constants";
import routes from "../../routes";
import settings from "../../settings";
import { truncate } from "../../utils";

const FightingResult = ({ code, handleClose }) => {
  const shouldFetchData = useRef(true);
  const [ourInformation, setOurInformation] = useState({});
  const [competitorInformation, setCompetitorInformation] = useState({});
  const [score, setScore] = useState("");
  const [earn, setEarn] = useState(0);
  const [result, setResult] = useState("");

  const fetchData = async () => {
    const res = await Moralis.Cloud.run("battleRoom_get", { code });

    if (res.isOk) {
      setOurInformation(
        res.data.userBattleRooms.find(
          (e) => e.user.id === Moralis.User.current().id
        ) || {}
      );
      setCompetitorInformation(
        res.data.userBattleRooms.find(
          (e) => e.user.id !== Moralis.User.current().id
        ) || {}
      );
    }

    toast(res.message, { type: "error" });
  };

  useEffect(() => {
    if (ourInformation.detailResult && competitorInformation.detailResult) {
      const yourScore = ourInformation.detailResult.filter(
        (n) => n === GAME_RESULT.WIN
      ).length;

      const competitorScore = competitorInformation.detailResult.filter(
        (n) => n === GAME_RESULT.WIN
      ).length;

      setScore(`${yourScore} - ${competitorScore}`);

      if (yourScore >= competitorScore) {
        setEarn(ourInformation.reward);
        if (yourScore > competitorScore) {
          setResult(GAME_RESULT.WIN);
        } else {
          setResult(GAME_RESULT.DRAW);
        }
      } else {
        setEarn(-ourInformation.lost);
        setResult(GAME_RESULT.LOSE);
      }
    }
  }, [ourInformation, competitorInformation]);

  useEffect(() => {
    if (shouldFetchData.current) {
      shouldFetchData.current = false;
      fetchData().catch(console.error);
    }
  });

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      onHide={handleClose}
    >
      {/*closeButton*/}
      <Modal.Header>
        <Modal.Title>You {result}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-content-wrap">
          <div className="div-score">
            <div className="score-item">
              <h3>Your Score</h3>
              <div className="number-score">
                <img className="icon" src={"/assets/img/score.svg"} alt="" />
                <span className="text">{score}</span>
              </div>
            </div>
            <div className="score-item">
              <h3>Your Earn</h3>
              <div className="number-score">
                <img className="icon" src={"/assets/img/score.svg"} alt="" />
                <span className="text">{earn} NAC</span>
              </div>
            </div>
          </div>
          <div className="compare-cards">
            <div className="your-card">
              <div className="userPlayer">
                {/*<img*/}
                {/*  className="avatar"*/}
                {/*  src={"/assets/img/avatar-2.png"}*/}
                {/*  alt=""*/}
                {/*/>*/}
                <span>
                  {ourInformation.user
                    ? truncate(ourInformation.user.userName, 10)
                    : ""}
                </span>
              </div>
              <div className="list-cards">
                {ourInformation.cards &&
                  ourInformation.cards.map((card, index) => (
                    <div
                      key={index}
                      className={`img ${
                        ourInformation.detailResult[index] ===
                          GAME_RESULT.LOSE && "lose"
                      }`}
                    >
                      <img
                        className="img"
                        src={`${settings.CARD_IMAGE_HOST}/${card.uri}`}
                        alt=""
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className="your-card">
              <div className="userPlayer">
                {/*<img*/}
                {/*  className="avatar"*/}
                {/*  src={"/assets/img/avatar-1.png"}*/}
                {/*  alt=""*/}
                {/*/>*/}
                <span>
                  {competitorInformation.user
                    ? truncate(competitorInformation.user.userName, 10)
                    : ""}
                </span>
              </div>
              <div className="list-cards">
                {competitorInformation.cards &&
                  competitorInformation.cards.map((card, index) => (
                    <div
                      key={index}
                      className={`img ${
                        competitorInformation.detailResult[index] ===
                          GAME_RESULT.LOSE && "lose"
                      }`}
                    >
                      <img
                        className="img"
                        src={`${settings.CARD_IMAGE_HOST}/${card.uri}`}
                        alt=""
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className="form-cta">
              <Link to={routes.PVP} className="btn-long">
                <img className="img" src="/assets/img/btn-finish.svg" alt="" />
              </Link>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FightingResult;
