import { useEffect, useState } from "react";

import { Button, Input, Modal } from "antd";
import { Moralis } from "moralis";

import { useMoralis } from "react-moralis";
import { useNavigate } from "react-router-dom";
import "../../assets/css/marketplace.css";
import "../../assets/js/slick/carousel.css";

import marketPlaceSound from "../../assets/music/marketplace.wav";
import openModal from "../../components/Modal";
import Pagination from "../../components/Pagination";
import Contract from "../../contract";
import ContractUtils from "../../ContractUtils";
import { useAudio } from "../../hooks/useAudio";

import routes from "../../routes";
import settings from "../../settings";
import { toRoute } from "../../utils";

const Marketplace = () => {
  const STAR_OPTIONS = [
    { label: "All", value: 0 },
    { label: "1 Star", value: 1 },
    { label: "2 Stars", value: 2 },
    { label: "3 Stars", value: 3 },
    { label: "4 Stars", value: 4 },
    { label: "5 Stars", value: 5 },
  ];

  const CLASS_OPTIONS = [
    { label: "All", value: "" },
    { label: "Water", value: "water" },
    { label: "Leaf", value: "leaf" },
    { label: "Earth", value: "earth" },
    { label: "Wind", value: "wind" },
    { label: "Fire", value: "fire" },
  ];
  const navigate = useNavigate();

  const { user } = useMoralis();
  const [isSellModalVisible, setIsSellModalVisible] = useState(false);
  const [sellingCard, setSellingCard] = useState(undefined);
  const [price, setPrice] = useState(0);

  const [userCards, setUserCards] = useState([]);

  const [yourListingOffers, setYourListingOffers] = useState([]);
  const [yourListingOffersPaginated, setYourListingOffersPaginated] = useState(
    []
  );
  const [pageYourListingOffers, setPageYourListingOffers] = useState(0);
  const [totalYourListingOffers, setTotalYourListingOffers] = useState(0);
  const [totalYourListingOffersPage, setTotalYourListingOffersPage] =
    useState(0);

  const [listingOffers, setListingOffers] = useState([]);
  const [listingOffersPaginated, setListingOffersPaginated] = useState([]);
  const [pageListingOffers, setPageListingOffers] = useState(0);
  const [totalListingOffers, setTotalListingOffers] = useState(0);
  const [totalListingOffersPage, setTotalListingOffersPage] = useState(0);

  const [starFiltered, setStarFiltered] = useState(0);
  const [classFiltered, setClassFiltered] = useState("");

  useEffect(() => {
    if (pageYourListingOffers > 0) {
      const start = (pageYourListingOffers - 1) * settings.ITEM_PER_PAGE;
      const end =
        pageYourListingOffers >= totalYourListingOffersPage
          ? totalYourListingOffers
          : start + settings.ITEM_PER_PAGE;

      const yourListingOffersPaginated = [];
      for (let i = start; i < end; i++) {
        yourListingOffersPaginated.push(yourListingOffers[i]);
      }
      setYourListingOffersPaginated(yourListingOffersPaginated);
    }
  }, [pageYourListingOffers]);

  useEffect(() => {
    if (pageListingOffers > 0) {
      const start = (pageListingOffers - 1) * settings.ITEM_PER_PAGE;
      const end =
        pageListingOffers >= totalListingOffersPage
          ? totalListingOffers
          : start + settings.ITEM_PER_PAGE;
      const listingOffersPaginated = [];
      for (let i = start; i < end; i++) {
        if (!listingOffers[i]) {
          continue;
        }
        listingOffersPaginated.push(listingOffers[i]);
      }

      setListingOffersPaginated(listingOffersPaginated);
    }
  }, [
    listingOffers,
    pageListingOffers,
    totalListingOffersPage,
    totalListingOffers,
  ]);

  const onChangePageYourListingOffers = async (page) => {
    setPageYourListingOffers(page);
  };

  const onChangePageListingOffers = async (page) => {
    setPageListingOffers(page);
  };

  const removeCardFromUserCards = async (nftId) => {
    setUserCards(userCards.filter((card) => card.nftId !== nftId));
  };

  const removeCardFromListing = async (nftId) => {
    setListingOffersPaginated(
      listingOffersPaginated.filter((offer) => offer.nftId !== nftId)
    );
  };

  const fetchYourListing = async () => {
    const data = await window.marketplaceContract.methods
      .fetchListingByAddress(ContractUtils.address)
      .call();
    const listingOffers = data.map((offer) => {
      return {
        id: offer["id"],
        class: offer["class"],
        nftId: offer["item"],
        level: offer["level"],
        price: offer["price"],
        uri: offer["uri"],
        status: offer["status"],
      };
    });
    const listingOffersFiltered = listingOffers.filter(
      (offer) => offer.level > 0
    );
    const totalItems = listingOffersFiltered.length;
    const totalPages = Math.ceil(
      listingOffersFiltered.length / settings.ITEM_PER_PAGE
    );
    setYourListingOffers(listingOffersFiltered);
    setTotalYourListingOffers(totalItems);
    setTotalYourListingOffersPage(totalPages);
    setPageYourListingOffers(1);
  };

  useEffect(() => {
    if (!window.marketplaceContract) {
      return;
    }

    fetchListingOffers(starFiltered, classFiltered);
  }, [starFiltered, classFiltered]);

  const initMarketplace = async () => {
    if (!window.marketplaceContract) {
      await Contract.init();
    }
    fetchYourListing();
    fetchListingOffers(starFiltered, classFiltered);
  };

  useEffect(() => {
    initMarketplace();
  }, []);

  const fetchListingOffers = async (_starFiltered, _classFiltered) => {
    const data = await window.marketplaceContract.methods
      .fetchOffers(_starFiltered, _classFiltered)
      .call();
    const yourAddress = ContractUtils.address;
    const dataFiltered = data.filter((item) => {
      return (
        item["owner"] &&
        item["owner"].toLowerCase() !== yourAddress.toLowerCase()
      );
    });
    const listingOffers = dataFiltered.map((offer) => {
      return {
        id: offer["id"],
        class: offer["class"],
        nftId: offer["item"],
        level: offer["level"],
        price: offer["price"],
        uri: offer["uri"],
        status: offer["status"],
      };
    });

    const listingOffersFiltered = listingOffers.filter(
      (offer) => offer.level > 0
    );
    const totalItems = listingOffersFiltered.length;
    const totalPages = Math.ceil(
      listingOffersFiltered.length / settings.ITEM_PER_PAGE
    );
    setListingOffers(listingOffersFiltered);
    setTotalListingOffers(totalItems);
    setTotalListingOffersPage(totalPages);
    setPageListingOffers(1);
  };

  const onSellAction = async (nftId) => {
    const contractApproved = await window.nftContract.methods
      .getApproved(nftId)
      .call();

    try {
      if (contractApproved !== settings.MARKETPLACE_CONTRACT) {
        const transaction = await approveTransaction(nftId);
        const { messageModal } = transaction;

        messageModal.destroy();
      }

      const transaction = await makeOpenOfferTransaction(nftId, price);
      const { messageModal } = transaction;
      messageModal.update({
        title: "Success",
        content: <div>Your NFT with ID #{nftId} listed successfully</div>,
      });
      removeCardFromUserCards(nftId);
      setIsSellModalVisible(false);
      setPrice(0);
      setSellingCard(0);
      fetchYourListing();
    } catch (error) {
      openModal("error", {
        title: "Error",
        content: "Unable to process the transaction.",
      });
    }
  };

  const onCancelOffer = async (offerId) => {
    const offer = yourListingOffers.find((offer) => offer["id"] === offerId);
    try {
      const transaction = await makeCancelOfferTransaction(offerId);
      const { messageModal } = transaction;
      messageModal.destroy();
      const card = !userCards.find((card) => card.nftId === offer["nftId"]);

      if (!card) {
        setUserCards([
          ...userCards,
          {
            class: offer["class"],
            isNew: false,
            level: offer["level"],
            nftId: offer["nftId"],
            uri: offer["uri"],
          },
        ]);
      }

      messageModal.update({
        title: "Success",
        content: (
          <div>
            Your NFT with ID #{offer["nftId"]} cancel listing successfully
          </div>
        ),
      });

      fetchYourListing();
    } catch (error) {
      openModal("error", {
        title: "Error",
        content: "Unable to process the transaction.",
      });
    }
  };

  const onBuyAction = async (offerId) => {
    const offer = listingOffers.find((offer) => offer.id === offerId);
    // get information from offer
    const price = offer.price;
    const status = offer.status;

    if (window.web3.utils.toUtf8(status) !== "Open") {
      alert("Status not open");
      return;
    }

    const balanceOf = await window.contract.methods
      .balanceOf(user.get("ethAddress"))
      .call();
    if (window.web3.utils.toBN(balanceOf).lt(window.web3.utils.toBN(price))) {
      openModal("error", {
        title: "Error",
        content: "Do not have enough NAC on your wallet",
      });
      return;
    }

    const allowance = await window.contract.methods
      .allowance(ContractUtils.address, settings.MARKETPLACE_CONTRACT)
      .call();

    try {
      if (allowance < price) {
        // please update price to moralis unit
        const transaction = await makeApproveNACTransaction(price);
        const { messageModal } = transaction;
        messageModal.destroy();
      }

      const transaction = await makeBuyofferTransaction(offerId);
      const { messageModal } = transaction;
      messageModal.update({
        title: "Success",
        content: <div>Buy NFT with ID #{offer.nftId} successfully</div>,
      });
      setUserCards([
        ...userCards,
        {
          class: offer["class"],
          isNew: true,
          level: offer["level"],
          nftId: offer["nftId"],
          uri: offer["uri"],
        },
      ]);
      removeCardFromListing(offer.nftId);
    } catch (error) {
      openModal("error", {
        title: "Error",
        content: "Unable to process the transaction.",
      });
    }
  };

  const makeApproveNACTransaction = (price) => {
    return new Promise((resolve, reject) => {
      let messageModal = null;
      let txHash = null;

      window.contract.methods
        .approve(settings.MARKETPLACE_CONTRACT, price)
        .send({
          from: user.get("ethAddress"),
        })
        .on("transactionHash", (hash) => {
          txHash = hash;
          messageModal = openModal("warning", {
            className: "message-only",
            title: "Approving",
            content: (
              <div className="text-center">
                The transaction hash:
                <div> {hash}</div>
                <h3 className="m-3">Please wait...</h3>
              </div>
            ),
          });
        })
        .on("error", (error) => {
          reject(error);
        })
        // .on('confirmation', (number, data) => console.log('confirm', data))
        .on("receipt", async (data) => {
          resolve({ messageModal, data, txHash });
        });
    });
  };

  const makeBuyofferTransaction = (offerId) => {
    return new Promise((resolve, reject) => {
      let messageModal = null;
      let txHash = null;

      window.marketplaceContract.methods
        .executeOffer(offerId)
        .send({
          from: user.get("ethAddress"),
        })
        .on("transactionHash", (hash) => {
          txHash = hash;
          messageModal = openModal("warning", {
            className: "message-only",
            title: "Processing",
            content: (
              <div className="text-center">
                The transaction hash:
                <div> {hash}</div>
                <h3 className="m-3">Please wait...</h3>
              </div>
            ),
          });
        })
        .on("error", (error) => {
          reject(error);
        })
        // .on('confirmation', (number, data) => console.log('confirm', data))
        .on("receipt", async (data) => {
          resolve({ messageModal, data, txHash });
        });
    });
  };

  const makeCancelOfferTransaction = (offerId) => {
    return new Promise((resolve, reject) => {
      let messageModal = null;
      let txHash = null;

      window.marketplaceContract.methods
        .cancelOffer(offerId)
        .send({
          from: user.get("ethAddress"),
        })
        .on("transactionHash", (hash) => {
          txHash = hash;
          messageModal = openModal("warning", {
            className: "message-only",
            title: "Processing",
            content: (
              <div className="text-center">
                The transaction hash:
                <div> {hash}</div>
                <h3 className="m-3">Please wait...</h3>
              </div>
            ),
          });
        })
        .on("error", (error) => {
          reject(error);
        })
        // .on('confirmation', (number, data) => console.log('confirm', data))
        .on("receipt", async (data) => {
          resolve({ messageModal, data, txHash });
        });
    });
  };

  const approveTransaction = (nftId) => {
    return new Promise((resolve, reject) => {
      let messageModal = null;
      let txHash = null;

      window.nftContract.methods
        .approve(settings.MARKETPLACE_CONTRACT, nftId)
        .send({
          from: user.get("ethAddress"),
        })
        .on("transactionHash", (hash) => {
          txHash = hash;
          messageModal = openModal("warning", {
            className: "message-only",
            title: "Approving",
            content: (
              <div className="text-center">
                The transaction hash:
                <div> {hash}</div>
                <h3 className="m-3">Please wait...</h3>
              </div>
            ),
          });
        })
        .on("error", (error) => {
          reject(error);
        })
        // .on('confirmation', (number, data) => console.log('confirm', data))
        .on("receipt", async (data) => {
          resolve({ messageModal, data, txHash });
        });
    });
  };

  const makeOpenOfferTransaction = (nftId, price) => {
    return new Promise((resolve, reject) => {
      let messageModal = null;
      let txHash = null;

      window.marketplaceContract.methods
        .openOffer(nftId, Moralis.Units.Token(price, "18"))
        .send({
          from: user.get("ethAddress"),
        })
        .on("transactionHash", (hash) => {
          txHash = hash;
          messageModal = openModal("warning", {
            className: "message-only",
            title: "Processing",
            content: (
              <div className="text-center">
                The transaction hash:
                <div> {hash}</div>
                <h3 className="m-3">Please wait...</h3>
              </div>
            ),
          });
        })
        .on("error", (error) => {
          reject(error);
        })
        // .on('confirmation', (number, data) => console.log('confirm', data))
        .on("receipt", async (data) => {
          resolve({ messageModal, data, txHash });
        });
    });
  };

  const onChangeSelectStarFiltered = (e) => {
    setStarFiltered(Number(e.target.value));
  };

  const onChangeClassFiltered = (e) => {
    setClassFiltered(e.target.value);
  };

  useAudio(marketPlaceSound);

  return (
    <div className="content marketplace">
      <div className="wrap-content marketplace">
        <ul className="bread">
          <li>Listings</li>
          {/* <li>
                        <Link to={routes.AUCTION}>Auction</Link>
                    </li> */}
        </ul>
        <div className="wrap-info marketplace">
          {/* <div className="list">
                        <p>Your Inventory</p>
                        {userCards.map(card => {
                            return <div key={card.nftId} className="img" style={{display: "inline-block", width: '200px'}}>
                                    <div>
                                        {card.uri &&
                                            <img src={`${settings.CARD_IMAGE_HOST}/${card.uri}`} alt="" />
                                        }
                                        <div style={{ color: '#000' }}>#{card.nftId} {card.isNew && <span>(New Card)</span>}</div>
                                        <button onClick={() => onSellClick(card.nftId)}>Sell</button>
                                    </div>
                            </div>
                        })}
                        {totalUserCardPage &&
                            <Pagination totalPage={totalUserCardPage} currentPage={pageUserCard} onChange={onChangePageUserCard} />
                        }
                    </div> */}
          <div className="list">
            {yourListingOffersPaginated.length > 0 && (
              <>
                <p className="bg-title">Your Listing</p>
                {yourListingOffersPaginated.map((offer) => {
                  return (
                    <div
                      key={offer?.id}
                      className="img img1"
                      style={{ display: "inline-block", width: "200px" }}
                    >
                      <div>
                        {offer?.uri && (
                          <img
                            src={`${settings.CARD_IMAGE_HOST}/${offer?.uri}`}
                            alt=""
                          />
                        )}
                        <div style={{ color: "#000" }} className="text">
                          #{offer?.nftId}: {offer?.price / 1e18} NAC
                        </div>
                        <button
                          onClick={() => onCancelOffer(offer?.id)}
                          className="cancel"
                        >
                          Cancel Listing
                        </button>
                      </div>
                    </div>
                  );
                })}

                {totalYourListingOffersPage && (
                  <Pagination
                    totalPage={totalYourListingOffersPage}
                    currentPage={pageYourListingOffers}
                    onChange={onChangePageYourListingOffers}
                  />
                )}
              </>
            )}
          </div>
          {/*
                    <div className="wrap-slider">
                        <div className="title marketplace"><img src={'../../assets/img/hotntf.svg'} alt=""/></div>
                        <div className="swiper marketplace">
                            <Slider {...sliderSettings} className="swiper-wrapper">
                                {Array(6).fill(0).map((_, idx) => {
                                    return (
                                        <div>
                                            <div className="img">
                                                <img src={`../../assets/img/marketplace_img0${idx+1}.png`} alt="gallery_img" />
                                            </div>
                                            <div className="price"><span>3000</span></div>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                    </div>*/}
          <div className="wrap-gallery">
            <div className="title">Marketplace</div>
            <div className="find-info">
              <div className="sub-list">
                <span>Star: </span>
                <select onChange={onChangeSelectStarFiltered}>
                  {STAR_OPTIONS.map((o) => {
                    return (
                      <option
                        key={`start_${o.value}`}
                        value={o.value}
                        selected={o.value === starFiltered}
                      >
                        {o.label}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="sub-list">
                <span>Class: </span>
                <select onChange={onChangeClassFiltered}>
                  {CLASS_OPTIONS.map((o) => {
                    return (
                      <option
                        key={`class_${o.value}`}
                        value={o.value}
                        selected={o.value === classFiltered}
                      >
                        {o.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="gallery-info">
              {listingOffersPaginated.length <= 0 ? (
                <div>Currently do not have any cards listed.</div>
              ) : (
                <>
                  <div className="gallery-item">
                    {listingOffersPaginated.map((offer) => {
                      return (
                        <div key={offer.id} className="box">
                          <div
                            className="img"
                            onClick={() =>
                              navigate(
                                toRoute(routes.INVENTORY_DETAIL, {
                                  id: offer.nftId,
                                })
                              )
                            }
                          >
                            <img
                              src={`${settings.CARD_IMAGE_HOST}/${offer?.uri}`}
                              alt="gallery_img"
                            />
                          </div>
                          <div className="price">
                            <span>{offer?.price / 1e18} NAC</span>
                          </div>
                          <div className="button">
                            <span onClick={() => onBuyAction(offer?.id)}>
                              <img src="/assets/img/buy.svg" alt="" />
                            </span>{" "}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {totalListingOffersPage && (
                    <Pagination
                      totalPage={totalListingOffersPage}
                      currentPage={pageListingOffers}
                      onChange={onChangePageListingOffers}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {/* <div className="content-fixed">
                    <div className="info">
                        <div className="fixed-link">
                            <div className="first">
                                <div className="title">Price:</div>
                                <div className="price">
                                    <span id="price">2000</span>
                                </div>
                            </div>
                            <div className="last">
                                <div className="level">
                                    <div className="title">Star level: </div>
                                    <ul className="star">
                                        <li><span><img src="/assets/img/star.png" alt=""/></span></li>
                                        <li><span><img src="/assets/img/star.png" alt=""/></span></li>
                                        <li><span><img src="/assets/img/star.png" alt=""/></span></li>
                                        <li><span><img src="/assets/img/star.png" alt=""/></span></li>
                                        <li><span><img src="/assets/img/star.png" alt=""/></span></li>
                                    </ul>
                                </div>
                                <div className="detail"><a href="listings_detail.html">Details</a></div>
                                <div className="button"><a href="#"><img src="/assets/img/buy.svg" alt=""/></a> </div>
                            </div>
                        </div>
                        <div className="fixed-content">
                            <div className="text">
                                The Nowl Age assets such as Guardians are user-owned BSC-721 NFTs can be freely traded with other players.
                                To remove the possibility for scams when conducting trades through the use of the NFT contracts, The Nowl Age has a proprietary marketplace where users may list and purchase their NFTs.
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>

      <Modal
        visible={isSellModalVisible}
        closable
        footer={false}
        onCancel={() => setIsSellModalVisible(false)}
        destroyOnClose
      >
        <div>
          {sellingCard && sellingCard.uri && (
            <img
              style={{ width: "300px" }}
              src={`${settings.CARD_IMAGE_HOST}/${sellingCard.uri}`}
              alt=""
            />
          )}
          <Input
            placeholder="Price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
          <Button onClick={() => onSellAction(sellingCard.nftId)}>Sell</Button>
        </div>
      </Modal>
    </div>
  );
};

export default Marketplace;
